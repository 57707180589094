import styled from "styled-components/macro";
import { Box, LinearProgress } from "@mui/material";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";

export const StyledTimeContainer = styled(Box)`
  color: ${colors.white};
  ${styles.FontRegular};
  padding: 6px 20px 15px;
  font-size: 14px;
  line-height: 17px;
`;

export const StyledProgressBarContainer = styled(Box)`
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 44px;
  background: rgba(0, 0, 0, 0.2);
`;

export const StyledProgress = styled(LinearProgress)`
  &.MuiLinearProgress-colorPrimary {
    background-color: hsla(0, 0%, 100%, 0.2);
    height: 6px;
    opacity: 0.85;
    margin-left: 1px;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${colors.primary};
  }
`;
