import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const StyledVideoNumberContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 19px;
  display: none !important;

  @media only screen and (min-width: ${SCREEN_SIZES.xs}) and (max-width: ${SCREEN_SIZES.md}) {
    width: 25px;
    height: 25px;
    left: 8px;
    top: 18px;
  }
`;

export const StyledImageContainer = styled.img`
  cursor: pointer;
  transition: 0.8s;
  border-radius: 50px;

  ${isMobile} && {
    :hover {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50px;
    }
  }
`;

export const StyledVideosNumberSection = styled.div`
  ${styles.FlexRow};
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 7px 12px 6px 13px;
  color: white;
  margin-left: 15px;
  align-items: center;

  .numbers-container {
    display: flex;
  }
`;

export const StyledCameraImage = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  img {
    max-width: 12px;
  }
`;

export const StyledVideoNumbers = styled.div`
  ${styles.FontMedium};
  display: block;
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
  color: ${({ active }) => (active ? colors.black : colors.white)};
  background: ${({ active }) => (active ? "rgba(255, 255, 255, 0.7)" : "none")};
  cursor: pointer;
  margin: 0 1px;

  ${isMobile} && {
    :hover {
      background: ${({ active }) =>
        active ? "rgba(255, 255, 255, 0.9);" : "rgba(0, 0, 0, 0.3)"};
    }
  }
`;

export const MultiCamItem = styled.div`
  position: relative;

  ${isMobile} && {
    :hover .thumb-item {
      display: block;
    }
  }
`;

export const CameraThumb = styled.div`
  position: absolute;
  display: none;
  text-align: center;
  top: 30px;
  left: -70px;
`;

export const ThumbName = styled.span`
  background-color: ${colors.lightBlack};
  border-radius: 2px;
  text-align: center;
  color: ${colors.white};
  ${styles.FontMedium};
  font-size: 12px;
  padding: 2px 6px;
`;

export const ThumbImg = styled.img`
  margin-top: 6px;
  border: 1px solid ${colors.white};
  border-radius: 3px;
  width: 160px;
`;
