import { SimpleButtonStyle } from "./styled";

export const SimpleButton = ({
  text,
  icon,
  event,
  style,
  isFilter,
  className = "",
}) => (
  <SimpleButtonStyle
    isFilter={isFilter}
    onClick={event ? () => event() : null}
    style={style}
    className={className}
  >
    {icon ? <img src={icon} alt="" /> : null}
    {text}
  </SimpleButtonStyle>
);
