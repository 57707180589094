import { CloseSvg, Modal, ModalContainer } from "./styled";
import { Images } from "assets/images";
import { useRef } from "react";
import { useOnClickOutside } from "hooks";

export const SimpleModal = ({ show, close, children }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, close);

  return (
    <ModalContainer show={show}>
      <Modal ref={ref}>
        <CloseSvg src={Images.CloseIcon} onClick={close} alt="" />
        {children}
      </Modal>
    </ModalContainer>
  );
};
