import {
  WrapperContainer,
  UploadingText,
  UploadLoading,
  ProgressLoading,
} from "./styled";
import { Link } from "react-router-dom";

export const ProgressBlock = () => {
  return (
    <WrapperContainer>
      <UploadLoading>
        <ProgressLoading>
          <progress max="100" value="80" />
          <UploadingText>
            Uploading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </UploadingText>
        </ProgressLoading>
      </UploadLoading>
      <Link to="/club/video/upload/add-info">
        <h1>To the next page</h1>
      </Link>
    </WrapperContainer>
  );
};
