import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVideo, getVideoList } from "store";
import { useParams } from "react-router-dom";

export const useVideoActions = () => {
  const { user } = useSelector((state) => state.video);
  const dispatch = useDispatch();
  const params = useParams();

  return {
    getUpdatedVideoList: function () {
      dispatch(
        getVideoList({
          userId: user.id,
          code: user.security_string,
          activeTab: localStorage.getItem("activeTab"),
          filters: "",
        })
      );
    },
    getData: function () {
      dispatch(
        getVideo({
          id: params.id || "",
          userId: user.id,
          code: user.security_string,
        })
      );
      dispatch(
        getVideoList({
          userId: user.id,
          code: user.security_string,
          activeTab: localStorage.getItem("activeTab"),
          filters: "",
        })
      );
    },
  };
};
