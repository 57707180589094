import { useState } from "react";
import { colors } from "assets/styles/colors";
import {
  AccountsWrapper,
  AccountName,
  BlockAccounts,
  CheckBoxInput,
  EachAccountBlock,
} from "../styled";

const OpenAccountsBlock = ({ checkCount }) => {
  const [currentFaculty, setCurrentFaculty] = useState([]);
  const [users, setState] = useState([
    {
      name: "David Long",
    },
    {
      name: "Gary Caron",
    },
    {
      name: "Jamie Forsythe",
    },
    {
      name: "Jessica Wyant",
    },
    {
      name: "Kent Grice",
    },
    {
      name: "Lee Barkell",
    },
  ]);

  return (
    <AccountsWrapper className="open-accounts-block">
      <BlockAccounts>
        {users.map((element, index) => {
          return (
            <EachAccountBlock
              key={`${element.name}-${index}`}
              className={`${
                currentFaculty.includes(element.name) && "includes-name"
              }`}
            >
              <CheckBoxInput
                type="checkbox"
                onClick={checkCount}
                onChange={() =>
                  setCurrentFaculty([...currentFaculty, element.name])
                }
              />
              <AccountName
                className={`${
                  currentFaculty.includes(element.name) && "includes-name"
                }`}
              >
                {element.name}
              </AccountName>
            </EachAccountBlock>
          );
        })}
      </BlockAccounts>
    </AccountsWrapper>
  );
};

export default OpenAccountsBlock;
