export const ROUTES = {
  selectVideo: "/video/:id",
  video: "/video",
  uploadVideo: "club/video/create",
  notFound: "/404",

  //todo check routues
  uploadingProgressDemo: "club/video/upload/progress",
  uploadVideoAddInfo: "club/video/upload/add-info",
};
