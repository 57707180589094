import { configureStore } from "@reduxjs/toolkit";
import videoReducer from "./video/slice";
import videoListReducer from "./videoList/slice";

export const store = configureStore({
  reducer: {
    video: videoReducer,
    videoList: videoListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
