import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const StyledHomPageContainer = styled.div`
  padding: 0 20px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 0;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding: 0;
    }
  }
`;

export const Wrapper = styled.div`
  ${styles.FlexCenter};
  width: 960px;
  margin: auto;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    width: 100%;
  }
`;

export const StyledMobileContainer = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 0 18px 0 10px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding: 0;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 0 8px 0 10px;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  margin: 15px 0 0 0;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 15px 0 27px 0;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin: 16px 0 0 0;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin: 15px 0 21px 0;
  }
`;

export const StyledDividerContainer = styled.div`
  width: calc(100vw - 15px);
  border-bottom: 1px solid ${colors.black};

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

export const StyledWrapperInnerContainer = styled.div`
  .video-js {
    border: 1px solid rgba(18, 18, 18, 0.2);
    box-sizing: inherit;
    background: none;

    .vjs-control-bar {
      transition: 0.3s;
      height: 40px;
    }

    .vjs-play-control {
      span {
        width: 40px;
        height: 40px;
      }
    }

    .vjs-vast-label {
      display: none;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding: 0 70px;
    }
  }
`;
