import React from "react";
import { Stack } from "@mui/material";
import { Images } from "assets/images";
import {
  Dropdown,
  LastPage,
  LeftArrow,
  RightArrow,
  SelectPaginationSection,
  StyledButtonContainer,
  StyledDownItemsContainer,
  StyledFilterText,
  StyledPageNumberContainer,
  StyledPaginationNumbers,
} from "./styled";

export const PaginationSection = ({
  prevOrNextPage,
  dropDownItems,
  setOpenPagination,
  paginationData,
  openPagination,
  paginationLinks,
  paginationRef = { paginationRef },
  topPosition,
}) => {
  return (
    <SelectPaginationSection topPosition={topPosition}>
      {paginationLinks.prev && (
        <LeftArrow
          src={Images.DownArrow}
          alt=""
          onClick={() => prevOrNextPage("prev")}
        />
      )}

      <StyledFilterText>Page</StyledFilterText>

      <Dropdown ref={paginationRef}>
        <StyledButtonContainer
          onClick={() => setOpenPagination((prevState) => !prevState)}
        >
          <Stack direction="row" spacing="24px">
            <StyledPaginationNumbers>
              {paginationData.current_page}
            </StyledPaginationNumbers>
            <img src={Images.DownArrow} alt="" />
          </Stack>
        </StyledButtonContainer>

        {openPagination && (
          <StyledDownItemsContainer>{dropDownItems()}</StyledDownItemsContainer>
        )}
      </Dropdown>

      <StyledPageNumberContainer>
        of
        <LastPage>{paginationData.last_page}</LastPage>
      </StyledPageNumberContainer>

      {paginationLinks.next && (
        <RightArrow
          src={Images.DownArrow}
          alt=""
          onClick={() => prevOrNextPage("next")}
        />
      )}
    </SelectPaginationSection>
  );
};
