import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const CopyLinkContainer = styled.div`
  ${styles.FlexRow};
  ${styles.FontMedium};
  align-items: center;
  font-size: ${({ fs }) => fs};
  line-height: 15px;
  color: ${colors.lightBlack};
  opacity: 0.75;
  width: ${({ underVideoSection, isSaveModal }) =>
    underVideoSection || isSaveModal ? "max-content" : "95px"};
  cursor: pointer;
  margin-left: ${({ underVideoSection }) => underVideoSection && "26px"};

  img {
    margin-right: 7px;
  }

  ${isMobile} && {
    &:hover {
      opacity: 1;
      color: ${colors.primary};
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: ${({ isSaveModal }) => (isSaveModal ? "16px" : "10px")};
    line-height: 12px;
    margin-top: 1px;
    margin-left: ${({ underVideoSection }) => underVideoSection && "23px"};
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: ${({ isSaveModal }) => (isSaveModal ? "16px" : "10px")};
      line-height: 12px;
      margin-left: ${({ underVideoSection }) => underVideoSection && "0px"};

      img {
        margin-right: 6px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    img {
      margin-right: 4px;
      margin-top: ${({ underVideoSection }) => underVideoSection && "-5px"};
    }
  }
`;

export const ImageCopyDownload = styled.img`
  width: 14px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 12px;
  }
`;

export const CopyLinkText = styled.span`
  margin-left: ${({ isSaveModal }) => (isSaveModal ? "79px" : "")};
  white-space: nowrap;
`;

export const LinkCopiedContainer = styled.div`
  ${styles.FlexRow};
  ${({ underVideoSection }) =>
    underVideoSection ? styles.FontSemiBold : styles.FontMedium};
  align-items: center;
  font-size: ${({ fs }) => fs};
  line-height: 15px;
  color: ${colors.primary};
  opacity: 1;
  white-space: nowrap;
  cursor: pointer;
  width: ${({ underVideoSection, isSaveModal }) =>
    underVideoSection || isSaveModal ? "max-content" : "85px"};
  margin-left: ${({ underVideoSection }) => underVideoSection && "24px"};

  img {
    margin-right: 3px;
    filter: ${colors.svgPrimary};
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: ${({ isSaveModal }) => (isSaveModal ? "16px" : "10px")};
      line-height: 12px;
      margin-left: ${({ underVideoSection }) => underVideoSection && "0px"};

      img {
        margin-right: 6px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: ${({ isSaveModal }) => (isSaveModal ? "16px" : "10px")};
  }
`;
