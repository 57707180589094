import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";

export const HeaderWrapper = styled.div`
  ${styles.FlexRow};
  justify-content: space-between;
  min-height: 85px;
  height: min-content;
  padding: 0 32px;
  background: ${colors.white};
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    padding: 0 21px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 0 9px;
  }
`;

export const HeaderLeftSideIMG = styled.img`
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledImageContainer = styled.div`
  box-sizing: border-box;
  position: relative;

  a {
    display: block;
    height: 100px;
  }
`;

export const HeaderRightSideIMG = styled.img`
  height: 16px;
  margin-bottom: 4px;
`;

export const HeaderRightSide = styled.div`
  ${styles.FlexColumn};
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 207px;
  max-width: 75%;
  padding-top: 15px;

  a {
    text-align: end;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    min-width: 162px;
    margin-right: 2px;
  }
`;

export const HeaderRightSideName = styled.div`
  ${styles.FontBold};
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const BlackLine = styled.div`
  height: 6px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 1px;
  background: #000;
`;
