import videojs from "video.js";
import { SimpleButton } from "components";
import { Images } from "assets/images";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVideoIsFavorite,
  openMakeClip,
  setVideoCurrentTime,
} from "store";
import { colors } from "assets/styles/colors";
import { useMediaQuery } from "@mui/material";
import { Date, DateWrapper, WeekDay } from "fragments/mainVideoInfo/styled";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Copy } from "../copy";
import { Download } from "../download";
import { scrollTopFunction } from "utils/heplers";
import { resetStatus } from "store/video/slice";
import { actionBarFontSizeVariants } from "assets/styles/sizes";
import { SCREEN_SIZES, SUCCEEDED } from "utils/constants";
import { useVideoActions } from "hooks";

import {
  UnderVideoAction,
  UnderVideoSectionWrapper,
  UnderVideoActionWrapper,
  IsFavoriteAction,
  StyledContainer,
  StyledLinkCopied,
} from "./styled";
import { isMobile } from "react-device-detect";

export const UnderVideoSection = () => {
  const { video, isFavorite, updateIsFavoriteStatus, updateVideoTitleStatus } =
    useSelector((state) => state.video);

  const [isCopied, setIsCopied] = useState(false);

  const videoActions = useVideoActions();
  const dispatch = useDispatch();

  const buttonText = video.parent_id ? "Edit Clip" : "Make a clip";

  const isMobileSm = useMediaQuery(`(min-width:${SCREEN_SIZES.sm})`);
  const isMobileXs = useMediaQuery(`(max-width:${SCREEN_SIZES.xs})`);
  const landscape = useMediaQuery(
    `(max-width: ${SCREEN_SIZES.landscape}) and (orientation: landscape) and (min-width: ${SCREEN_SIZES.landscapeMinDistance})`
  );

  const isMobileLandscape = isMobile && landscape;

  const makeClipHandler = () => {
    dispatch(openMakeClip());
    scrollTopFunction();
  };

  const favoriteHandler = () => {
    dispatch(
      updateVideoIsFavorite({
        id: video.id,
        is_favorite: !video.is_favorite,
      })
    );
  };

  useEffect(() => {
    if (
      updateIsFavoriteStatus === SUCCEEDED ||
      updateVideoTitleStatus === SUCCEEDED
    ) {
      const player = videojs("video");
      dispatch(setVideoCurrentTime(player.currentTime()));
      dispatch(resetStatus("updateIsFavoriteStatus"));
      dispatch(resetStatus("updateVideoTitleStatus"));
      videoActions.getData();
    }
  }, [updateIsFavoriteStatus, updateVideoTitleStatus]);

  return (
    <UnderVideoSectionWrapper>
      {isMobileLandscape || isMobileXs ? (
        <DateWrapper>
          <WeekDay>{dayjs(video?.filming_date).format("dddd")}</WeekDay>
          <Date>{dayjs(video?.filming_date).format("MMMM D, YYYY")}</Date>
        </DateWrapper>
      ) : (
        <SimpleButton
          text={buttonText}
          icon={Images.Scissors}
          event={makeClipHandler}
          style={{ background: colors.primary, color: colors.white }}
        />
      )}
      <StyledContainer>
        <UnderVideoActionWrapper>
          <IsFavoriteAction
            onClick={favoriteHandler}
            isFavorite={isFavorite.favorite}
          >
            <img
              src={isFavorite.favorite ? Images.HeartIconRed : Images.HeartIcon}
              alt=""
            />

            {isMobileSm && !isMobileLandscape ? (
              <span>
                {isFavorite.favorite
                  ? "Added to favorites"
                  : "Add to favorites"}
              </span>
            ) : null}
          </IsFavoriteAction>

          <UnderVideoAction className="under-video-section">
            <Copy
              isMobile={isMobileSm && !isMobileLandscape}
              video={video}
              setIsCopied={setIsCopied}
              underVideoSection={true}
              fs={actionBarFontSizeVariants.underVideo}
            />
          </UnderVideoAction>

          <UnderVideoAction>
            <Download
              isMobile={isMobileSm && !isMobileLandscape}
              video={video}
              fs={actionBarFontSizeVariants.underVideo}
              underVideoSection={true}
            />
          </UnderVideoAction>
        </UnderVideoActionWrapper>

        {isCopied && isMobile && (
          <StyledLinkCopied className="link-copied-for-mobile">
            Link copied
          </StyledLinkCopied>
        )}
      </StyledContainer>
    </UnderVideoSectionWrapper>
  );
};
