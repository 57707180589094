import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getVideo, getVideoList, getFilterLabels } from "store";
import { UnderVideoSection } from "../underVideoSection";
import { MainVideoInfo } from "../mainVideoInfo";
import { Video } from "../video";
import { MyVideos } from "../myVideos";
import { Loading } from "fragments";
import { generateUrlForMakeCip, redirectNullUser } from "utils/heplers";
import {
  ANALYTIC_TYPES,
  BROWSER_SAFARI,
  PENDING,
  REJECTED,
  SUCCEEDED,
} from "utils/constants";
import { resetStatus } from "store/video/slice";
import { browserName } from "react-device-detect";
import { useAnalytics, useFirstRender } from "hooks";
import { AnnouncementBar } from "../announcementBar";
import { DownloadProgressModal } from "components/downloadProgressModal";

import {
  StyledDividerContainer,
  StyledHomPageContainer,
  StyledMobileContainer,
  StyledWrapperInnerContainer,
  VideoWrapper,
  Wrapper,
} from "./styled";

export const Home = () => {
  const {
    editClipStatus,
    makeVideoData,
    pageTitle,
    isVideoPlayerReady,
    downloadVideoStatus,
  } = useSelector((state) => state.video);

  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedLabels, setCheckedLabels] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [downloadProgressStatus, setDownloadProgressStatus] = useState(null);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const firstRender = useFirstRender();
  const analyticActions = useAnalytics();

  const params = useParams();
  const userId = searchParams.get("user_id");
  const code = searchParams.get("code");

  if (!userId) {
    navigate("/");
  }

  if (code === "") {
    navigate(redirectNullUser({ userId, id: params.id || "" }));
  }

  if (browserName === BROWSER_SAFARI) {
    document.body.classList.add("is-safari");
  }

  useEffect(() => {
    if (makeVideoData) {
      const url = generateUrlForMakeCip(makeVideoData.video_data);
      navigate(url);
    }
  }, [makeVideoData]);

  useEffect(() => {
    if (userId && code) {
      localStorage.removeItem("filters");

      /**
       * start logic for default tab
       */
      if (firstRender) {
        if (localStorage.getItem("videoId") !== params.id) {
          localStorage.setItem("activeTab", "full");
        }
      }
      if (!localStorage.getItem("activeTab")) {
        localStorage.setItem("activeTab", "full");
      }
      /**
       * end
       */

      localStorage.setItem("videoId", params.id);

      dispatch(
        getVideo({
          id: params.id || "",
          userId,
          code,
        })
      );

      dispatch(
        getFilterLabels({
          userId,
          code,
        })
      );
    }
  }, [dispatch, params.id, searchParams]);

  useEffect(() => {
    if (code) {
      dispatch(
        getVideoList({
          userId,
          code,
          activeTab: localStorage.getItem("activeTab"),
        })
      );
    }
  }, [code]);

  useEffect(() => {
    if (editClipStatus === SUCCEEDED) {
      dispatch(
        getVideo({
          id: params.id || "",
          userId,
          code,
        })
      );
      dispatch(resetStatus("editClipStatus"));
    }
  }, [editClipStatus]);

  useEffect(() => {
    switch (downloadVideoStatus) {
      case PENDING:
        setOpenModal(true);
        setDownloadProgressStatus(null);
        break;
      case SUCCEEDED:
        analyticActions.update(ANALYTIC_TYPES.download_count);
        setDownloadProgressStatus("finished");
        break;
      case REJECTED:
        setDownloadProgressStatus("finished");
        break;
      default:
        break;
    }
  }, [downloadVideoStatus]);

  useEffect(() => {
    if (pageTitle) document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      {!isVideoPlayerReady ? <Loading /> : null}
      <StyledHomPageContainer>
        <Wrapper>
          <VideoWrapper>
            <StyledWrapperInnerContainer className="styled-wrapper-inner-container">
              <Video />
              <StyledMobileContainer className="styled-mobile-container">
                <UnderVideoSection />
                <MainVideoInfo
                  setCheckedLabels={setCheckedLabels}
                  checkedLabels={checkedLabels}
                />
              </StyledMobileContainer>
            </StyledWrapperInnerContainer>
          </VideoWrapper>

          <StyledDividerContainer />

          <MyVideos
            setCheckedLabels={setCheckedLabels}
            checkedLabels={checkedLabels}
          />

          <AnnouncementBar />
        </Wrapper>
      </StyledHomPageContainer>

      <DownloadProgressModal
        show={openModal}
        setOpenModal={setOpenModal}
        downloadProgressStatus={downloadProgressStatus}
        setDownloadProgressStatus={setDownloadProgressStatus}
      />
    </>
  );
};
