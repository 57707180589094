import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const DownloadSection = styled.div`
  ${styles.FontMedium};
  font-size: ${({ fs }) => fs};
  line-height: 15px;
  opacity: 0.75;
  color: ${colors.lightBlack};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ underVideoSection }) => underVideoSection && "0 1px 0 27px"};

  img {
    margin-right: 7px;
    margin-top: ${({ isSaveModal }) => (isSaveModal ? "3px" : "-3px")};
    width: 14px;

    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      width: 12px;
    }
  }

  span {
    margin-left: ${({ isSaveModal }) => (isSaveModal ? "77px" : "")};
  }

  ${isMobile} && {
    &:hover {
      opacity: 1;
      color: ${colors.primary};

      img {
        filter: ${colors.svgPrimary};
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: ${({ isSaveModal }) => (isSaveModal ? "16px" : "10px")};
    line-height: 12px;
    margin: ${({ underVideoSection }) => underVideoSection && "0 2px 0 21px"};

    img {
      margin-top: -3px;
      margin-right: 10px;
      width: 11px;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 10px;
      line-height: 12px;
      margin-left: 0;
      margin-right: ${({ underVideoSection }) =>
        underVideoSection ? "unset" : "6px"};

      img {
        margin-top: 1px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    line-height: unset;
    margin-top: 0;

    img {
      margin-top: ${({ underVideoSection }) => underVideoSection && "-4px"};
      margin-right: ${({ underVideoSection }) =>
        underVideoSection ? "6px" : "7px"};
      width: ${({ underVideoSection }) => underVideoSection && "13px"};
    }
  }
`;

export const StyledSizeTex = styled.div`
  ${styles.FontRegular};
  font-size: 10px;
  margin-left: 6px;
  margin-top: 1px;
`;

export const SpanText = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 76px;
`;
