import { Images } from "assets/images";
import { SimpleButton } from "components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAnnouncements } from "store/selector";
import {
  StyledAnnouncementBarContainer,
  StyledBarTitle,
  StyledFlexContainer,
  StyledImageContainer,
} from "./styled";

export const AnnouncementBar = () => {
  const announcements = useSelector(selectAnnouncements);

  const [isOpenBar, setIsOpenBar] = useState(true);

  const {
    bg_color,
    btn_link,
    btn_text,
    btn_text_bg_color,
    btn_text_color,
    btn_text_hover_bg_color,
    btn_text_hover_color,
    is_btn_link_target,
    text,
  } = announcements;

  return (
    <>
      {isOpenBar && Object.keys(announcements).length ? (
        <StyledAnnouncementBarContainer bg_color={bg_color}>
          <StyledFlexContainer>
            <StyledBarTitle dangerouslySetInnerHTML={{ __html: text }} />

            <a
              href={btn_link}
              target={is_btn_link_target && "_blank"}
              rel="noreferrer"
            >
              <SimpleButton
                text={btn_text}
                style={{
                  background: btn_text_bg_color,
                  color: btn_text_color,
                  width: "max-content",
                  maxWidth: "150px",
                  "&:hover": {
                    color: `${btn_text_hover_color} !important`,
                    background: `${btn_text_hover_bg_color} !important`,
                  },
                }}
              />
            </a>
            <StyledImageContainer
              onClick={() => setIsOpenBar(false)}
              src={Images.CloseIcon}
              alt="Close Icon"
            />
          </StyledFlexContainer>
        </StyledAnnouncementBarContainer>
      ) : null}
    </>
  );
};
