import { createSlice } from "@reduxjs/toolkit";
import {
  getVideoList,
  updateVideoListIsFavorite,
  updateVideoListLabels,
} from "./axios";
import { sortAlphabeticallyByName } from "utils/heplers";
import { FAILED, PENDING, REJECTED, SUCCEEDED } from "utils/constants";

const initialState = {
  videoList: [],
  paginationLinks: {},
  paginationData: {},
  getVideoListStatus: PENDING | SUCCEEDED | FAILED,
  updateVideoListLabelsStatus: PENDING | SUCCEEDED | FAILED,
  updateFavoriteStatus: PENDING | SUCCEEDED | FAILED,
};

export const videoList = createSlice({
  name: "videoList",
  initialState,
  reducers: {
    syncVideoListVideoLabels: (state, action) => {
      const { changedLabels, videoId } = action.payload;

      state.videoList.map((item) => {
        if (item.video.id === videoId) {
          item.labels = sortAlphabeticallyByName([...changedLabels]);
        }

        return item;
      });
    },
  },
  extraReducers: {
    [getVideoList.pending]: (state) => {
      state.getVideoListStatus = PENDING;
    },

    [getVideoList.fulfilled]: (state, action) => {
      state.videoList = action.payload.video_list;
      state.paginationLinks = action.payload.links;
      state.paginationData = action.payload.meta;
      state.getVideoListStatus = SUCCEEDED;
    },

    [getVideoList.rejected]: (state) => {
      state.getVideoListStatus = REJECTED;
    },

    [updateVideoListIsFavorite.pending]: (state) => {
      state.updateFavoriteStatus = PENDING;
    },

    [updateVideoListIsFavorite.fulfilled]: (state, action) => {
      state.updateFavoriteStatus = SUCCEEDED;
    },

    [updateVideoListIsFavorite.rejected]: (state) => {
      state.updateFavoriteStatus = REJECTED;
    },

    [updateVideoListLabels.pending]: (state) => {
      state.updateVideoListLabelsStatus = PENDING;
    },

    [updateVideoListLabels.fulfilled]: (state, action) => {
      let index = state.videoList.findIndex((item) => {
        return item.video.id === action.payload.id;
      });
      state.videoList[index].labels = sortAlphabeticallyByName(
        action.payload.labels
      );
      state.updateVideoListLabelsStatus = SUCCEEDED;
    },

    [updateVideoListLabels.rejected]: (state) => {
      state.updateVideoListLabelsStatus = REJECTED;
    },
  },
});

export const { syncVideoListVideoLabels } = videoList.actions;

export default videoList.reducer;
