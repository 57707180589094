import { Images } from "assets/images";
import { useEffect, useId, useRef, useState } from "react";
import { SimpleButton, SimpleModal } from "components";
import { EditLabel } from "./editLabel";
import { v4 as uuid } from "uuid";
import { colors } from "assets/styles/colors";
import { updateVideoLabels, updateVideoListLabels } from "store";
import { useDispatch, useSelector } from "react-redux";
import { SUCCEEDED } from "utils/constants";
import { syncVideoLabels } from "store/video/slice";
import { syncVideoListVideoLabels } from "store/videoList/slice";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

import {
  allFiltersFormatString,
  capitalizeFirstLetter,
  formatDateFilter,
  parameterizeArray,
} from "utils/heplers";

import {
  ButtonContainer,
  EditLabelContainer,
  EditLabelIcon,
  Error,
  LabelItem,
  LabelsWrapper,
} from "./styled";

export const Labels = ({
  items,
  id,
  isMainVideo,
  setCheckedLabels,
  checkedLabels,
}) => {
  const { updateLabelsStatus, fromFilterDate } = useSelector(
    (state) => state.video
  );

  const { updateVideoListLabelsStatus, getVideoListStatus } = useSelector(
    (state) => state.videoList
  );

  const [labelsCopy, setLabelsCopy] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [startDate, setStartDate] = useState(new Date(fromFilterDate));
  const [endDate, setEndDate] = useState(new Date());
  const [refresh, setRefresh] = useState(false);
  const uniqueId = useId();
  const dispatch = useDispatch();
  const editLabelModalRef = useRef(null);

  const closeModal = () => {
    setShowError(false);
    setOpenModal(false);
  };

  const editLabels = () => {
    setOpenModal(true);
  };

  const changeLabelName = (e, id) => {
    if (e.target.value.length < 51) {
      setLabelsCopy(
        labelsCopy.map((item) => {
          if (item.id === id) {
            return { ...item, name: e.target.value };
          }

          return item;
        })
      );
    } else {
      setShowError(true);
    }
  };

  const removeLabel = (id) => {
    setLabelsCopy(
      labelsCopy.filter((item) => {
        if (item.id !== id) {
          return item;
        }
      })
    );
  };

  const addLabel = () => {
    setLabelsCopy([...labelsCopy, { id: uuid(), name: "" }]);
  };

  const saveLabels = () => {
    const clearEmptyLabels = labelsCopy.filter(
      (item) => item.name.length !== 0
    );

    if (isMainVideo) {
      dispatch(updateVideoLabels({ id, labels: clearEmptyLabels }));
      dispatch(
        syncVideoListVideoLabels({
          changedLabels: clearEmptyLabels,
          videoId: id,
        })
      );
    } else {
      dispatch(updateVideoListLabels({ id, labels: clearEmptyLabels }));
      dispatch(
        syncVideoLabels({ changedLabels: clearEmptyLabels, videoId: id })
      );
    }
  };

  const applyFilter = () => {
    let filtersEncodeURI = allFiltersFormatString({
      labels: parameterizeArray("labels", checkedLabels),
      startDate: formatDateFilter(startDate),
      endDate: formatDateFilter(endDate),
    });

    localStorage.setItem("filters", filtersEncodeURI);

    // Todo need to check
    // const queryParams = {
    //   userId: user.id,
    //   code: user.security_string,
    //   activeTab: localStorage.getItem("activeTab"),
    //   filters: filtersEncodeURI,
    // };
    //
    // dispatch(getVideoList(queryParams));
  };

  const checkLabel = (name) => {
    setCheckedLabels([...checkedLabels, name]);

    const newArray = checkedLabels.filter((i) => i !== name);
    if (newArray.length < checkedLabels.length) {
      setCheckedLabels(newArray);
    }
    setRefresh(true);
  };

  const labels = items.map((label, index) => {
    const selected = checkedLabels?.find((e) => {
      return e.toLowerCase() === label.name.toLowerCase();
    });

    const textLength = label.name.length;

    return (
      <LabelItem
        onClick={() => checkLabel(label.name)}
        selected={!!selected}
        key={`${uniqueId}-${index}`}
      >
        {textLength > 17 ? (
          <> {capitalizeFirstLetter(label.name.substr(0, 16))}...</>
        ) : (
          <> {capitalizeFirstLetter(label.name)}</>
        )}
      </LabelItem>
    );
  });

  useEffect(() => {
    if (refresh) {
      applyFilter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setLabelsCopy([...items]);
  }, [items]);

  useEffect(() => {
    if (updateLabelsStatus === SUCCEEDED) {
      closeModal();
    }
  }, [updateLabelsStatus]);

  useEffect(() => {
    if (updateVideoListLabelsStatus === SUCCEEDED) {
      closeModal();
    }
  }, [updateVideoListLabelsStatus]);

  useEffect(() => {
    if (!localStorage.getItem("filters")) {
      setCheckedLabels([]);
      setStartDate(new Date(fromFilterDate));
      setEndDate(new Date());
    }
  }, [fromFilterDate, getVideoListStatus]);

  useEffect(() => {
    if (openModal) {
      disableBodyScroll(editLabelModalRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [openModal]);

  return (
    <LabelsWrapper className="labels-wrapper">
      {labels}

      <EditLabelIcon src={Images.EditLabel} alt="" onClick={editLabels} />

      <SimpleModal show={openModal} close={closeModal}>
        <EditLabelContainer ref={editLabelModalRef}>
          {showError ? (
            <Error>
              <p>Each label can have up to 50 characters</p>
            </Error>
          ) : null}
          {labelsCopy.map((label, index) => (
            <EditLabel
              key={`${uniqueId}-${index}`}
              label={label}
              changeLabelName={changeLabelName}
              remove={removeLabel}
            />
          ))}

          {labelsCopy.length < 12 && (
            <img src={Images.AddIcon} alt="" onClick={addLabel} />
          )}

          <ButtonContainer>
            <SimpleButton
              event={saveLabels}
              text="Save"
              style={{ background: colors.primary, color: colors.white }}
            />
          </ButtonContainer>
        </EditLabelContainer>
      </SimpleModal>
    </LabelsWrapper>
  );
};
