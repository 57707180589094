import { useEffect, useState } from "react";
import {
  hideAllTips,
  removeActiveMarker,
  scrollTopFunction,
  validDate,
} from "utils/heplers";
import { useDispatch } from "react-redux";
import { addVideoMarker, deleteVideoMarker, updateVideoMarker } from "store";
import { useMediaQuery } from "@mui/material";
import { ANALYTIC_TYPES, SCREEN_SIZES } from "utils/constants";
import { useAnalytics } from "hooks";
import { ConfirmDeleteMarkerModal } from "components";
import { isMobile, useMobileOrientation } from "react-device-detect";
import {
  Container,
  OnVideoContainer,
  StyledInput,
  StyledButton,
  StyledMarketDescriptionContainer,
  SubmitButton,
  DeleteButton,
} from "./styled";

const MarkerDescription = ({
  videoId,
  setIsAddMarker,
  isAddMarker,
  player,
  selectedMarker,
  isEditMarker,
  setIsEditMarker,
  handleRemoveMarker,
  handleCancelMarker,
}) => {
  const [markerText, setMarkerText] = useState("");
  const [markerOrVideoTime, setMarkerOrVideoTime] = useState("");
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const { isLandscape } = useMobileOrientation();
  const dispatch = useDispatch();
  const analyticActions = useAnalytics();

  const isMobileSx = useMediaQuery(`(max-width:${SCREEN_SIZES.sm})`);
  const mouseHoverProgressBar = document.querySelector(".mouse-hover-progress");

  function handleResize(event) {
    if (window.innerHeight <= window.visualViewport.height) {
      window.visualViewport.removeEventListener("resize", handleResize);
    } else {
      let input = document.querySelector(".marker-input");
      const rect = input.getBoundingClientRect();
      const heightFromTop = rect.top + window.pageYOffset;
      let viewportHeight = event.target.height;
      let targetPosition =
        heightFromTop + input.offsetHeight - viewportHeight + 10;

      scrollTopFunction(Math.abs(targetPosition), 0, "auto");
    }
  }

  const onFocusEditInput = () => {
    if (!isMobile) return;
    window.visualViewport.addEventListener("resize", handleResize);
  };

  const onBlurEditInput = () => {
    if (!isMobile) return;

    if (isLandscape) {
      scrollTopFunction(95);
    } else {
      scrollTopFunction(0);
    }
  };

  const handleAddMarker = () => {
    dispatch(
      addVideoMarker({
        time: markerOrVideoTime,
        text: markerText.length > 0 ? markerText : "Untitled marker",
        video_id: videoId,
      })
    );
    analyticActions.update(ANALYTIC_TYPES.marker_count);
    setIsAddMarker(false);
    setIsEditMarker(false);
    removeActiveMarker(4000);
    hideAllTips();
    mouseHoverProgressBar?.classList.remove("mouse-hover-progress-active");

    if (isMobile) {
      if (isLandscape) {
        scrollTopFunction(95);
      } else {
        scrollTopFunction(0);
      }
    }
  };

  const confirmDeleteButton = () => {
    if (selectedMarker.is_highlight) {
      setOpenConfirmDeleteModal(true);
    } else {
      deleteMarker();
    }
  };

  const deleteMarker = () => {
    setOpenConfirmDeleteModal(false);
    mouseHoverProgressBar?.classList.remove("mouse-hover-progress-active");
    dispatch(deleteVideoMarker({ id: selectedMarker.id }));
    handleRemoveMarker(selectedMarker.id);
    setIsAddMarker(false);
    setIsEditMarker(false);
    hideAllTips();
  };

  const cancelButton = () => {
    mouseHoverProgressBar?.classList.remove("mouse-hover-progress-active");
    handleCancelMarker();
    removeActiveMarker(0);
    setIsAddMarker(false);
    setIsEditMarker(false);
    hideAllTips();
  };

  const updateMarker = () => {
    dispatch(
      updateVideoMarker({
        id: selectedMarker.id,
        text: markerText.length > 0 ? markerText : "Untitled marker",
      })
    );
    mouseHoverProgressBar?.classList.remove("mouse-hover-progress-active");
    setIsAddMarker(false);
    setIsEditMarker(false);
    removeActiveMarker(4000);
    hideAllTips();
  };

  const startTimeValidFormat = validDate(markerOrVideoTime);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        document.querySelector(".add-marker-input")?.focus();
      }, 100);
    }

    if (isEditMarker) {
      setMarkerOrVideoTime(selectedMarker?.time);

      if (selectedMarker.text === "Untitled marker") {
        setMarkerText("");
      } else {
        setMarkerText(selectedMarker.text);
      }

      return;
    }

    if (isAddMarker) {
      player.pause();
    }

    setMarkerOrVideoTime(player?.cache_?.currentTime);
    setMarkerText("");
  }, [isAddMarker]);

  useEffect(() => {
    if (selectedMarker.text === "Untitled marker") {
      setMarkerText("");
    } else {
      setMarkerText(selectedMarker.text);
    }
  }, [selectedMarker]);

  return (
    <>
      {isAddMarker && (
        <>
          <OnVideoContainer />

          <StyledMarketDescriptionContainer className="marker-desc-container">
            <Container
              isEditMarker={isEditMarker}
              className="marker-desc-content"
            >
              <StyledInput
                onChange={(e) => setMarkerText(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                value={markerText}
                onFocus={onFocusEditInput}
                onBlur={onBlurEditInput}
                placeholder="Add marker description (optional)"
                variant="outlined"
                inputProps={{ className: "add-marker-input" }}
                className="marker-input"
              />

              {isEditMarker ? (
                <>
                  <SubmitButton isEditMarker onClick={updateMarker}>
                    {isMobileSx ? "Save" : "Save marker"}
                  </SubmitButton>

                  <DeleteButton
                    isEditMarker={isEditMarker}
                    onClick={confirmDeleteButton}
                  >
                    Delete
                  </DeleteButton>
                </>
              ) : (
                <SubmitButton
                  onClick={handleAddMarker}
                  className="add-marker-button"
                >
                  {isMobile
                    ? "Add marker"
                    : `Add marker at ${startTimeValidFormat}`}
                </SubmitButton>
              )}

              <StyledButton onClick={cancelButton} className="marker-cancel">
                Cancel
              </StyledButton>
            </Container>
          </StyledMarketDescriptionContainer>
        </>
      )}
      <ConfirmDeleteMarkerModal
        show={openConfirmDeleteModal}
        close={() => setOpenConfirmDeleteModal(false)}
        event={deleteMarker}
      />
    </>
  );
};

export default MarkerDescription;
