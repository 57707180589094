import videojs from "video.js";
import "./style.scss";
import "js/videojs/skins/nuevo/videojs.min.css";
import "js/videojs/nuevo.min.js";
import "js/videojs/video.min";
import "js/videojs/thumbnails";
import "js/videojs/markers/videojs-markers.min.js";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EllipsesMenu from "../EllipsesMenu";
import AddMarker from "../AddMarker";
import VideoCameras from "../VideoNumber";
import MarkerDescription from "../AddMarker/components/MarkerDescription";
import { MouseHoverProgress } from "../mouseHoverProgress";
import { MakeClip } from "../MakeClip";
import { useAnalytics } from "hooks";
import { VideoProgressBar } from "../VideoProgressBar";
import { ToastMessage } from "../ToastMessage";
import {
  isMobile,
  useMobileOrientation,
  withOrientationChange,
} from "react-device-detect";
import { Images } from "assets/images";
import { SimpleButton } from "components";
import { colors } from "assets/styles/colors";
import {
  clearMarkersObject,
  compareNowAndDateBySecond,
  debounce,
  getOffset,
  hideAllTips,
  removeActiveMarker,
  scrollTopFunction,
} from "utils/heplers";
import { v4 as uuid } from "uuid";
import {
  ANALYTIC_TYPES,
  ArrowLeft,
  ArrowRight,
  KeyJ,
  KeyK,
  KeyM,
  MARKERS_CLASS,
  SPACE,
} from "utils/constants";
import {
  closeMakeClip,
  openMakeClip,
  setActiveMarker,
  setClipDistance,
  setPlayerPlayed,
  setPlayerRef,
  setScrollPosition,
  setVideoCurrentResolution,
  setVideoCurrentTime,
  setVideoPlayerReady,
} from "store";
import {
  StyledVideoPlayerContainer,
  StyledVideo,
  PlayStopContainer,
  PlayButtonContainer,
  BackgroundBLack,
  BackgroundBlur,
} from "./styled";

let lastStartTime = 0;

const VideoPlayer = ({ options, onReady }) => {
  const {
    makeClipAction,
    saveClip,
    markers,
    video,
    analyticId,
    videoCurrentTime,
    scrollPosition,
    editeMarkerMode,
    activeMarker,
  } = useSelector((state) => state.video);

  const [isAddMarker, setIsAddMarker] = useState(false);
  const [isEditMarker, setIsEditMarker] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState({});
  const [isEllipsesMenu, setIsEllipsesMenu] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mainClassName, setMainClassName] = useState("");
  const [copyMarkers, setCopyMarkers] = useState([...markers]);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [serverError, setServerError] = useState(false);

  const videoContainer = useRef(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const dispatch = useDispatch();
  const analyticActions = useAnalytics();
  const { isLandscape } = useMobileOrientation();

  const screenWidth = window.innerWidth;

  //start tip position functionality
  const tip = document.querySelector(".vjs-tip");
  const getMarkerPosition =
    tip && window.getComputedStyle(tip, null).getPropertyValue("left");

  const tipWidth = tip?.offsetWidth;
  const tipHeight = tip?.offsetHeight;
  const videoWidth = document.querySelector("#video")?.offsetWidth;

  if (getMarkerPosition && tip && tipWidth && videoWidth) {
    if (parseInt(getMarkerPosition) < tipWidth / 2) {
      tip.style.marginLeft = `-${getMarkerPosition}`;
    } else if (parseInt(getMarkerPosition) > videoWidth - tipWidth / 2) {
      const leftDistance =
        parseInt(getMarkerPosition) - (videoWidth - tipWidth);
      tip.style.marginLeft = `${-leftDistance}px`;
    }
  }
  //end

  function removeZeroDotFromSpeedRate() {
    const vjsSpeedElements = document.querySelectorAll(".vjs-speed");

    if (vjsSpeedElements.length < 1) return false;

    for (let i = 0; i < vjsSpeedElements.length; i++) {
      let elem = vjsSpeedElements[i];

      elem.innerText = elem.innerText.replaceAll("0.", ".");
    }

    return true;
  }

  function keyDownEvents(e) {
    const player = playerRef.current;
    if (
      document.querySelector(".add-marker-active") ||
      document.querySelector(".hidden-bar")
    )
      return;
    switch (e.code) {
      case SPACE:
        e.preventDefault();
        if (!player.hasStarted_) {
          toggleUserActive();
        }
        player.paused() ? player.play() : player.pause();
        break;
      case KeyM:
        handleAddMarker();
        break;
      case KeyJ:
        seekFrame(false, player);
        break;
      case KeyK:
        seekFrame(true, player);
        break;
      case ArrowLeft:
        seek(-15, player);
        break;
      case ArrowRight:
        seek(+15, player);
        break;
      default:
        break;
    }
    return false;
  }

  function addThumbnailInVideo(player) {
    const thumbnailBaseUrl = video.url_scrubbing_thumbnail
      ? video.url_scrubbing_thumbnail.substring(
          0,
          video.url_scrubbing_thumbnail.lastIndexOf("/")
        ) + "/"
      : "";

    player.thumbnails({
      basePath: thumbnailBaseUrl,
      width: 256,
      height: 144,
    });

    player.on("ready", function () {
      let track = {
        kind: "metadata",
        src: video.url_scrubbing_vtt,
      };
      player.loadTracks(track);
    });
  }

  function handleAddMarker(isHighlight = false, id = null) {
    const player = videojs("video");
    setCopyMarkers((prev) => [
      ...clearMarkersObject(prev),
      {
        id: id ?? uuid(),
        text: "",
        time: player.currentTime(),
        video_id: video.id,
        class: isHighlight
          ? MARKERS_CLASS.active + " " + MARKERS_CLASS.highlight
          : MARKERS_CLASS.active,
      },
    ]);
    setIsAddMarker(true);

    if (isMobile && isLandscape) {
      scrollTopFunction(105);
    }
  }

  function handleCancelMarker() {
    setCopyMarkers((prev) => [
      ...prev.filter(
        (item) => !item.class?.toString()?.includes(MARKERS_CLASS.active)
      ),
    ]);

    if (isMobile) {
      if (isLandscape) {
        scrollTopFunction(105);
      } else {
        scrollTopFunction();
      }
    }
  }

  function addMarkersInVideo(player) {
    player.markers({
      markerStyle: {
        height: "18px",
        width: "12px",
        "border-radius": "none",
        "margin-top": "-33px",
        "background-size": "cover",
        "background-repeat": "no-repeat",
        "background-color": "none",
      },
      markerTip: {
        display: true,
        text: function (data) {
          setMainClassName((prevState) => prevState + " marker-hover");
          return data.text !== "" ? data.text : "loading...";
        },
      },
      breakOverlay: {
        display: true,
        text: function (data) {
          return data.text;
        },
        style: {
          height: "max-content",
          "font-family": "Azo Sans Regular",
        },
      },
      onMarkerClick: function (data) {
        if (isMobile) return;
        setSelectedMarker(data);
        setIsAddMarker(true);
        setIsEditMarker(true);
        let markerElement = player
          .el()
          .querySelector(".vjs-marker[data-marker-key='" + data.key + "']");

        markerElement.className += " " + MARKERS_CLASS.active;
        if (data.is_highlight) {
          markerElement.className += " " + MARKERS_CLASS.highlight;
        }

        player.pause();
      },
      onMarkerReached: function (data, index) {
        console.log("onMarkerReached");
      },
      markers: JSON.parse(JSON.stringify(copyMarkers)),
    });
  }

  function handleRemoveMarker(id) {
    setCopyMarkers((prev) => [
      ...prev.filter(
        (item) =>
          item.id !== id &&
          !item.class?.toString()?.includes(MARKERS_CLASS.active)
      ),
    ]);
  }

  function toggleUserActive(active = false) {
    if (active) {
      videoContainer.current.classList.add("user-active");
      videoContainer.current.classList.remove("user-inactive");
    } else {
      videoContainer.current.classList.add("user-inactive");
      videoContainer.current.classList.remove("user-active");
    }
  }

  function toggleUserActiveMobile() {
    if (videoContainer.current.classList.contains("user-active")) {
      videoContainer.current.classList.add("user-inactive");
      videoContainer.current.classList.remove("user-active");
    } else {
      videoContainer.current.classList.add("user-active");
      videoContainer.current.classList.remove("user-inactive");
      hideUserActive();
    }
  }

  function generateClassName() {
    let className = "";

    if (makeClipAction) {
      className = "hidden-bar";
    }

    if (isAddMarker) {
      className = className + " add-marker-active";
      if (isMobile) document.body.classList.add("add-marker-active-mobile");
    } else {
      if (isMobile) document.body.classList.remove("add-marker-active-mobile");
    }

    if (hasPlayed) {
      className = className + " has-played";
    }

    if (editeMarkerMode || activeMarker) {
      className = className + " edit-marker-mode";
      if (isMobile) document.body.classList.add("edit-marker-mode-mobile");
    } else {
      if (isMobile) document.body.classList.remove("edit-marker-mode-mobile");
    }

    setMainClassName(className);
  }

  function togglePlayPausePlayer(play = true) {
    const player = videojs("video");
    if (play) {
      player.pause();
    } else {
      player.play();
      addMarkersEvent();
    }
  }

  function closeMobilePlayer() {
    const player = videojs("video");
    if (isMobile) {
      document.body.style.overflow = "unset";
      document.body.classList.remove("mobile-played");
    }
    player.pause();
    setHasPlayed(false);
  }

  function closeMobileAddMarkerMode() {
    setIsAddMarker(false);
    handleCancelMarker();
    removeActiveMarker(0);
    hideAllTips();
  }

  function seekFrame(isForward, player) {
    player.pause();

    let time = isForward
      ? Math.min(player.duration(), player.currentTime() + 0.04)
      : Math.min(player.duration(), player.currentTime() - 0.04);

    if (time < 0) {
      time = 0;
    }

    player.currentTime(time);
    analyticActions.update(ANALYTIC_TYPES.move_frame_count);
  }

  function seek(secs, player) {
    let time = player.currentTime() + secs;

    if (time < 0) {
      time = 0;
    }

    player.currentTime(time);
  }

  function initPlayerSeekListeners(player) {
    const seekForward15 = player.el().querySelector(".skip-forward.skip-15");
    const seekBack15 = player.el().querySelector(".skip-back.skip-15");

    seekForward15?.addEventListener("click", function () {
      analyticActions.update(ANALYTIC_TYPES.forward_10s_count);
    });

    seekBack15?.addEventListener("click", function () {
      analyticActions.update(ANALYTIC_TYPES.backward_10s_count);
    });
  }

  function addMarkersEvent() {
    const player = videojs("video");
    const markersDom = player.el().querySelectorAll(".vjs-marker");
    markersDom.forEach(function (element) {
      if (isMobile) {
        element.addEventListener("touchend", () => {
          let scrollHeight = 95;
          if (!document.body.classList.contains("mobile-view-marker-data")) {
            scrollHeight = (window.innerHeight * 62.5) / 100;
            document.body.classList.add("mobile-view-marker-data");
          }
          if (isLandscape) {
            setTimeout(() => scrollTopFunction(scrollHeight), 500);
          } else {
            setTimeout(() => scrollTopFunction(0), 500);
          }
        });
      } else {
        element.addEventListener("mouseleave", () => {
          generateClassName();
        });
      }
    });
  }

  function addProgressControlEvent() {
    const progressControl = document.querySelector(".vjs-progress-control");

    if (isMobile) {
      progressControl.addEventListener("touchend", () => {
        if (isLandscape) {
          setTimeout(() => scrollTopFunction(95), 400);
        } else {
          setTimeout(() => scrollTopFunction(0), 400);
        }
      });
    }
  }

  function showControlBarBeforePlay(player) {
    let playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          setTimeout(() => {
            player.pause();
            player.muted(false);
            document.querySelector(".vjs-poster").style.opacity = "1";
          }, 200);
        })
        .catch((error) => {
          console.log("play error", error);
        });
    }
  }

  const makeClipHandler = () => {
    dispatch(openMakeClip());
  };

  function closeViewMobileMarkerMode() {
    document.body.classList.remove("mobile-view-marker-data");
    dispatch(setActiveMarker(null));
  }

  const hideUserActive = debounce(() => {
    toggleUserActive(false);
  }, 4000);

  useEffect(() => {
    generateClassName();
  }, [isAddMarker, makeClipAction, hasPlayed, editeMarkerMode, activeMarker]);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        setTimeout(() => {
          dispatch(setVideoPlayerReady());
        }, 1000);
        onReady && onReady(player);
      }));

      player.nuevo({
        zoomInfo: false,
        zoomWheel: true,
        hdicon: false,
        contextMenu: false,
        tooltips: false,
        chapterMarkers: false,
        ghostThumb: true,
      });

      addThumbnailInVideo(player);

      addMarkersInVideo(player);
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);

      if (videoCurrentTime) {
        player.src(options.sources);
        player.poster(Images.BlackBackground);
        player.currentTime(videoCurrentTime);
        player.play();
        dispatch(setVideoCurrentTime(null));
      } else {
        player.src(options.sources);
        player.poster(options.poster);
        dispatch(setVideoCurrentResolution(null));
        player.el().querySelector(".vjs-poster").style.opacity = "1";
      }

      setHasPlayed(false);

      player.one("loadedmetadata", function () {
        addThumbnailInVideo(player);
        player.markers.reset(JSON.parse(JSON.stringify(copyMarkers)));
        addMarkersEvent();

        if (scrollPosition) {
          scrollTopFunction(scrollPosition);
          dispatch(setScrollPosition(null));
        }
      });

      if (isMobile) {
        document.body.style.overflow = "unset";
        document.body.classList.remove("mobile-played");
      }

      //Todo open if need hide control-bar
      // if (!isMobile && !videoCurrentTime) {
      //   player.ready(function () {
      //     showControlBarBeforePlay(player);
      //   });
      // }
    }
  }, [options, videoRef]);

  useEffect(() => {
    if (playerRef.current && analyticId) {
      const player = playerRef.current;
      document.body.addEventListener("keydown", keyDownEvents, false);

      initPlayerSeekListeners(player);

      player.on("firstplay", function () {
        const removeZeroDotFromSpeedRateInterval = setInterval(() => {
          if (removeZeroDotFromSpeedRate() !== false) {
            clearInterval(removeZeroDotFromSpeedRateInterval);
          }
        });
        analyticActions.play();
      });

      player.on("play", function () {
        dispatch(setPlayerPlayed(1));
        player.el().querySelector(".vjs-poster").style.opacity = "0";

        if (isMobile) {
          window.scrollTo(0, 0);
          document.body.style.overflow = "hidden";
          document.body.classList.add("mobile-played");
          hideUserActive();
        }
        setHasPlayed(true);

        lastStartTime = player.currentTime();
      });

      player.on("pause", function () {
        const watched = {
          duration:
            player.currentTime() - lastStartTime > 0
              ? player.currentTime() - lastStartTime
              : player.currentTime(),
          current_time: player.currentTime(),
        };

        analyticActions.pause(watched);
      });

      player.on("ended", function () {
        analyticActions.pause({ is_end: true });
      });

      player.on("click", function () {
        document.activeElement.blur();
      });

      player.one("loadedmetadata", function () {
        addMarkersEvent();
        if (isMobile) {
          const controlBar = player.el().querySelector(".vjs-control-bar");
          const videoCameras = player.el().querySelector(".video-cameras");
          videoCameras?.addEventListener("touchmove", function () {
            hideUserActive();
          });
          controlBar?.addEventListener("touchstart", function () {
            hideUserActive();
          });
          controlBar?.addEventListener("click", function () {
            hideUserActive();
          });
        }
      });

      if (isMobile) {
        player.on("touchstart", function (e) {
          if (
            e.target.className?.toString().includes("video-js") ||
            e.target.className?.toString().includes("play-stop-container")
          ) {
            toggleUserActiveMobile();
          } else {
            hideUserActive();
          }
        });

        player.on("touchmove", function () {
          hideUserActive();
        });

        player.on("touchend", function () {
          hideUserActive();
        });
      } else {
        player.on("useractive", function () {
          toggleUserActive(true);
        });

        player.on("userinactive", function () {
          toggleUserActive();
        });

        player.on("mouseover", function () {
          toggleUserActive(true);
        });

        player.on("mouseout", function () {
          toggleUserActive();
        });

        //Todo open if need hide control-bar
        // player.ready(function () {
        //   showControlBarBeforePlay(player);
        // });
      }
    }
  }, [playerRef.current, analyticId]);

  useEffect(() => {
    const player = playerRef.current;
    dispatch(setPlayerRef(player));

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
        player.forward();
      }
    };
  }, [playerRef]);

  useEffect(() => {
    if (videoRef) {
      setScreenSize(getOffset(videoRef.current).left);
    }
  }, [screenWidth]);

  useEffect(() => {
    const player = videojs("video");
    player.markers.reset(JSON.parse(JSON.stringify(copyMarkers)));
    addMarkersEvent();
  }, [copyMarkers]);

  useEffect(() => {
    setCopyMarkers([
      ...markers.map((item) => {
        if (compareNowAndDateBySecond(item.updated_at) < 4) {
          return { ...item, class: MARKERS_CLASS.active + " " + item.class };
        } else {
          return item;
        }
      }),
    ]);
  }, [markers]);

  useEffect(() => {
    if (isMobile) document.body.classList.add("is-mobile");
  }, [isMobile]);

  useEffect(() => {
    if (makeClipAction) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [makeClipAction]);

  useEffect(() => {
    scrollTopFunction(0, 0, "auto");
    dispatch(closeMakeClip());
    dispatch(setClipDistance([]));
    if (isMobile) {
      addMarkersEvent();
      addProgressControlEvent();
    }
  }, [isLandscape]);

  useEffect(() => {
    analyticActions.create();

    const progressHolder = document.querySelector(".vjs-progress-holder");

    if (progressHolder) {
      if (!isMobile) {
        progressHolder.addEventListener("dblclick", (e) => {
          const isHighlight = e.target.className
            .split(" ")
            .includes(MARKERS_CLASS.highlight);
          handleAddMarker(isHighlight);
        });
      } else {
        // todo check mobile screen change
        // progressHolder.addEventListener("touchmove", (e) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        // });
      }
    }

    const progressControl = document.querySelector(".vjs-progress-control");
    const mouseHoverProgressBar = document.querySelector(
      ".mouse-hover-progress"
    );

    if (progressControl && mouseHoverProgressBar) {
      progressControl.onmouseenter = function (e) {
        if (this === e.target) {
          mouseHoverProgressBar.classList.add("mouse-hover-progress-active");
        }
      };

      progressControl.onmouseleave = function (e) {
        if (this === e.target) {
          mouseHoverProgressBar.classList.remove("mouse-hover-progress-active");
        }
      };
    }
  }, []);

  return (
    <>
      <StyledVideoPlayerContainer
        className={mainClassName}
        tipHeight={tipHeight}
        ref={videoContainer}
      >
        <div data-vjs-player>
          <StyledVideo
            playsInline
            id="video"
            ref={videoRef}
            className="video-js vjs-fluid w-full vjs-big-play-centered vjs-16-9"
          />

          {!makeClipAction ? (
            <>
              <VideoProgressBar player={playerRef.current} />
              <VideoCameras setIsEllipsesMenu={setIsEllipsesMenu} />
              <AddMarker handleAddMarker={handleAddMarker} />
            </>
          ) : null}

          <ToastMessage serverError={serverError} />

          {isMobile ? (
            <PlayStopContainer className="play-stop-container">
              <button
                className="mob-play-btn"
                onClick={() => togglePlayPausePlayer(false)}
              >
                <img
                  src={Images.MobilePlay}
                  alt="Play"
                  width="24"
                  height="26"
                  className="play-img"
                />
              </button>

              <button
                className="mob-pause-btn"
                onClick={() => togglePlayPausePlayer(true)}
              >
                <img
                  src={Images.MobilePause}
                  alt="Pause"
                  width="22"
                  height="26"
                  className="pause-img"
                />
              </button>
            </PlayStopContainer>
          ) : !makeClipAction ? (
            <PlayButtonContainer className="play-button-container">
              <button className="" onClick={() => togglePlayPausePlayer(false)}>
                <img
                  src={Images.MobilePlay}
                  alt="Play"
                  width="24"
                  height="26"
                  className="play-img"
                />
              </button>
            </PlayButtonContainer>
          ) : null}
        </div>

        <BackgroundBLack
          className="black-background"
          onTouchStart={toggleUserActiveMobile}
        >
          <img
            src={Images.WhiteCloseIcon}
            alt="close"
            onClick={
              isMobile && isAddMarker
                ? closeMobileAddMarkerMode
                : closeMobilePlayer
            }
          />
        </BackgroundBLack>

        {isMobile ? (
          <>
            <SimpleButton
              className={"mobile-make-clip-btn"}
              text={""}
              icon={Images.ScissorsMobile}
              event={makeClipHandler}
              style={{ background: colors.primary, color: colors.white }}
            />

            <SimpleButton
              className={"mobile-exit-marker-btn"}
              text={"Exit"}
              event={closeViewMobileMarkerMode}
              style={{ background: "transparent" }}
            />
          </>
        ) : (
          <MouseHoverProgress
            screenSize={screenSize}
            makeClipAction={makeClipAction}
          />
        )}

        {makeClipAction ? (
          <MakeClip />
        ) : (
          <>
            <EllipsesMenu
              isEllipsesMenu={isEllipsesMenu}
              setIsEllipsesMenu={setIsEllipsesMenu}
            />

            <MarkerDescription
              videoId={video.id}
              player={playerRef.current}
              selectedMarker={selectedMarker}
              isEditMarker={isEditMarker}
              setIsEditMarker={setIsEditMarker}
              isAddMarker={isAddMarker}
              setIsAddMarker={setIsAddMarker}
              handleRemoveMarker={handleRemoveMarker}
              handleCancelMarker={handleCancelMarker}
            />
          </>
        )}
      </StyledVideoPlayerContainer>

      {makeClipAction && !saveClip ? <BackgroundBlur /> : null}
    </>
  );
};

export default withOrientationChange(VideoPlayer);
