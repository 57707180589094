import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const MainVideoInfoWrapper = styled.div`
  ${styles.FlexRow};
  align-items: flex-start;
  width: 100%;
  margin-bottom: 31px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-bottom: 0;
  }
`;

export const DateWrapper = styled.div`
  ${styles.FlexColumn};
  justify-content: center;
  margin-right: 48px;
  min-width: 104px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-right: 12px;
  }
`;

export const WeekDay = styled.span`
  color: ${colors.black};
  ${styles.FontBold}
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 3px;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    margin-bottom: 6px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 14px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-top: 0px;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 7px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-top: 2px;
    margin-bottom: 7px;
  }
`;

export const Date = styled.span`
  color: ${colors.black};
  ${styles.FontRegular}
  font-size: 16px;
  line-height: 19px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const LabelSection = styled.div`
  ${styles.FlexColumn};
  max-width: 616px;
  width: 100%;
`;

export const FullVideo = styled.div`
  color: ${colors.primary};
  ${styles.FontBold}
  font-size: 12px;
  line-height: 15px;
  width: fit-content;
  transition: 0.2s;

  ${isMobile} && {
    &:hover {
      text-decoration-line: underline;
    }
  }
`;
