import { Images } from "assets/images";
import { LabelInputContainer } from "./styled";

export const EditLabel = ({ label, remove, changeLabelName }) => (
  <LabelInputContainer>
    <input
      value={label.name || ""}
      onChange={(e) => changeLabelName(e, label.id)}
      onKeyDown={(e) => e.stopPropagation()}
    />
    <img src={Images.DeleteIcon} alt="" onClick={() => remove(label.id)} />
  </LabelInputContainer>
);
