import styled from "styled-components/macro";
import { Images } from "assets/images";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles.js";
import { SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const StyledVideoPlayerContainer = styled.div`
  position: relative;
  margin-top: -2px;
  z-index: 3;

  .mobile-make-clip-btn {
    display: none;
    z-index: 3;
    padding: unset;
    margin: unset;
    height: 39px;
    width: 39px;
    position: absolute;
    top: -53px;
    left: 10px;

    img {
      margin: 13px auto 11px auto;
      padding: unset;
    }
  }

  .mobile-exit-marker-btn {
    display: none;
    z-index: 3;
    padding: unset;
    margin: unset;
    position: absolute;
    top: -23px;
    right: 10px;
    color: ${colors.white};

    :hover,
    :active {
      border-radius: unset;
      border-bottom: 1px solid ${colors.white};
    }
  }

  .vjs-control-bar {
    .vjs-custom-control-spacer {
      display: none !important;
    }
  }

  .mouse-hover-progress {
    height: 4px;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 39px;
    left: 2px;
    overflow: hidden;
    display: none;
  }

  .mouse-hover-progress-active {
    display: block !important;
  }

  &.hidden-bar {
    .vjs-control-bar {
      opacity: 0 !important;
      pointer-events: none;
    }

    .mouse-hover-progress {
      display: none !important;
    }

    .vjs-poster {
      //opacity: 0 !important;
    }
  }

  // initial condition
  .inactive-timeline {
    display: none;
  }

  .add-marker {
    display: none;
    width: 30px;
    height: 39px;
    padding: 11px 8px;
    transition: 1s;
    z-index: 1;
    overflow: hidden;

    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      width: 20px;
      height: 24px;
      padding: 6px 6px;
    }
  }

  .video-cameras {
    display: none;
    z-index: 1;
  }

  .mob-pause-btn {
    display: none;
  }

  .mob-play-btn {
    display: none;
  }

  // inactive condition
  &.user-inactive {
    .vjs-has-started {
      .vjs-control-bar {
        opacity: 0;
      }
      .inactive-timeline {
        display: block;
      }
    }
    .mouse-hover-progress {
      display: none !important;
    }

    .video-cameras {
      display: none !important;
    }

    .add-marker {
      width: 30px !important;
      height: 39px !important;
      padding: 11px 8px !important;
      transition: 1s !important;
      z-index: 1 !important;
      overflow: hidden !important;

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        width: 20px !important;
        height: 24px !important;
        padding: 6px 6px !important;
      }
    }
  }

  // active condition
  &.user-active {
    .vjs-has-started {
      .vjs-control-bar {
        opacity: 1;
      }

      .inactive-timeline {
        display: none;
      }
    }

    .add-marker {
      padding: 11px 14px 6px;
      transition: 0.5s;
      width: 129px;

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        padding: 6px 7px;
        width: 84px;
      }
    }

    .video-cameras {
      display: flex;
    }

    .inactive-timeline {
      display: none !important;
    }
  }

  .vjs-user-active {
    .video-cameras {
      display: flex;
    }
    .add-marker {
      padding: 11px 14px 6px;
      transition: 0.5s;
      width: 129px;

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        padding: 6px 7px;
        width: 84px;
      }
    }
  }

  // add marker process
  &.add-marker-active {
    .vjs-control-bar {
      opacity: 1;

      .vjs-progress-control {
        .vjs-tip {
          opacity: 0;
        }
      }
    }

    .vjs-thumbnail-holder {
      display: none !important;
    }

    .vjs-has-started {
      .vjs-control-bar {
        opacity: 1;
      }
    }

    .inactive-timeline {
      display: block;
      bottom: 68px;
      height: 2px;
    }

    .add-marker {
      display: none;
    }

    .active-marker {
      margin-top: -65px !important;
    }

    .video-cameras {
      display: none !important;
    }

    .inactive-timeline {
      display: none !important;
    }
  }

  &.marker-hover {
    .vjs-control-bar {
      opacity: 1;
      .vjs-progress-control {
        .vjs-thumbnail-holder {
          bottom: ${({ tipHeight }) => tipHeight + 48}px;
        }
        .vjs-thumb-show {
          //display: none;
        }
      }
    }
    .video-cameras {
      display: flex;
    }
    .add-marker {
      padding: 11px 14px 6px;
      transition: 0.5s;
      width: 129px;

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        padding: 6px 7px;
        width: 84px;
      }
    }
  }
`;

export const StyledVideo = styled.video`
  border: 1px solid rgba(18, 18, 18, 0.2);
  margin-top: -2px;
  overflow: hidden;

  .vjs-big-play-button {
    display: none !important;

    .vjs-icon-placeholder {
      display: none;
    }
  }

  .vjs-picture-in-picture-control {
    display: none;
  }

  .vjs-rewind-control {
    display: none;
  }

  .vjs-thumb-poster {
    display: none;
  }

  .vjs-poster {
    background-size: cover;
  }

  // CONTROL BAR

  .vjs-control-bar {
    align-items: center;
    height: 100%;
    transition: all 0.2s;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -73.84%,
      rgba(0, 0, 0, 0.27) 55.73%
    );

    // PLAY BTN

    .vjs-play-control {
      width: 53px;
      max-height: 32px;
      background-color: rgba(255, 255, 255, 0.2);
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin-left: 17px;
      order: 1;

      .vjs-icon-placeholder:before {
        content: url(${Images.PlayIcon});
        width: 22px;
        background-size: 100%;
        margin-left: 0px;
        position: absolute;
        opacity: 0.85;
        object-fit: cover;
        left: 15px;
        top: 6px;
      }

      &.vjs-playing {
        .vjs-icon-placeholder:before {
          content: url(${Images.StopIcon});
          width: 20px;
          height: 20px;
          background-size: 100%;
          margin-left: 0;
          opacity: 0.85;
          position: absolute;
          object-fit: cover;
          left: 14px;
          top: 7px;
        }
      }

      &:hover {
        .vjs-icon-placeholder:before {
          opacity: 1;
        }
      }

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        margin-left: 10px;
        width: 35px;

        .vjs-icon-placeholder:before {
          left: 6px;
          content: url(${Images.PlaySmallIcon});
        }

        &.vjs-playing {
          .vjs-icon-placeholder:before {
            content: url(${Images.StopSmallIcon});
            left: 6px;
          }
        }
      }
    }

    // SPEED

    .vjs-cog-menu-button {
      position: relative;
      display: block !important;
      order: 2;

      :hover {
        background-color: #0000;
      }

      .vjs-menu-button {
        display: none;
      }

      .vjs-extend-zoom {
        display: none !important;
      }

      .vjs-share-button {
        display: none !important;
      }

      .vjs-settings-item {
        display: none !important;
      }

      .vjs-menu-speed {
        display: block !important;
        position: absolute;
        top: 4px;
        left: -55px;
      }

      .vjs-quality-selector {
        width: auto;
        margin-left: 5px;
        margin-right: 5px;
        text-align: center;
      }

      .vjs-settings-div {
        display: block !important;
      }

      .vjs-menu-settings {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
        width: max-content;
        transform: scale(1) !important;

        .vjs-settings-home {
          display: none;
        }

        .vjs-menu-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          background: rgba(255, 255, 255, 0.2);
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          padding: 4px 0;
          width: 197px;

          .vjs-speed {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            border: 2px solid transparent;
            text-align: center;
            border-radius: 5px;
            overflow: hidden;

            &:nth-child(2) {
              margin-right: 7px;
            }
            &:nth-child(3) {
              margin-right: 0;
            }
            &:nth-child(4) {
              margin-right: 0;
            }
            &:nth-child(5) {
              margin-right: 0;
            }
            &:nth-child(6) {
              margin-right: 16px;
            }
          }

          .vjs-checked {
            color: white;
            opacity: 0.85;
            background: none;
            border: 2px solid ${colors.white};
          }

          li {
            font-size: 12px;
            line-height: 12px;
            height: 24px;
            opacity: 0.85;
            padding: 0 10px;
            align-items: center;
            ${styles.FontMedium}

            :hover {
              background: none;
              opacity: 1;
            }
          }

          li::before {
            display: none !important;
            content: "";
          }

          @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
            padding: 4px 0;
            width: 185px;

            .vjs-speed {
              font-size: 10px;
              padding: 0 14px;

              &:nth-child(2) {
                //margin-right: -5px;
              }
              &:nth-child(6) {
                margin-right: 12px;
              }
            }
          }
        }

        .vjs-settings-back {
          display: none;
        }
      }
    }

    // REWIND

    .vjs-seek-button {
      order: 3;
      background: silver;
    }

    .skip-back {
      margin-left: 180px;
      width: 24px;
      height: 24px;
      background-size: cover !important;
      background: url(${Images.RewindIcon}) center;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 0.85;

      :hover {
        opacity: 1;
      }

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        margin-left: 160px;
        width: 19px;
        height: 19px;
      }
    }

    .skip-forward {
      margin-left: 19px;
      width: 24px;
      height: 24px;
      background-size: cover !important;
      background: url(${Images.ForwardIcon}) center;
      background-repeat: no-repeat;
      cursor: pointer;
      opacity: 0.85;

      :hover {
        opacity: 1;
      }

      @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        margin-left: 13px;
        width: 19px;
        height: 19px;
      }
    }

    // TIME

    .vjs-remaining-time {
      order: 4;
    }

    .vjs-remaining-time {
      display: none;
    }

    .vjs-current-time {
      display: flex;
      align-items: center;
      font-size: 12px;
      ${styles.FontMedium};
      order: 5;
      opacity: 0.85;
      margin-left: 30px;

      ::after {
        padding: 0 3px;
        content: "/";
      }

      :hover {
        opacity: 1;
      }

      @media screen and (max-width: ${SCREEN_SIZES.sm}) {
        font-size: 9px;
        line-height: 11px;
        margin-left: 15px;
        margin-top: 3px;
      }
    }

    .vjs-duration {
      display: flex;
      align-items: center;
      font-size: 12px;
      ${styles.FontMedium};
      order: 6;
      opacity: 0.85;

      :hover {
        opacity: 1;
      }

      @media screen and (max-width: ${SCREEN_SIZES.sm}) {
        font-size: 9px;
        line-height: 11px;
        margin-top: 3px;
      }
    }

    .quality-span {
      ${styles.FontSemiBold};

      @media screen and (max-width: ${SCREEN_SIZES.sm}) {
        ${styles.FontMedium};
        font-size: 9px;
        line-height: 11px;
        margin-top: 2px;
      }
    }

    // PROGRESS CONTROL

    .vjs-progress-control {
      order: 7;
      position: absolute;
      width: 100%;
      z-index: 2;
      margin: 0;
      cursor: pointer;
      top: -1px;
      height: max-content;
      padding-bottom: 10px;

      .vjs-tip {
        opacity: 1;
        position: absolute;
        bottom: 43px;
        left: -2000px;
        min-height: 19px;
        padding: 0;
        background-color: ${colors.black};
        border-radius: 3px;
        max-width: 182px;
        width: max-content;

        .vjs-tip-inner {
          ${styles.FontMedium};
          font-size: 12px;
          min-height: 19px;
          padding: 2px 6px;
          text-align: center;
          word-wrap: break-word;
        }
      }

      .vjs-thumbnail-holder {
        display: none;
        border: 1px solid ${colors.white};
        border-radius: 2px;
      }

      .vjs-thumb-show {
        display: block;
      }

      .vjs-slider:before {
        display: none;
      }

      .vjs-play-progress {
        height: 2px;
        margin-top: -5px;

        &:before {
          display: none;
        }
      }

      :hover {
        margin-top: -1px;

        .vjs-play-progress:before {
          display: block;
          width: 13px;
          height: 13px;
          top: -6px;
          background: ${colors.primary};
          box-shadow: 0 0 8px ${colors.black};
        }

        .vjs-progress-holder {
          border-top: 2px solid rgba(0, 178, 225, 0.2);
          border-bottom: 2px solid rgba(0, 178, 225, 0.2);
        }

        .vjs-play-progress {
          border-top: 2px solid ${colors.primary};
          border-bottom: 2px solid ${colors.primary};
        }

        .vjs-play-progress {
          ::after {
            display: block;
          }
        }
      }

      .vjs-mouse-display {
        display: none;
      }

      .vjs-progress-holder {
        height: 2px;
        background: rgba(0, 178, 225, 0.2);

        .vjs-marker {
          z-index: 9;
          margin-left: -6px !important;
          transition: 0.1s;
          background: url(${Images.MarkerIcon}) center;

          ${isMobile} && {
            :hover {
              transform: scale(1.5);
              z-index: 9;
              background: url(${Images.MarkerIconHover}) center;
            }
          }
        }

        .active-marker {
          transform: scale(1.5);
          z-index: 9;
          background: url(${Images.MarkerIcon}) center;
        }

        .vjs-marker.marker-highlight {
          z-index: 9;
          margin-left: -6px !important;
          transition: 0.1s;
          background: url(${Images.MarkerIconHighlight}) center;

          ${isMobile} && {
            :hover {
              transform: scale(1.5);
              z-index: 9;
              background: url(${Images.MarkerIconHighlightHover}) center;
            }
          }
        }

        .marker-highlight {
          .active-marker {
            background: url(${Images.MarkerIconHighlightHover}) center !important;
          }
        }
      }

      .vjs-load-progress {
        display: none;
      }

      .vjs-progress-slide {
        margin-top: -47px;
      }

      .vjs-thumb-duration {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 5px;
      }

      .vjs-play-progress {
        background: ${colors.primary};
        top: 5px;

        ::after {
          content: " ";
          display: none;
          position: absolute;
          right: 0;
          border-radius: 5px;
          width: 10px;
          height: 10px;
          background-color: ${colors.primary};
        }
      }
    }

    .vjs-hd-icon {
      display: none !important;
    }

    .autores {
      display: none;
    }
  }

  .vjs-volume-panel {
    order: 8;
    margin-left: auto;
    opacity: 0.85;
    height: 25px;
    transition: 0.6s;

    .vjs-volume-vertical {
      display: none;
    }

    :hover {
      width: 130px;
      button {
        opacity: 1;
        width: 50px;
        position: absolute;
      }

      .vjs-volume-vertical {
        display: block;
        background: none;
        transform: rotate(90deg);
        position: absolute;
        top: 10px;
        left: 10px;
        width: 0;
      }
    }

    .vjs-mute-control {
      opacity: 0.85;
      height: 22px;
      margin-top: 2px;
      background-size: cover;
      background: url(${Images.VolumeIcon}) center;
      background-repeat: no-repeat;

      .vjs-icon-placeholder {
        display: none;
      }

      @media screen and (max-width: ${SCREEN_SIZES.sm}) {
        background: url(${Images.VolumeSmallIcon}) center;
        background-repeat: no-repeat;
      }
    }

    .vjs-vol-0 {
      opacity: 0.85;
      height: 22px;
      background-size: cover;
      margin-top: 2px;
      background: url(${Images.SpeakerIcon}) center;
      background-repeat: no-repeat;

      .vjs-icon-placeholder {
        display: none;
      }

      @media screen and (max-width: ${SCREEN_SIZES.sm}) {
        background: url(${Images.SpeakerSmallIcon}) center;
        background-repeat: no-repeat;
      }
    }

    .vjs-volume-control {
      margin-top: 6px;

      .vjs-volume-level {
        background: ${colors.primary};
        width: 4px;
      }

      .vjs-volume-level:before {
        background: ${colors.white};
        border-radius: 50%;
        content: "";
        height: 10px;
        width: 10px;
        position: absolute;
        right: -3px;
        top: -2px;
      }

      .vjs-slider-vertical {
        background: hsl(0deg 0% 82%);
        width: 4px;
        position: absolute;
        right: 20px;
        bottom: 35px;
      }

      .vjs-mouse-display {
        display: none;
      }
    }

    @media screen and (max-width: ${SCREEN_SIZES.sm}) {
      margin-left: auto;

      .vjs-slider-vertical {
        height: 50px;
      }
    }
  }

  // QUALITY

  .vjs-quality-button {
    display: flex;
    align-items: center;
    position: relative;
    order: 9;
    ${styles.FontMedium};
    font-size: 12px;
    min-width: 50px;
    line-height: 15px;
    z-index: 999;
    opacity: 0.85;
    margin: 0 -10px 0 10px !important;

    .vjs-menu {
      opacity: 1 !important;
      visibility: visible !important;
      transform: scale(1) !important;
      -webkit-transform: scale(1) !important;
    }

    .vjs-control-text {
      opacity: 1;
    }

    :hover {
      opacity: 1;
      background-color: unset;

      .vjs-menu-content {
        display: block;
      }
    }

    .vjs-menu-content {
      display: none;
      position: absolute;
      right: 10px;
      bottom: 100%;
      width: 64px;
      overflow: unset;
      background: rgba(0, 0, 0, 0.5);

      :hover {
        display: block;
      }

      li {
        ${styles.FontMedium};
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        padding: 5px 0 !important;

        @media screen and (max-width: ${SCREEN_SIZES.sm}) {
          font-size: 9px;
        }

        :hover {
          background: ${colors.primary};
          color: ${colors.white};
        }
      }

      .vjs-checked {
        background: ${colors.white};
        color: ${colors.black};
      }

      li:before {
        display: none;
      }
    }

    @media screen and (max-width: ${SCREEN_SIZES.sm}) {
      margin: 0 -10px 0 5px !important;
    }
  }

  // FULL SCREEN

  .vjs-fullscreen-control {
    margin: 0 10px !important;
    order: 10;
    opacity: 0.85;

    .vjs-icon-placeholder {
      top: 10px;
      background-size: cover;
      content: url(${Images.FullScreenIcon});
      background-repeat: no-repeat;
    }

    :hover {
      background-color: unset;
      opacity: 1;
    }

    @media screen and (max-width: ${SCREEN_SIZES.sm}) {
      margin: 6px 0 0 -5px !important;

      .vjs-icon-placeholder {
        top: 10px;
        content: url(${Images.FullScreenSmallIcon});
      }
    }
  }
`;

export const PlayStopContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 35%;
  z-index: 1;

  button {
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 35px;
  }

  .mob-play-btn {
    padding: 22px 20px 22px 26px;
  }

  .mob-pause-btn {
    padding: 22px 24px 22px 24px;
  }
`;

export const PlayButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  button {
    width: 140px;
    height: 140px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    padding: 44px 40px 44px 52px;
    transition: 0.2s;

    img {
      width: 48px;
      height: 52px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    button {
      width: 70px;
      height: 70px;
      padding: 22px 20px 22px 26px;

      img {
        width: 24px;
        height: 26px;
      }
    }
  }
`;

export const BackgroundBLack = styled.div`
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.darkBlack};
  z-index: -1;

  img {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 25px;
    right: 10px;
  }
`;

export const BackgroundBlur = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.black};
  opacity: 0.85;
  z-index: 2;
`;
