import * as styles from "assets/styles/globalStyles.js";
import { isMobile } from "react-device-detect";
import styled from "styled-components/macro";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";

export const PaginationWrapper = styled.div`
  ${styles.FlexRow};
  width: 100%;
  align-items: center;
  justify-content: ${({ topPosition }) =>
    topPosition ? "space-between" : "flex-end"};

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    justify-content: space-between;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    ${styles.FlexColumn};
    margin-top: ${({ topPosition }) => (topPosition ? "unset" : "80px")};
  }
`;
