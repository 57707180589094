import * as styles from "assets/styles/globalStyles.js";
import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";

export const ModalContainer = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.85);
`;

export const Modal = styled.div`
  overflow: auto;
  position: relative;
  ${styles.FlexColumn};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  max-width: 350px;
  max-height: 194px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(18, 18, 18, 0.7);

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    max-width: 300px;
  }
`;

export const StyledModalText = styled.div`
  color: ${colors.lightBlack};
  text-align: center;
  font-size: 16px;
  ${styles.FontBold};
  line-height: 150%;
  text-transform: capitalize;
`;
