import { Stack } from "@mui/material";
import { colors } from "assets/styles/colors";
import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { SCREEN_SIZES } from "utils/constants";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledEllipsesMenuContainer = styled.div`
  position: absolute;
  ${styles.FontRegular};
  font-size: 14px;
  line-height: 150%;
  top: 19px;
  left: 10px;
  width: 460px;
  height: 500px;
  background: ${colors.white};
  box-shadow: 0 2px 4px rgba(18, 18, 18, 0.7);
  border-radius: 5px;
  z-index: 999;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    padding-bottom: 36px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: calc(100% - 20px);
    height: calc(100% - 30px);
    top: 11px;
    left: 10px;
    padding-bottom: 20px;
  }
`;

export const StyledCloseIconContainer = styled.img`
  margin: 25px 0 0 35px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin: 25px 0 0 25px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 20px 0 0 20px;
  }
`;

export const StyledOptionsContainer = styled(Stack)`
  width: max-content;
  margin: 35px 76px 0;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin: 0px 100px 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 0px 84px 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 0px 90px 0;
  }
`;

export const StyledOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 210px;
  align-items: end;
  grid-gap: 30px;

  :nth-child(4) {
    margin-top: 33px;
  }

  :nth-child(5) {
    margin-top: 46px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    align-items: center;

    :nth-child(1) {
      align-items: end;
    }

    :nth-child(2) {
      align-items: end;
      margin-top: 24px;
    }

    :nth-child(3) {
      margin-top: 24px;

      p {
        margin-top: 2px;
      }
    }

    :nth-child(4) {
      margin-top: 20px;
    }

    :nth-child(5) {
      margin-top: 22px;

      p {
        margin-bottom: 3px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    align-items: center;
    grid-template-columns: 1fr 210px;
    grid-gap: 42px;
    font-size: 12px;

    :nth-child(1) {
      margin-top: -11px;

      p {
        margin-bottom: 5px;
      }
    }

    :nth-child(2) {
      align-items: center;
      margin-top: 16px;

      p {
        margin-bottom: 5px;
      }
    }

    :nth-child(3) {
      margin-top: 15px;

      p {
        margin-bottom: 5px;
      }
    }

    :nth-child(4) {
      margin-top: 12px;
    }

    :nth-child(5) {
      align-items: start;
      margin-top: 16px;

      p {
        margin-bottom: 5px;
      }
    }
  }
`;

export const StyledImage = styled.div`
  margin: auto;
`;
