import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import streamSaver from "js/StreamSaver.min";
import { MARKERS_CLASS, USER_SECURE_CODE } from "utils/constants";

dayjs.extend(utc);

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getDuration = (second) => {
  return dayjs.utc(second * 1000).format("H:mm:ss");
};

export const getFilmingDate = (date) => {
  return dayjs(date).format("dddd MMMM D YYYY");
};

export const formatDateFilter = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const getDaysArray = function (start, end) {
  let arr = [];
  for (
    let dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const sortAlphabeticallyByName = (arr) => {
  return arr.sort(function (a, b) {
    let textA = a.name.toUpperCase();
    let textB = b.name.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const capitalizeFirstLetter = (str) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const debounce = (func, delay) => {
  let timeId;
  return (...args) => {
    if (timeId) {
      clearTimeout(timeId);
    }
    timeId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};

export const parameterizeArray = (key, arr) => {
  if (!arr.length) return "";
  arr = arr.map(encodeURIComponent);
  return key + "[]=" + arr.join("&" + key + "[]=");
};

export const allFiltersFormatString = (filters) => {
  return (
    filters.labels +
    "&date_from=" +
    filters.startDate +
    "&date_to=" +
    filters.endDate
  );
};

export const splitTime = (duration) => {
  const time = (duration / 60).toFixed(2);
  const validTime = String(time)?.split(".");
  return [validTime[0], validTime[1]];
};

export const validDate = (date) => {
  const hours = Math.floor(date / 60 / 60);
  const minutes = Math.floor(date / 60) - hours * 60;
  const seconds = Math.floor(date % 60);

  const hoursValidAppearance = hours > 0 && hours + ":";
  const secondsValidAppearance = seconds < 10 ? "0" + seconds : seconds;

  return (
    hoursValidAppearance +
    (hours > 0 && minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    secondsValidAppearance
  );
};

export const validDateTextFormat = (date) => {
  const hours = Math.floor(date / 60 / 60);
  const minutes = Math.floor(date / 60) - hours * 60;
  const seconds = Math.floor(date % 60);

  const hoursValidAppearance = hours > 0 ? "0" + hours : "00";
  const minutesValidAppearance =
    minutes > 0 && minutes < 10
      ? "0" + minutes
      : minutes >= 10
      ? minutes
      : "00";

  const secondsValidAppearance = seconds < 10 ? "0" + seconds : seconds;

  return (
    <>
      <div>{hoursValidAppearance}</div>
      <div>:</div>
      <div>{minutesValidAppearance}</div>
      <div>:</div>
      <div>{secondsValidAppearance}</div>
    </>
  );
};

export const generateRedirectUrlForLocal = (url) => {
  if (process.env.REACT_APP_ENV === "local") {
    const searchParams = url.search("/video/");
    return url.substring(searchParams, url.length);
  }

  return url;
};

export const generateUrlForMakeCip = (data) => {
  return `/video/${data.video_id}?user_id=${data.user_id}&code=${data.security_string}`;
};

export const redirectNullUser = (data) => {
  return `/video/${data.id}?user_id=${data.userId}&code=${USER_SECURE_CODE}`;
};

export const generateVideoSrc = (
  videoSrc,
  src,
  res = null,
  type = "video/mp4"
) => {
  videoSrc.push({
    type: type,
    src: src,
    label: String(res),
    res: res,
  });
};

export const scrollTopFunction = (y = 0, x = 0, behavior = "smooth") => {
  window.scroll({
    top: y,
    left: x,
    behavior,
  });
};

export const downloadBigFile = async (url, name = "ocamsclub") => {
  try {
    const res = await fetch(url);
    const readableStream = res.body;
    const fileStream = streamSaver.createWriteStream(name + ".mp4");

    // Use pipeTo for browsers that support it (more efficient)
    if (window.WritableStream && readableStream.pipeTo) {
      await readableStream.pipeTo(fileStream);
      console.log("Download completed.");
    } else {
      // Fallback for browsers that don't support pipeTo
      const writer = fileStream.getWriter();
      const reader = res.body.getReader();

      const pump = async () => {
        try {
          const { done, value } = await reader.read();
          if (done) {
            writer.close();
            console.log("Download completed.");
            return;
          }
          await writer.write(value);
          pump();
        } catch (error) {
          console.error("Error reading or writing the file:", error);
          writer.abort();
        }
      };

      await pump();
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.log("Service Worker permission denied or not available.");
    } else {
      console.error("Error downloading the file:", error);
    }
  }
};

export const removeActiveMarker = (delay = 4000) => {
  setTimeout(function () {
    const element = document.querySelectorAll(".active-marker");
    if (element.length > 0) {
      element.forEach((item) => item.classList.remove("active-marker"));
    }
  }, delay);
};

export const removeMobileActiveMarker = () => {
  let mobileActiveMarker = document.querySelector(".mobile-active");
  mobileActiveMarker?.classList.remove("mobile-active");
};

export const clearMarkersObject = (markers) => {
  return JSON.parse(JSON.stringify(markers)).map((item) => {
    return { ...item, class: item.is_highlight && MARKERS_CLASS.highlight };
  });
};

export const hideAllTips = () => {
  const tip = document.querySelector(".vjs-tip");
  tip.style.left = "-100%";
};

export function detectBrowser() {
  let browser,
    userAgent = navigator.userAgent;

  if (userAgent.indexOf("Firefox") > -1) {
    browser = "Mozilla Firefox";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browser = "Samsung Internet";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browser = "Opera";
  } else if (userAgent.indexOf("Trident") > -1) {
    browser = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browser = "Microsoft Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browser = "Google Chrome or Chromium";
  } else if (userAgent.indexOf("Safari") > -1) {
    browser = "Safari";
  } else {
    browser = "Unknown";
  }

  return browser;
}

export function detectOS() {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function detectDevice() {
  let isMobile = "Desktop"; //initiate as 'Desktop'

  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = "Mobile";
  }

  return isMobile;
}

export function compareNowAndDateBySecond(date) {
  return (new Date().getTime() - new Date(date).getTime()) / 1000;
}
