/** All Images */
import SeeingOcamsNew from "./svg/seeing-with-ocams-new.svg";
import SeeingOcamsNewMobile from "./svg/seeing-with-ocams-new-mobile.svg";
import Scissors from "./svg/scissors.svg";
import LinkBlack from "./svg/linkBlack.svg";
import HeartIcon from "./svg/heartIcon.svg";
import HeartIconRed from "./svg/heart icon red .svg";
import DownloadIcon from "./svg/downloadIcon.svg";
import DownArrow from "./svg/down-arrow 2.svg";
import Filter from "./svg/filter 1.svg";
import EditLabel from "./svg/edit label icon.svg";
import WhiteHeartIconOutline from "./svg/Heart icon white outline 2.svg";
import RedHeartIconOutline from "./svg/Heart icon white outline filled red 2.svg";
import Camera from "./svg/Camera icon white.svg";
import Vector from "./svg/right arrow pagination.svg";
import Checkmark from "./svg/Checkmark.svg";
import CloseIcon from "./svg/Close icon.svg";
import AddIcon from "./svg/Plus_icon.svg";
import RemoveIcon from "./svg/EDIT_MODAL_REMOVE.svg";
import VideoBurger from "./svg/videoBurger.svg";
import CameraIcon from "./svg/video-camera.svg";
import LeftAndRightArrows from "./svg/LeftAndRightArrows.svg";
import LeftAndRightArrowsMobile from "./svg/LeftAndRightArrowsMobile.svg";
import JAngKIcons from "./svg/JAngKIcons.svg";
import JAngKIconsMobile from "./svg/JAngKIconsMobile.svg";
import MIcon from "./svg/MIcon.svg";
import MIconMobile from "./svg/MIconMobile.svg";
import ZoomIcon from "./svg/ZoomIcon.svg";
import ZoomIconMobile from "./svg/ZoomIconMobile.svg";
import SpaceIcon from "./svg/SpaceIcon.svg";
import SpaceIconMobile from "./svg/SpaceIconMobile.svg";
import AddMarker from "./svg/AddMarker.svg";
import AddMarkerMobile from "./svg/AddMarkerMobile.svg";
import FullScreenIcon from "./svg/FullScreenIcon.svg";
import FullScreenSmallIcon from "./svg/FullScreenSmallIcon.svg";
import PlayIcon from "./svg/PlayIcon.svg";
import PlaySmallIcon from "./svg/PlaySmallIcon.svg";
import StopIcon from "./svg/StopIcon.svg";
import StopSmallIcon from "./svg/StopSmallIcon.svg";
import VolumeIcon from "./svg/VolumeIcon.svg";
import VolumeSmallIcon from "./svg/VolumeSmallIcon.svg";
import RewindIcon from "./svg/RewindIcon.svg";
import ForwardIcon from "./svg/ForwardIcon.svg";
import SpeakerIcon from "./svg/SpeakerIcon.svg";
import SpeakerSmallIcon from "./svg/SpeakerSmallIcon.svg";
import MarkerIcon from "./svg/MarkerIcon.svg";
import MarkerIconHover from "./svg/MarkerIconHover.svg";
import MarkerIconHighlight from "./svg/MarkerIconHighlight.svg";
import MarkerIconHighlightHover from "./svg/MarkerIconHighlightHover.svg";
import RightArrow from "./svg/RightArrow.svg";
import LeftArrow from "./svg/LeftArrow.svg";
import VerticalLine from "./svg/VerticalLine.svg";
import sprite from "./sprite.jpg";
import LeftDoubleLines from "./svg/LeftDoubleLines.svg";
import VectorIcon from "./svg/Vector.svg";
import EditClipIcon from "./svg/EditClipIcon.svg";
import SuccessIcon from "./svg/Success.svg";
import UploadBorder from "./upload-border-pc.png";
import TabletUploadBorder from "./upload-border-pc-2.png";
import WhiteCloseIcon from "./svg/white-close-icon.svg";
import MobilePlay from "./svg/mobilePlay.svg";
import MobilePause from "./svg/mobilePause.svg";
import BlackBackground from "./black.png";
import DeleteIcon from "./svg/Delete icon.svg";
import OcamsLogoAnimation from "./OcamsLogoAnimation.json";
import ClipPlayIcon from "./svg/clipPlayIcon.svg";
import LeftEarMobile from "./svg/left-ear-mobile.svg";
import RightEarMobile from "./svg/right-ear-mobile.svg";
import EditClipIconMobile from "./svg/edit-clip-icon-mobile.svg";
import ScissorsMobile from "./svg/scissors-mobile-2.svg";
import PlayBlueIcon from "./svg/playIconBlue.svg";
import PlayYellowIcon from "./svg/playIconYellow.svg";
import PauseBlueIcon from "./svg/PauseButtonblue.svg";
import PauseYellowIcon from "./svg/PauseYellowblue.svg";
import DeleteWhiteIcon from "./svg/DeleteIconWhite.svg";

export const Images = {
  SuccessIcon,
  SpeakerIcon,
  SpeakerSmallIcon,
  ForwardIcon,
  RewindIcon,
  VolumeIcon,
  VolumeSmallIcon,
  StopIcon,
  StopSmallIcon,
  PlayIcon,
  PlaySmallIcon,
  SpaceIcon,
  SpaceIconMobile,
  LeftAndRightArrows,
  LeftAndRightArrowsMobile,
  JAngKIcons,
  JAngKIconsMobile,
  MIcon,
  MIconMobile,
  ZoomIcon,
  ZoomIconMobile,
  SeeingOcamsNew,
  Scissors,
  LinkBlack,
  HeartIcon,
  HeartIconRed,
  DownloadIcon,
  DownArrow,
  Filter,
  EditLabel,
  WhiteHeartIconOutline,
  RedHeartIconOutline,
  Camera,
  Vector,
  Checkmark,
  CloseIcon,
  AddIcon,
  RemoveIcon,
  SeeingOcamsNewMobile,
  VideoBurger,
  CameraIcon,
  AddMarker,
  AddMarkerMobile,
  FullScreenIcon,
  FullScreenSmallIcon,
  MarkerIcon,
  MarkerIconHover,
  MarkerIconHighlight,
  MarkerIconHighlightHover,
  RightArrow,
  LeftArrow,
  EditClipIcon,
  VerticalLine,
  sprite,
  LeftDoubleLines,
  VectorIcon,
  UploadBorder,
  TabletUploadBorder,
  WhiteCloseIcon,
  MobilePause,
  MobilePlay,
  BlackBackground,
  OcamsLogoAnimation,
  DeleteIcon,
  ClipPlayIcon,
  RightEarMobile,
  LeftEarMobile,
  EditClipIconMobile,
  ScissorsMobile,
  PlayBlueIcon,
  PlayYellowIcon,
  PauseBlueIcon,
  PauseYellowIcon,
  DeleteWhiteIcon,
};
