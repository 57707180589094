import { Images } from "assets/images";
import { BlackLine } from "components";
import { getDuration, getFilmingDate, scrollTopFunction } from "utils/heplers";
import { useDispatch, useSelector } from "react-redux";
import { updateVideoListIsFavorite } from "store";
import { Link } from "react-router-dom";
import { API } from "configs/api";
import { VideoTitle, Labels } from "fragments";
import { Copy } from "../copy";
import { Download } from "../download";
import { Delete } from "../delete";
import { useEffect, useState } from "react";
import { actionBarFontSizeVariants } from "assets/styles/sizes";

import {
  CameraIcon,
  DateInfo,
  Duration,
  HeartIcon,
  MainImg,
  VideoItemLeftBlock,
  VideoItemRightBlock,
  VideoItemWrapper,
  VideoActions,
  CopySection,
  VideoItemDownloadSection,
  VideoDeleteSection,
} from "./styled";

export const VideoListItem = ({
  item,
  setCheckedLabels,
  checkedLabels,
  setDeletedVideoId,
}) => {
  const { user, isFavorite } = useSelector((state) => state.video);

  const [mainVideoId, setMainVideoId] = useState(null);

  const dispatch = useDispatch();

  const favoriteHandler = () => {
    dispatch(
      updateVideoListIsFavorite({
        id: item.video.id,
        is_favorite: !item.video.is_favorite,
      })
    );
  };

  useEffect(() => {
    setMainVideoId(isFavorite.favoriteId == item.video.id);
  }, [isFavorite]);

  return (
    <VideoItemWrapper>
      <div>
        <VideoItemLeftBlock>
          <Link
            onClick={scrollTopFunction}
            to={`${API.video}/${item.video.id}?user_id=${user.id}&code=${user.security_string}`}
          >
            <MainImg src={item.video.thumbnail_link} alt="" />
          </Link>

          <HeartIcon onClick={favoriteHandler}>
            <img
              src={
                (
                  mainVideoId
                    ? mainVideoId && isFavorite.favorite
                    : item.video.is_favorite
                )
                  ? Images.RedHeartIconOutline
                  : Images.WhiteHeartIconOutline
              }
              alt=""
            />
          </HeartIcon>

          {item.video_cameras_count > 1 && (
            <CameraIcon>
              <img src={Images.Camera} alt="" />
              <p>{item.video_cameras_count}</p>
            </CameraIcon>
          )}

          <Duration>{getDuration(item.video.duration_seconds)}</Duration>
        </VideoItemLeftBlock>
      </div>

      <VideoItemRightBlock>
        <DateInfo>{getFilmingDate(item.video.filming_date)}</DateInfo>

        <Link
          onClick={scrollTopFunction}
          to={`${API.video}/${item.video.id}?user_id=${user.id}&code=${user.security_string}`}
        >
          <VideoTitle video={item.video} />
        </Link>

        <Labels
          setCheckedLabels={setCheckedLabels}
          checkedLabels={checkedLabels}
          items={item.labels}
          id={item.video.id}
        />

        <div>
          <BlackLine />
        </div>

        <VideoActions>
          <CopySection className="copy-section">
            <Copy
              video={item.video}
              fs={actionBarFontSizeVariants.underVideo}
            />
          </CopySection>

          <VideoItemDownloadSection>
            <Download
              video={item.video}
              fs={actionBarFontSizeVariants.underVideo}
            />
          </VideoItemDownloadSection>

          {item.video?.parent_id ? (
            <VideoDeleteSection>
              <Delete
                setDeletedVideoId={setDeletedVideoId}
                item={item.video}
                fs={actionBarFontSizeVariants.underVideo}
              />
            </VideoDeleteSection>
          ) : null}
        </VideoActions>
      </VideoItemRightBlock>
    </VideoItemWrapper>
  );
};
