import styled from "styled-components/macro";
import { isMobile } from "react-device-detect";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import { TextField } from "@mui/material";
import { SCREEN_SIZES } from "../../utils/constants";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.is-mobile-markers {
    display: none;
  }
`;

export const StyledMarkersContainer = styled.div`
  display: grid;
  grid-template-columns: 26px 87px minmax(max-content, auto);
  width: fit-content;
  margin-left: -5px;
  padding: 5px 4px 5px 7px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  span {
    opacity: 0.75;
  }

  ${isMobile} && {
    :hover {
      background: ${({ isHighlight }) =>
        isHighlight ? colors.lightYellow : colors.lightPrimary};
      color: ${colors.black};
      opacity: 1;
    }
  }
`;

export const StyledMarkerTitleText = styled.span`
  ${styles.FontMedium};
  word-wrap: break-word;
`;

export const StyledHighlightPlayIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;

  img {
    width: 16px;
    height: 16px;
    transition: 0.3s;
  }

  &:hover {
    img {
      width: 22px;
      height: 22px;
    }
  }
`;

export const StyledMarkerTimeText = styled.span`
  ${styles.FontBold};
  color: ${colors.primary};
  opacity: 1 !important;
  display: flex;
  justify-content: space-between;
  max-width: 67px;
  color: ${({ isHighlight }) => (isHighlight ? colors.yellow : colors.primary)};
`;

export const StyledInput = styled(TextField)`
  width: 100%;

  * {
    border: none !important;
  }

  input {
    padding: unset;
    padding-left: 15px;
    background: ${colors.white};
    border-radius: 5px;
    height: 25px;
    margin-left: 7px;

    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      ${styles.FontRegular}
      padding-left: 2px;
      opacity: 0.6;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.md}) {
      padding-left: 9px;
    }
  }
`;

export const StyledEditMarkerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  img {
    margin-left: 10px;
  }
`;
