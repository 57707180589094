import { FooterWrapper } from "./styled";
import { OCAMS_CLUB_LINK } from "utils/constants";

export const Footer = () => (
  <FooterWrapper>
    <a href={OCAMS_CLUB_LINK} target="blank">
      © {new Date().getFullYear()} SmartShot Technology Inc
    </a>
  </FooterWrapper>
);
