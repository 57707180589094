import axios from "axios";
import { API } from "configs/api";

export const videoListApi = {
  getVideoList: (body) =>
    axios.get(
      `${API.videoList}?user_id=${encodeURIComponent(
        body.userId
      )}&code=${encodeURIComponent(body.code)}&page=${encodeURIComponent(
        body.page
      )}&sort_by=${encodeURIComponent(
        body.sortBy
      )}&active_tab=${encodeURIComponent(body.activeTab)}${
        body.filters ? "&" + body.filters : ""
      }`
    ),
  updateLabels: (body) => axios.put(`${API.videoLabel}/${body.id}`, body),
  updateVideoListFavorite: (body) =>
    axios.patch(`${API.video}/${body.id}`, body),
};
