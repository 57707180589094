import { SpinnerContainerSM, StyledSpinnerSM } from "./styled";
import React from "react";
import { useMobileOrientation } from "react-device-detect";

export const LoadingSM = ({ width = "100px", height = "100px" }) => {
  const { isLandscape } = useMobileOrientation();
  return (
    <SpinnerContainerSM isLandscape={isLandscape}>
      <StyledSpinnerSM viewBox="0 0 50 50" width={width} height={height}>
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </StyledSpinnerSM>
    </SpinnerContainerSM>
  );
};
