import Modal from "@mui/material/Modal";
import { Images } from "assets/images";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { editClip } from "store";
import { makeClipPreview } from "store";
import { resetStatus, closeMakeClip } from "store/video/slice";
import { PENDING, SUCCEEDED } from "utils/constants";
import { StyledProgressCircular } from "components/buttons/loadingButton";
import { useVideoActions } from "hooks";
import { isMobile } from "react-device-detect";
import {
  StyledEditModalContainer,
  StyledEditText,
  StyledOpacityContainer,
  StyledTextContainer,
} from "./styled";

export const EditSuccessModal = ({
  makeClipEndTime,
  makeClipStartTime,
  videoId,
  clipTitle,
  setModalName,
}) => {
  const {
    makeClipVideoUrlAndSizeStatus,
    makeClipVideoUrlAndSize,
    editClipStatus,
  } = useSelector((state) => state.video);

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTransitionModal, setIsTransitionModal] = useState(false);

  const videoActions = useVideoActions();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = searchParams.get("user_id");
  const code = searchParams.get("code");

  useEffect(() => {
    const highlight = {
      end: makeClipEndTime,
      preview: false,
      start: makeClipStartTime,
      video_id: videoId,
    };

    dispatch(makeClipPreview(highlight));
  }, []);

  useEffect(() => {
    if (makeClipVideoUrlAndSizeStatus === PENDING) {
      setIsLoading(true);
    } else if (makeClipVideoUrlAndSizeStatus === SUCCEEDED) {
      const data = {
        saveVideo: {
          ...makeClipVideoUrlAndSize,
          duration: makeClipEndTime - makeClipStartTime,
          start: makeClipStartTime,
          title: clipTitle,
          url: makeClipVideoUrlAndSize.output,
          download_size: makeClipVideoUrlAndSize.size,
          video_id: videoId,
        },
        userParams: {
          id: params.id || "",
          userId,
          code,
        },
      };
      setIsLoading(false);
      dispatch(editClip(data));

      setTimeout(() => {
        setIsTransitionModal(false);
      }, 1200);

      if (isTransitionModal) {
        if (isMobile) {
          document.body.style.overflow = "unset";
          document.body.classList.remove("mobile-played");
        }
        setTimeout(() => {
          setModalName(null);
          dispatch(closeMakeClip());
        }, 2000);
      }
      dispatch(resetStatus("makeClipVideoUrlAndSizeStatus"));
    }
  }, [makeClipVideoUrlAndSizeStatus]);

  useEffect(() => {
    if (editClipStatus === SUCCEEDED) {
      dispatch(resetStatus("editClipStatus"));
      videoActions.getUpdatedVideoList();
    }
  }, [editClipStatus]);

  useEffect(() => {
    setIsTransitionModal(true);
  }, []);

  return (
    <Modal open={open}>
      <StyledOpacityContainer isTransitionModal={isTransitionModal}>
        <StyledEditModalContainer className="edit-clip-container">
          <StyledTextContainer>
            {isLoading ? (
              <>
                <StyledEditText>Saving...</StyledEditText>
                <StyledProgressCircular size={22} />
              </>
            ) : (
              <>
                <StyledEditText>Saved</StyledEditText>
                <img src={Images.SuccessIcon} alt="" />
              </>
            )}
          </StyledTextContainer>
        </StyledEditModalContainer>
      </StyledOpacityContainer>
    </Modal>
  );
};

export default EditSuccessModal;
