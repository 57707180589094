import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";

export const OutlineButtonStyle = styled.button`
  ${styles.FlexRow};
  width: fit-content;
  border: 1px solid ${colors.primary};
  padding: 10px 30px;
  color: ${colors.primary};
  background: ${colors.white};
  text-align: center;
  text-decoration: none;
  line-height: 19px;
  font-size: 16px;
  border-radius: 100px;
  ${styles.FontMedium}
  cursor: pointer;

  img {
    margin-right: 8px;
  }

  &:hover,
  &:active {
    background: ${colors.primary};
    color: ${colors.white};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: ${({ isBIg }) => (isBIg ? "10px 29px" : "11px 20px 12px")};
  }
`;

export const SimpleButtonStyle = styled.button`
  ${styles.FlexRow};
  width: fit-content;
  padding: 11px 31px 10px 32px;
  border: none;
  text-align: center;
  text-decoration: none;
  line-height: 19px;
  cursor: pointer;
  border-radius: ${({ downloadModal }) =>
    downloadModal ? "0.375rem" : "100px"};
  font-size: 16px;
  ${styles.FontMedium}

  &:hover,
  &:active {
    background: ${colors.darkBlack} !important;
  }

  img {
    margin-right: 8px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: ${({ isFilter }) => (isFilter ? "16px" : "14px")};
    line-height: ${({ isFilter }) => (isFilter ? "19px" : "17px")};
    padding: ${({ isFilter }) =>
      isFilter ? "11px 31px 10px 32px" : "11px 20px 12px"};

    img {
      margin-right: 10px;
      width: 15px;
      margin-top: 2px;
    }
  }

  ${({ style }) => {
    return style;
  }}
`;
