import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import styled from "styled-components/macro";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const SelectPaginationSection = styled.div`
  ${styles.FlexRow};
  ${styles.FontMedium}
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  margin-top: -1px;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    margin-top: 1px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: ${({ topPosition }) => (topPosition ? "-20px" : "0")};
    margin-right: 15px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 12px;
      line-height: 15px;
      margin-right: 20px;
      margin-top: ${({ topPosition }) => (topPosition ? "-21px" : "-12px")};
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin: auto;
  }
`;

export const LeftArrow = styled.img`
  transform: rotate(90deg);
  margin-right: 19px;
  opacity: 0.75;
  width: 16px;
  cursor: pointer;

  ${isMobile} && {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Dropdown = styled.div`
  position: relative;
  margin: 0 7px 0 14px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 0 14px;
  }
`;

export const StyledDownItemsContainer = styled.div`
  position: absolute;
  width: 81px;
  max-height: 180px;
  top: -50px;
  left: 0;
  background: ${colors.white};
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 2px;
  box-shadow: 2px 2px 10px grey;
  overflow-y: auto;
  cursor: pointer;
  z-index: 1;

  .current {
    position: relative;

    img {
      position: absolute;
      top: 36%;
      left: 8px;
    }

    ${isMobile} && {
      &:hover {
        img {
          filter: ${colors.svgWhite};
        }
      }
    }
  }
`;

export const StyledDownItems = styled.div`
  width: 95%;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  background: ${colors.white};
  color: ${colors.black};
  ${styles.FontMedium};
  font-size: 14px;
  line-height: 25px;
  transition: 0.3s;
  cursor: pointer;

  ${isMobile} && {
    &:hover {
      background: ${colors.primary};
      border-radius: 5px;
      color: ${colors.white};
    }
  }
`;

export const StyledButtonContainer = styled.button`
  ${styles.FlexRow};
  align-items: center;
  padding: 12px 9px 11px 30px;
  justify-content: space-between;
  background: rgba(18, 18, 18, 0.05);
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;

  img {
    margin-left: 12px;
    width: 9px;
  }

  ${isMobile} && {
    &:hover {
      box-shadow: 2px 2px 10px grey;
      background: rgba(18, 18, 18, 0.03);
    }
  }
`;

export const StyledPaginationNumbers = styled.span`
  opacity: 0.75;
  ${styles.FontMedium}
  -webkit-text-fill-color: ${colors.black};
`;

export const StyledFilterText = styled.p`
  opacity: 0.75;
  margin-top: 4px;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    margin-right: 2px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
    line-height: 15px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 12px 0 12px 19px;
    font-size: 12px;
    line-height: 15px;
  }

  ${isMobile} && {
    :hover {
      opacity: 1;
    }
  }
`;

export const StyledPageNumberContainer = styled.div`
  ${styles.FlexRow};
  opacity: 0.75;
  margin-top: 4px;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
    line-height: 15px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    font-size: 12px;
    line-height: 15px;
  }

  ${isMobile} && {
    :hover {
      opacity: 1;
    }
  }
`;

export const LastPage = styled.p`
  margin-left: 4px;
  width: 10px;
`;

export const RightArrow = styled.img`
  margin-left: 30px;
  opacity: 0.75;
  transform: rotate(270deg);
  width: 16px;
  cursor: pointer;

  ${isMobile} && {
    :hover {
      opacity: 1;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-left: 18px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-left: 16px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-left: 18px;
  }
`;
