import { useEffect, useState } from "react";
import { getVideoList } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../filter";
import { VideoList } from "../videoList";
import {
  MyVideosWrapper,
  StyledClipsText,
  StyledFullVideoText,
  Tabs,
  Title,
} from "./styled";

export const MyVideos = ({ setCheckedLabels, checkedLabels }) => {
  const { user } = useSelector((state) => state.video);
  const [tab, setTab] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [sortBy, setSortBy] = useState(1);

  const dispatch = useDispatch();

  const changeTab = (tabName) => {
    if (tab !== tabName) {
      localStorage.setItem("activeTab", tabName);
      // todo need check
      // localStorage.removeItem("filters");
      setTab(tabName);

      const filtersEncodeURI = localStorage.getItem("filters");

      dispatch(
        getVideoList({
          userId: user.id,
          code: user.security_string,
          activeTab: tabName,
          filters: filtersEncodeURI ?? "",
        })
      );
      setSortBy(1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const activeTab = localStorage.getItem("activeTab");
      if (activeTab) {
        setTab(activeTab);
      }
    }, 0);
  }, []);

  return (
    <MyVideosWrapper>
      <Title>My Videos</Title>

      <Tabs>
        <StyledFullVideoText
          className={tab == "full" ? "checked" : ""}
          onClick={() => changeTab("full")}
        >
          Full Videos
        </StyledFullVideoText>

        <StyledClipsText
          className={tab == "clips" ? "checked" : ""}
          onClick={() => changeTab("clips")}
        >
          Clips
        </StyledClipsText>
        <span />
      </Tabs>

      <Filter
        setCheckedLabels={setCheckedLabels}
        checkedLabels={checkedLabels}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />

      <VideoList
        setCheckedLabels={setCheckedLabels}
        checkedLabels={checkedLabels}
        openFilter={openFilter}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </MyVideosWrapper>
  );
};
