import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";

export const FooterWrapper = styled.div`
  ${styles.FlexCenter};
  height: 90px;
  width: 100%;
  color: ${colors.white};
  background: #000;
  ${styles.FontSemiBold}
  font-size: 12px;
`;
