import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const MyVideosWrapper = styled.div`
  width: 100%;
  margin-top: 53px;
  padding-bottom: 100px;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-top: 53px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 54px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-top: 55px;
  }
`;

export const Title = styled.div`
  color: ${colors.lightBlack};
  ${styles.FontBold};
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 36px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-left: 10px;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 42px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 42px;
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-bottom: 41px;
  }
`;

export const Tabs = styled.div`
  ${styles.FlexRow};
  position: relative;
  width: 100%;

  span {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    border-bottom: 1px solid ${colors.black};
  }

  div {
    ${styles.FontBold};
    margin-right: 44px;
    padding-right: 32px;
    padding-bottom: 2px;
    color: ${colors.lightBlack};
    opacity: 0.5;
    border-bottom: 3px solid transparent;
    transition: 0.2s;
    cursor: pointer;

    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      padding-right: 42px;
    }

    ${!isMobile} && {
      ${MEDIA_LANDSCAPE} {
        padding-right: 42px;
        margin-right: 45px;
      }
    }

    @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
      padding-right: 42px;
      margin-right: 55px;
    }
  }

  .checked {
    width: 120px;
    color: ${colors.primary};
    border-bottom: 3px solid ${colors.primary};
    opacity: 0.9;
    padding-bottom: 6px;

    @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
      padding-bottom: 7px;
    }
  }
`;

export const StyledFullVideoText = styled.div`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;

  ${isMobile} && {
    &:hover {
      opacity: 0.9;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    line-height: 18px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 14px;
    line-height: 17px;
    padding-left: 10px;
    padding-right: 20px;
    margin-left: -1px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 14px;
      line-height: 17px;
      padding-left: 10px;
      padding-right: 20px;
      margin-left: -1px;
    }
  }
`;

export const StyledClipsText = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-left: 8px;

  ${isMobile} && {
    &:hover {
      opacity: 0.9;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 14px;
    line-height: 17px;
    margin-left: 12px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 14px;
      line-height: 17px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-left: unset;
  }
`;
