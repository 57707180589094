const BothConfettiStyles = {
  startVelocity: 70,
  spread: 180,
  ticks: 120,
  zIndex: 0,
  particleCount: 3000,
};

export const LeftConfetti = {
  ...BothConfettiStyles,
  origin: {
    x: -0.1,
    y: 0.6,
  },
};

export const RightConfetti = {
  ...BothConfettiStyles,
  origin: {
    x: 1.1,
    y: 0.6,
  },
};
