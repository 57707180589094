import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Images } from "assets/images";
import { Copy } from "fragments";
import { useDispatch, useSelector } from "react-redux";
import { Download } from "fragments/download";
import { closeMakeClip } from "store";
import { actionBarFontSizeVariants } from "assets/styles/sizes";
import { saveClipToggle } from "store/video/slice";
import { isMobile } from "react-device-detect";
import {
  DownloadCopyBlock,
  ReadyCopyDownload,
  SimpleLineThrough,
  SuccessBarContainer,
  EqualBlock,
  StyledModalTitle,
  StyledOpacityContainer,
} from "./styled";

export const SuccessBarModal = ({ setModalName }) => {
  const { makeClipVideoUrlAndSize } = useSelector((state) => state.video);

  const [isTransitionModal, setIsTransitionModal] = useState(false);

  const dispatch = useDispatch();

  const video = {
    download_link: makeClipVideoUrlAndSize.output,
    download_size: makeClipVideoUrlAndSize.size,
  };

  const closeModal = () => {
    setIsTransitionModal(false);
    if (isMobile) {
      document.body.style.overflow = "unset";
    }

    setTimeout(() => {
      setModalName(null);
      dispatch(closeMakeClip());
      dispatch(saveClipToggle());
    }, 1200);
  };

  useEffect(() => {
    setIsTransitionModal(true);
    dispatch(saveClipToggle());
    if (isMobile) {
      document.body.classList.remove("mobile-played");
    }
  }, []);

  return (
    <Modal open={open}>
      <StyledOpacityContainer isTransitionModal={isTransitionModal}>
        <SuccessBarContainer className="success-bar-container">
          <StyledModalTitle isClipReady className="success-bar-title">
            Your clip is ready!
          </StyledModalTitle>

          <ReadyCopyDownload className="close-icon-container">
            <img
              src={Images.CloseIcon}
              onClick={closeModal}
              alt="sorry the image isn't loaded yet"
            />
          </ReadyCopyDownload>

          <DownloadCopyBlock className="download-copy-block">
            <EqualBlock className="copy-block">
              <Copy
                url={video.download_link}
                isSaveModal
                fs={actionBarFontSizeVariants.onModal}
              />
            </EqualBlock>

            <SimpleLineThrough />

            <EqualBlock className="download-block">
              <Download
                video={video}
                isSaveModal
                fs={actionBarFontSizeVariants.onModal}
              />
            </EqualBlock>
          </DownloadCopyBlock>
        </SuccessBarContainer>
      </StyledOpacityContainer>
    </Modal>
  );
};
