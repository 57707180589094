import styled from "styled-components/macro";
import Slider from "rc-slider";
import { colors } from "assets/styles/colors";
import { Images } from "assets/images";
import * as styles from "assets/styles/globalStyles";
import { unsetSideColors } from "assets/styles/globalStyles";
import "rc-slider/assets/index.css";
import { isMobile } from "react-device-detect";

export const SliderContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const StyledSlider = styled(Slider)`
  &.rc-slider {
    position: absolute;
    bottom: 7px;
    height: 72px;
  }

  .rc-slider-rail {
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: unset;
    content: ${({ editClip }) =>
      editClip ? "unset" : `url(${Images.VerticalLine})`};
  }

  .rc-slider-track {
    cursor: ew-resize;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: unset;
    border-top: 3px solid ${colors.yellow};
    border-bottom: 3px solid ${colors.yellow};
  }

  .rc-slider-handle {
    top: 10px;
    height: 100%;
    background-color: ${colors.yellow};
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    ${unsetSideColors};
    &:focus,
    &:active {
      ${unsetSideColors}
    }

    div {
      background: ${colors.black};
      margin-top: -23px;
      color: ${colors.white};
      border-radius: 2px;
      ${styles.FontRegular}
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      padding: 1px 6px;
      width: max-content;
      height: 19px;
    }

    &.rc-slider-handle-1 {
      cursor: w-resize;
      margin-left: 9px;
      width: 18px;
      background-image: ${({ editClip }) =>
        editClip ? `url(${Images.EditClipIcon})` : `url(${Images.LeftArrow})`};

      div {
        margin-left: ${({ leftBracket }) => (leftBracket ? "20px" : "-20px")};
      }
    }

    &.rc-slider-handle-2 {
      cursor: e-resize;
      margin-left: -9px;
      width: 18px;
      background-image: ${({ editClip }) =>
        editClip ? `url(${Images.EditClipIcon})` : `url(${Images.RightArrow})`};

      div {
        margin-left: ${({ rightBracket }) => (rightBracket ? "-40px" : "-5px")};
      }
    }

    ${isMobile} && {
      :hover {
        box-shadow: unset;
      }
    }
  }

  &.mobile-slider {
    .rc-slider-handle {
      &.rc-slider-handle-1 {
        background-image: ${({ editClip }) =>
          editClip
            ? `url(${Images.EditClipIconMobile})`
            : `url(${Images.LeftEarMobile})`};

      }
      &.rc-slider-handle-2 {
        background-image: ${({ editClip }) =>
          editClip
            ? `url(${Images.EditClipIconMobile})`
            : `url(${Images.RightEarMobile})`};
      }
  }

`;

export const StyledMessageContainerOpacity = styled.div`
  transition: 0.5s;
  opacity: ${({ maxAndMinDistance }) => (maxAndMinDistance ? 1 : 0)};
`;

export const StyledMessageContainer = styled.div`
  opacity: ${({ maxAndMinDistance }) => (maxAndMinDistance ? 1 : 0)};
  color: red;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 9px;
  background: ${colors.black};
  color: ${colors.white};
  font-size: 14px;
  text-align: center;
  max-width: 228px;
  margin: auto;
  ${styles.FontRegular};
  border-radius: 3px;
  transition: 0.5s;
`;
