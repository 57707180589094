import { useDispatch, useSelector } from "react-redux";
import { Images } from "assets/images";
import { getVideoList } from "store";
import { useState, useId, useRef } from "react";
import { useOnClickOutside } from "hooks";
import { useMediaQuery } from "@mui/material";
import { PaginationSection, PaginationInfo } from "./fragments";
import { PaginationWrapper } from "./styled";
import { setIsPageChanged } from "store/video/slice";
import { SCREEN_SIZES } from "utils/constants";
import { StyledDownItems } from "./fragments/paginationSection/styled";

export const Pagination = ({ topPosition, sortBy, setSortBy }) => {
  const { paginationLinks, paginationData } = useSelector(
    (state) => state.videoList
  );

  const { user } = useSelector((state) => state.video);

  const dispatch = useDispatch();

  const [openPagination, setOpenPagination] = useState(false);
  const [openDateFilter, setOpenDateFilter] = useState(false);

  const paginationRef = useRef();
  const newestOldestRef = useRef();
  const id = useId();
  const isMobileSx = useMediaQuery(`(max-width:${SCREEN_SIZES.xs})`);

  useOnClickOutside(paginationRef, () => setOpenPagination(false));
  useOnClickOutside(newestOldestRef, () => setOpenDateFilter(false));

  const changePage = (page = 1, sortByDate = 1) => {
    const queryParams = {
      userId: user.id,
      code: user.security_string,
      page,
      sortBy: sortByDate,
      activeTab: localStorage.getItem("activeTab"),
    };

    let filtersEncodeURI = localStorage.getItem("filters");

    dispatch(setIsPageChanged(page));
    if (filtersEncodeURI) {
      queryParams.filters = filtersEncodeURI;
    }

    dispatch(getVideoList(queryParams));
  };

  const selectPage = (page) => {
    changePage(page, sortBy);
    setOpenPagination(false);
  };

  const prevOrNextPage = (type) => {
    let newPage;
    if (type === "prev") {
      newPage = paginationData.current_page - 1;
    } else {
      newPage = paginationData.current_page + 1;
    }
    changePage(newPage, sortBy);
    setOpenPagination(false);
  };

  const dropDownItems = () => {
    return [...Array(paginationData.last_page)].map((e, i) => {
      return i + 1 === paginationData.current_page ? (
        <StyledDownItems className="current" key={`${id}-${i}`}>
          <img src={Images.Checkmark} alt="" />
          {i + 1}
        </StyledDownItems>
      ) : (
        <StyledDownItems key={`${id}-${i}`} onClick={() => selectPage(i + 1)}>
          {i + 1}
        </StyledDownItems>
      );
    });
  };

  const changeDateFilter = (filterDate) => {
    setSortBy(filterDate);
    changePage(paginationData.current_page, filterDate);
  };

  return (
    <>
      {paginationData.total > 10 && (
        <PaginationWrapper topPosition={topPosition}>
          {!isMobileSx && (
            <PaginationInfo
              paginationData={paginationData}
              topPosition={topPosition}
              setOpenDateFilter={setOpenDateFilter}
              changeDateFilter={changeDateFilter}
              openDateFilter={openDateFilter}
              newestOldestRef={newestOldestRef}
              sortBy={sortBy}
            />
          )}

          {isMobileSx && !topPosition && (
            <PaginationInfo
              paginationData={paginationData}
              topPosition={topPosition}
              setOpenDateFilter={setOpenDateFilter}
              changeDateFilter={changeDateFilter}
              openDateFilter={openDateFilter}
              newestOldestRef={newestOldestRef}
              sortBy={sortBy}
            />
          )}

          <PaginationSection
            topPosition={topPosition}
            dropDownItems={dropDownItems}
            paginationData={paginationData}
            prevOrNextPage={prevOrNextPage}
            setOpenPagination={setOpenPagination}
            openPagination={openPagination}
            paginationLinks={paginationLinks}
            paginationRef={paginationRef}
          />

          {isMobileSx && topPosition && (
            <PaginationInfo
              paginationData={paginationData}
              topPosition={topPosition}
              setOpenDateFilter={setOpenDateFilter}
              changeDateFilter={changeDateFilter}
              openDateFilter={openDateFilter}
              newestOldestRef={newestOldestRef}
              sortBy={sortBy}
            />
          )}
        </PaginationWrapper>
      )}
    </>
  );
};
