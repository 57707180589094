import { useEffect, useRef } from "react";
import { useOnClickOutside } from "hooks";
import { OutlineButton, SimpleButton } from "../buttons";
import { colors } from "assets/styles/colors";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import {
  Modal,
  ModalContainer,
  ButtonsContainer,
  QuestionContainer,
} from "./styled";

export const ConfirmDeleteModal = ({ show, close, event, text }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, close);

  useEffect(() => {
    if (show) {
      disableBodyScroll(ref.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [show]);

  return (
    <ModalContainer show={show} className="confirm-modal-container">
      <Modal ref={ref}>
        <QuestionContainer>{text}</QuestionContainer>
        <ButtonsContainer>
          <OutlineButton text="Cancel" event={close} isBIg={true} />
          <SimpleButton
            isFilter={true}
            text="Delete"
            event={event}
            style={{
              background: colors.red,
              color: colors.white,
              "&:hover, &:active": {
                background: `${colors.bordeaux} !important`,
              },
            }}
          />
        </ButtonsContainer>
      </Modal>
    </ModalContainer>
  );
};
