import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAnalytic, getAnalyticId } from "store";
import { detectBrowser, detectDevice, detectOS } from "../utils/heplers";

const ANAYTICS_TYPES = {
  play: "update",
  stop: "stop",
  update: "update",
};

export const useAnalytics = () => {
  const { video, analyticId } = useSelector((state) => state.video);
  const dispatch = useDispatch();

  return {
    create: function () {
      const deviceData = {
        browser: detectBrowser(),
        os: detectOS(),
        device: detectDevice(),
        video_id: video.id,
      };

      dispatch(getAnalyticId(deviceData));
    },

    play: function () {
      dispatch(updateAnalytic({ type: ANAYTICS_TYPES.play, id: analyticId }));
    },

    pause: function (data) {
      const defaultData = {
        id: analyticId,
        type: ANAYTICS_TYPES.stop,
      };

      let watched = { ...defaultData, ...data };

      dispatch(updateAnalytic(watched));
    },

    update: function (field) {
      if (!analyticId) return;

      dispatch(
        updateAnalytic({
          type: ANAYTICS_TYPES.update,
          field: field,
          id: analyticId,
        })
      );
    },
  };
};
