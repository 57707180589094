import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const VideoItemWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(320px, 30%) auto;
  background: ${colors.white};
  margin-bottom: 40px;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    grid-template-columns: minmax(233px, 35%) auto;
    margin-bottom: 59px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    grid-template-columns: minmax(167px, 35%) auto;
    margin-bottom: 51px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      grid-template-columns: minmax(189px, 35%) auto;
      margin-bottom: 51px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    grid-template-columns: 1fr;
    margin-bottom: 62px;
  }
`;

export const VideoItemLeftBlock = styled.div`
  position: relative;
  object-fit: fill;
  cursor: pointer;
  min-height: 180px;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    min-height: 131px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    min-height: 94px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      min-height: 106px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    min-height: auto;
  }
`;

export const MainImg = styled.img`
  width: 100%;
  height: 100%;
  transition: 0.2s;
  border: 1px solid rgba(18, 18, 18, 0.2);

  ${isMobile} && {
    &:hover {
      box-shadow: 1px 1px 3px ${colors.black};
    }
  }
`;

export const HeartIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 9px;
  padding: 7px 6px 1px 7px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    right: 9px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    right: 11px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      right: 9px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    right: 9px;
  }

  img {
    margin-left: -1px;
    width: 16px;
  }
`;

export const CameraIcon = styled.div`
  ${styles.FlexRow};
  ${styles.FontMedium};
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 4px 7px;
  color: ${colors.white};
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.8);

  img {
    width: 11px;
    margin-right: 5px;
  }

  p {
    font-size: 10px;
    line-height: 12px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding: 4px 6px;
    }
  }
`;

export const Duration = styled.div`
  position: absolute;
  bottom: 8px;
  right: 9px;
  padding: 4px 6px;
  background: rgba(0, 0, 0, 0.8);
  color: ${colors.white};
  ${styles.FontMedium};
  font-size: 10px;
  line-height: 12px;
  border-radius: 3px;
  text-align: center;

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      bottom: 9px;
      right: 9px;
      padding: 4px 7px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    bottom: 10px;
    right: 9px;
    padding: 4px 4px;
  }
`;

export const VideoItemRightBlock = styled.div`
  ${styles.FlexColumn};
  margin-left: 40px;
  max-height: 180px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-left: 20px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-left: 0;
    max-height: unset;
    padding: 18px 10px 0 10px;
  }
`;

export const DateInfo = styled.p`
  ${styles.FontMedium};
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black};
  margin-bottom: 10px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
    line-height: 15px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 11px;
    }
  }
`;

export const Title = styled.h3`
  ${styles.FontBold};
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 16px;
    line-height: 19px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const VideoActions = styled.div`
  ${styles.FlexRow};
  margin-top: 12px;

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    align-items: center;
    margin-top: 11px;
  }
`;

export const CopySection = styled.div`
  margin-right: 6px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-right: 7px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-right: 7px;
      width: 65px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-right: 15px;
    width: 65px;
  }
`;

export const VideoItemDownloadSection = styled.div`
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 1px;
  }
  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-right: 7px;
    }
  }
`;

export const VideoDeleteSection = styled.div``;
