import { validDate } from "utils/heplers";
import videojs from "video.js";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClipDistance } from "store";
import {
  DURATION,
  DURATION_MOBILE_LANDSCAPE,
  IS_MAX_DISTANCE,
  IS_MIN_DISTANCE,
} from "utils/constants";
import { isMobile, useMobileOrientation } from "react-device-detect";
import {
  SliderContainer,
  StyledMessageContainer,
  StyledSlider,
} from "./styled";

const MakeClipSlider = ({
  setIsPlayButton,
  setMakeClipStartTime,
  setMakeClipEndTime,
  editClip,
  videoTime = 0,
}) => {
  const { makeClipDistance, saveClip } = useSelector((state) => state.video);

  const dispatch = useDispatch();

  const player = videojs("video");
  const { isPortrait } = useMobileOrientation();

  const durationData =
    isPortrait && isMobile ? DURATION_MOBILE_LANDSCAPE : DURATION;

  const [maxDistance, setMaxDistance] = useState(
    durationData.highlightFullDuration
  );
  const [highlightStartTime, setHighlightStartTime] = useState(0);
  const [highlightEndTime, setHighlightEndTime] = useState(0);
  const [activeThumb, setActiveThumb] = useState(0);
  const [maxAndMinDistance, setMaxAndMinDistance] = useState("");
  const [distance, setDistance] = useState([
    highlightStartTime,
    highlightEndTime,
  ]);

  //todo check for editeClip

  const videoDuration = player.duration();
  const minVideoLength = videoTime - durationData.permittedDistance;
  const videoMilliSecond =
    videoTime && Number("0." + String(videoTime).split(".")[1]);

  const leftBracket = distance[0] < 5;
  const rightBracket = distance[1] > maxDistance - 5;

  const bracketsHandler = () => {
    setIsPlayButton(true);
    dispatch(setClipDistance(distance));
  };

  const startTimeValidFormat = validDate(highlightStartTime);
  const endTimeValidFormat = validDate(highlightEndTime);

  const sliderContent = document.querySelectorAll(".rc-slider-handle");

  const sliderLeft = sliderContent[0];
  const sliderRight = sliderContent[1];

  if (sliderLeft && sliderRight) {
    sliderLeft.innerHTML = `<div>${startTimeValidFormat}</div>`;
    sliderRight.innerHTML = `<div>${endTimeValidFormat}</div>`;
  }

  const changeClip = (newValue) => {
    setIsPlayButton(false);

    if (distance[0] !== newValue[0]) {
      setActiveThumb(0);
    } else if (distance[1] !== newValue[1]) {
      setActiveThumb(1);
    }

    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < durationData.minDuration) {
      setMaxAndMinDistance(IS_MIN_DISTANCE);

      if (activeThumb === 0) {
        player.currentTime(highlightStartTime);
        const clamped = [
          Math.min(newValue[0], newValue[1] - durationData.minDuration),
          newValue[1],
        ];
        setDistance(clamped);
      } else {
        player.currentTime(highlightEndTime);
        const clamped = [
          newValue[0],
          Math.max(newValue[1], newValue[0] + durationData.minDuration),
        ];
        setDistance(clamped);
      }
    } else if (newValue[1] - newValue[0] > durationData.permittedDistance) {
      setMaxAndMinDistance(IS_MAX_DISTANCE);

      if (activeThumb === 0) {
        player.currentTime(highlightStartTime);
        setDistance([
          newValue[1] - durationData.permittedDistance,
          newValue[1],
        ]);
      } else {
        player.currentTime(highlightEndTime);
        setDistance([
          newValue[0],
          newValue[0] + durationData.permittedDistance,
        ]);
      }
    } else {
      if (activeThumb === 0) {
        player.currentTime(highlightStartTime);
        setDistance(newValue);
      } else {
        player.currentTime(highlightEndTime);
        setDistance(newValue);
      }
    }
  };

  const videoYellowBracketDuration = (durationData) => {
    if (makeClipDistance.length) {
      setDistance(makeClipDistance);
      if (
        videoDuration <= durationData.highlightFullDuration &&
        videoDuration >= durationData.minDuration
      ) {
        setMaxDistance(videoDuration);
      } else if (videoDuration <= durationData.highlightFullDuration) {
        setMaxDistance(videoDuration);
      }
    } else {
      if (editClip) {
        if (videoDuration > durationData.highlightFullDuration) {
          setDistance([0, durationData.highlightFullDuration]);
        } else {
          setDistance([0, videoDuration]);
        }
        setMaxDistance(videoDuration);
      } else if (
        videoDuration <= durationData.highlightFullDuration &&
        videoDuration >= durationData.minDuration
      ) {
        setDistance([videoDuration * 0.375, videoDuration * 0.625]);
        setMaxDistance(videoDuration);
      } else if (videoDuration <= durationData.highlightFullDuration) {
        setDistance([videoDuration * 0.375, videoDuration * 0.625]);
        setMaxDistance(videoDuration);
      } else if (videoDuration > durationData.highlightFullDuration) {
        setDistance([durationData.startingPosition, durationData.endPosition]);
      }
    }

    setIsPlayButton(true);
    player.pause();
  };

  const videoHighlightClip = (durationData) => {
    if (minVideoLength < 0) {
      setHighlightStartTime(distance[0] + videoMilliSecond);
      setHighlightEndTime(distance[1] + videoMilliSecond);
    } else if (minVideoLength > 0) {
      setHighlightStartTime(
        videoTime - durationData.midDuration + distance[0] + videoMilliSecond
      );
      setHighlightEndTime(
        videoTime - durationData.midDuration + distance[1] + videoMilliSecond
      );
    }
  };

  useEffect(() => {
    videoHighlightClip(durationData);
  }, [distance, videoTime, isPortrait]);

  useLayoutEffect(() => {
    if (videoDuration) {
      setMaxDistance(durationData.highlightFullDuration);
      videoYellowBracketDuration(durationData);
    }
  }, [videoDuration, isPortrait]);

  useEffect(() => {
    setMakeClipStartTime(highlightStartTime);
    setMakeClipEndTime(highlightEndTime);
  }, [highlightStartTime, highlightEndTime]);

  useEffect(() => {
    if (maxAndMinDistance.length > 0) {
      setTimeout(() => {
        setMaxAndMinDistance(false);
      }, 800);
    }
  }, [maxAndMinDistance]);

  return (
    <SliderContainer onClick={bracketsHandler} onTouchEnd={bracketsHandler}>
      {!saveClip ? (
        <>
          <StyledMessageContainer
            maxAndMinDistance={maxAndMinDistance === IS_MIN_DISTANCE}
          >
            Minimum clip length is 10 seconds
          </StyledMessageContainer>

          <StyledMessageContainer
            maxAndMinDistance={maxAndMinDistance === IS_MAX_DISTANCE}
          >
            {isMobile && isPortrait
              ? "Maximum clip length is 2 minutes"
              : "Maximum clip length is 3 minutes"}
          </StyledMessageContainer>

          <StyledSlider
            className={isMobile ? "mobile-slider" : ""}
            leftBracket={leftBracket}
            rightBracket={rightBracket}
            editClip={editClip}
            allowCross={false}
            range
            value={distance}
            draggableTrack
            onChange={changeClip}
            max={maxDistance}
          />
        </>
      ) : null}
    </SliderContainer>
  );
};

export default MakeClipSlider;
