import { useEffect, useRef } from "react";
import { Images } from "assets/images";
import Lottie from "react-lottie-player";
import { Footer } from "../footer";
import {
  Container,
  Header,
  NotFoundContent,
  NotFoundContentWrapper,
  NotFoundText,
  NotFoundTitle,
  NotFoundWrapper,
} from "./styled";

export const NotFound = () => {
  const lottieRef = useRef(null);

  function handleMouseEnter() {
    lottieRef.current.setDirection(-1);
    lottieRef.current.play();
  }

  function handleMouseLeave() {
    lottieRef.current.setDirection(1);
    lottieRef.current.play();
  }

  useEffect(() => {
    document.title = "404";
    lottieRef.current.play();
  }, []);

  return (
    <NotFoundWrapper>
      <Container>
        <Header>
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <a href="https://www.ocamsclub.com/">
              <Lottie
                animationData={Images.OcamsLogoAnimation}
                className="lottie"
                ref={lottieRef}
                renderer="svg"
                loop={false}
              />
            </a>
          </div>
        </Header>
        <NotFoundContent>
          <NotFoundContentWrapper>
            <NotFoundTitle>
              Oops, looks like you got lost <span>:(</span>
            </NotFoundTitle>
            <NotFoundText>
              You ended up here because somehow you entered the wrong address.
            </NotFoundText>
            <NotFoundText>
              To go to your personal video library, please find the private link
              in your notification email.
            </NotFoundText>
            <NotFoundText>
              Can`t find it, or link not working? Email us at
              <a href="mailto:tennis@ocamsclub.com"> tennis@ocamsclub.com</a>.
              We`ll be glad to help.
            </NotFoundText>
          </NotFoundContentWrapper>
        </NotFoundContent>
      </Container>
      <Footer />
    </NotFoundWrapper>
  );
};
