import { isMobile } from "react-device-detect";
import styled from "styled-components/macro";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";

export const VideoListWrapper = styled.div`
  width: 100%;
  margin-top: ${({ openFilter }) => (openFilter ? "26px" : "72px")};

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-top: ${({ openFilter }) => (openFilter ? "58px" : "72px")};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: ${({ openFilter }) => (openFilter ? "63px" : "72px")};
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-top: ${({ openFilter }) => (openFilter ? "63px" : "72px")};
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-top: ${({ openFilter }) => (openFilter ? "50px" : "72px")};
  }
`;

export const StyledPaginationContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    margin-bottom: 11px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-bottom: 28px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-bottom: 39px;
  }

  ${MEDIA_LANDSCAPE} {
    margin-bottom: 38px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-bottom: 10px;
  }
`;

export const StyledVideoListContainer = styled.div`
  > div:last-child {
    margin-bottom: 60px;
  }
`;
