import React, { useEffect, useState } from "react";
import { Message, MessageContainer, ToastMessageContainer } from "./styled";
import { resetStatus } from "store/video/slice";
import { useDispatch, useSelector } from "react-redux";
import { SUCCEEDED } from "utils/constants";
import { debounce } from "utils/heplers";
import { useVideoActions } from "hooks";
import { isMobile } from "react-device-detect";

export const ToastMessage = ({ serverError }) => {
  const { addMarkerStatus, editMarkerStatus, deleteMarkerStatus } = useSelector(
    (state) => state.video
  );

  const [viewToastMessage, setViewToastMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [mobileClassName, setMobileClassName] = useState("");

  const videoActions = useVideoActions();

  const dispatch = useDispatch();

  const hideMessage = debounce(() => {
    setViewToastMessage(false);
  }, 4000);

  useEffect(() => {
    if (serverError) {
      setViewToastMessage(true);
      setMessage("Server error. Please reload the page and try again.");
      return;
    }

    if (editMarkerStatus === SUCCEEDED) {
      setViewToastMessage(true);
      setMessage("Marker edited.");
      dispatch(resetStatus("editMarkerStatus"));
      if (isMobile) {
        setMobileClassName("mobile-marker-message");
      }
      return;
    }

    if (addMarkerStatus === SUCCEEDED) {
      setViewToastMessage(true);
      isMobile
        ? setMessage("Marker added.")
        : setMessage(
            "New marker added. Double click on the marker icon to edit. Remember,you can also press the `M` key to quickly add a marker."
          );
      if (isMobile) {
        setMobileClassName("mobile-marker-message");
      }
      videoActions.getData();
      dispatch(resetStatus("addMarkerStatus"));
      return;
    }

    if (deleteMarkerStatus === SUCCEEDED) {
      setViewToastMessage(true);
      setMessage("Marker deleted.");
      if (isMobile) {
        setMobileClassName("mobile-marker-message");
      }
      videoActions.getData();
      dispatch(resetStatus("deleteMarkerStatus"));
      return;
    }

    hideMessage();
  }, [editMarkerStatus, addMarkerStatus, deleteMarkerStatus, serverError]);

  return (
    <>
      {viewToastMessage ? (
        <ToastMessageContainer className={mobileClassName}>
          <MessageContainer className="message-container">
            <Message>{message}</Message>
          </MessageContainer>
        </ToastMessageContainer>
      ) : null}
    </>
  );
};
