import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import { Modal } from "@mui/material";

export const ModalContainer = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
`;

export const StyledModalContainer = styled(Modal)`
  * {
    outline: none;
  }

  .MuiBackdrop-root {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8588235294117647);
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
`;

export const Cube = styled.div`
  max-width: 476px;
  background: ${colors.white};
  padding: 44px 52px 33px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -4px 4px 6px 3px rgb(0 0 0 / 15%);
  border-radius: 3px;
`;

export const TextContainer = styled.p`
  ${styles.FontRegular};
  font-size: 14px;
  line-height: 26px;
  color: ${colors.lightBlack};
  max-width: 372px;
  width: 100%;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  ${styles.FlexRow};
  justify-content: flex-end;
  margin-top: 27px;

  button {
    margin-left: 20px;
  }
`;
