import axios from "axios";
import { ROUTES } from "configs/routes";

export const initAxiosInterceptors = () => {
  axios.interceptors.request.use((config) => {
    config.url = `${process.env.REACT_APP_API_URL}${config.url}`;
    // if (config.token) {
    //   const token = localStorage.getItem('access_token');
    //   if (!token) {
    //     // window.location.replace('/')
    //     throw new Error('token not found');
    //   }
    //   config.headers = {
    //     ...config.headers,
    //     authorization: `Bearer ${token}`,
    //   };
    // }
    // if (config.token) {
    //     const token = localStorage.getItem('access_token');
    //     config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // window.location.replace('/');
      }
      if (error.response.status === 403) {
        // window.location.replace('/')
      }
      if (error.response.status === 404) {
        window.location.replace(ROUTES.notFound);
      }
      throw new Object({
        data: error.response.data,
        status: error.response.status,
      });
    }
  );
};
