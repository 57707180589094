export const OCAMS_CLUB_LINK = "http://www.ocamsclub.com/";
export const CLUB_NAME = "ocamsclub";

export const PAGE_TITLE = "Ocams";

export const BROWSER_SAFARI = "Safari";

export const USER_SECURE_CODE = "00000000";

export const gifsPreview = [
  "https://media1.tenor.com/images/aa574640b0f3e2c22a4798233212e35d/tenor.gif?itemid=13052487",
  "https://s3.us-east-2.amazonaws.com/closer.ocamsclub.com/gifs/16172985471465_tenor%20%2820%29.gif",
  "https://s3.us-east-2.amazonaws.com/closer.ocamsclub.com/gifs/16172985473403_tenor%20%2823%29.gif",
];

//keyboard
export const SPACE = "Space";
export const KeyM = "KeyM";
export const KeyJ = "KeyJ";
export const KeyK = "KeyK";
export const ArrowRight = "ArrowRight";
export const ArrowLeft = "ArrowLeft";
export const Enter = "Enter";

// old logic
// export const DURATION = {
//   minDuration: 10,
//   startingPosition: 150,
//   midDuration: 180,
//   permittedDistance: 300,
//   endPosition: 210,
//   highlightFullDuration: 360,
// };

export const DURATION = {
  minDuration: 10,
  startingPosition: 60,
  midDuration: 90,
  permittedDistance: 180,
  endPosition: 120,
  highlightFullDuration: 180,
};

export const DURATION_MOBILE_LANDSCAPE = {
  minDuration: 10,
  startingPosition: 40,
  midDuration: 60,
  permittedDistance: 120,
  endPosition: 80,
  highlightFullDuration: 120,
};

export const PENDING = "pending";
export const SUCCEEDED = "succeeded";
export const FAILED = "failed";
export const REJECTED = "rejected";
export const CONFIRM_MODAL = "ConfirmModal";
export const SUCCESS_MODAL = "SuccessModal";
export const EDIT_MODAL = "EditModal";

export const IS_MAX_DISTANCE = "IS_MAX_DISTANCE";
export const IS_MIN_DISTANCE = "IS_MIN_DISTANCE";

export const ExpectedFileTypes = [
  "video/mp4",
  "video/ogx",
  "video/oga",
  "audio/ogg",
  "video/ogv",
  "video/ogg",
  "video/webm",
  "video/x-matroska",
  "video/quicktime",
];

export const WindowInnerSize = 500;

export const MARKERS_CLASS = {
  active: "active-marker",
  highlight: "marker-highlight",
  mobileActive: "mobile-active",
};

export const SCREEN_SIZES = {
  xs: "499px",
  landscape: "926px",
  landscapeMinDistance: "568px",
  sm: "699px",
  md: "999px",
  lg: "1018px",
};

export const MEDIA_LANDSCAPE = `@media only screen and (max-width: ${SCREEN_SIZES.landscape}) and (orientation: landscape) and (min-width: ${SCREEN_SIZES.landscapeMinDistance})`;

export const ANALYTIC_TYPES = {
  highlight_count: "highlight_count",
  move_frame_count: "move_frame_count",
  backward_10s_count: "backward_10s_count",
  forward_10s_count: "forward_10s_count",
  marker_count: "marker_count",
  share_count: "share_count",
  download_count: "download_count",
};
