import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { SCREEN_SIZES } from "utils/constants";

export const StyledAnnouncementBarContainer = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  text-align: center;
  background: ${({ bg_color }) => bg_color};
  padding: 20px 0;
  z-index: 999;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    padding: 63px 0 30px 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 63px 0 40px 0;
  }
`;

export const StyledFlexContainer = styled.div`
  max-width: 780px;
  height: 100%;
  ${styles.FlexRowCenter}
  column-gap: 20px;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    ${styles.FlexColumn}
    grid-gap: 30px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 0 35px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 0 10px;
  }
`;

export const StyledBarTitle = styled.div`
  p {
    font-family: "Inter Regular" !important;
    font-weight: 400;
    font-style: normal;
  }
`;

export const StyledImageContainer = styled.img`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    right: 20px;
    top: 20px;
    transform: unset;
  }
`;
