import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { isMobile } from "react-device-detect";
import { SCREEN_SIZES } from "utils/constants";

export const StyledAddMarkerContainer = styled.div`
  ${styles.FlexRow};
  justify-content: flex-start;
  position: absolute;
  bottom: 100px;
  left: 17px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;

  ${isMobile} && {
    :hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    bottom: 90px;
    left: 9px;
    border-radius: 3px;
  }
`;

export const StyledAddMarkerText = styled.div`
  margin: 0 0 5px 9px;
  color: ${colors.white};
  ${styles.FontMedium};
  font-size: 14px;
  min-width: 79px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 10px;
    margin: 0 0 5px 5px;
  }
`;

export const StyledImageContainer = styled.div``;

export const AddMarkerIcon = styled.img`
  margin-right: 1px;
`;
