import { useDispatch, useSelector } from "react-redux";
import { useEffect, useId, useState } from "react";
import { Pagination } from "../pagination";
import { VideoListItem } from "../videoListItem";
import { useVideoActions } from "hooks";
import { SUCCEEDED } from "utils/constants";
import { getVideoList, resetStatus } from "store";
import { useNavigate } from "react-router-dom";
import { API } from "configs/api";
import {
  StyledPaginationContainer,
  StyledVideoListContainer,
  VideoListWrapper,
} from "./styled";

export const VideoList = ({
  openFilter,
  setCheckedLabels,
  checkedLabels,
  sortBy,
  setSortBy,
}) => {
  const { videoList, updateFavoriteStatus } = useSelector(
    (state) => state.videoList
  );
  const { user, deleteClipFromVideoListStatus, video } = useSelector(
    (state) => state.video
  );

  const [deletedVideoId, setDeletedVideoId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = useId();
  const videoActions = useVideoActions();

  useEffect(() => {
    if (updateFavoriteStatus) {
      videoActions.getData();
    }
  }, [updateFavoriteStatus]);

  useEffect(() => {
    if (deleteClipFromVideoListStatus === SUCCEEDED) {
      dispatch(resetStatus("deleteClipFromVideoListStatus"));
      const queryParams = {
        userId: user.id,
        code: user.security_string,
        activeTab: localStorage.getItem("activeTab"),
        filters: "",
      };

      dispatch(getVideoList(queryParams));
      if (deletedVideoId === video.id) {
        const parentVideoUrl = `${API.video}/${video?.parent_id}?user_id=${user.id}&code=${user.security_string}`;
        navigate(parentVideoUrl);
      }
    }
  }, [deleteClipFromVideoListStatus, deletedVideoId]);

  return (
    <VideoListWrapper openFilter={openFilter}>
      <StyledPaginationContainer>
        <Pagination sortBy={sortBy} setSortBy={setSortBy} topPosition={true} />
      </StyledPaginationContainer>

      <StyledVideoListContainer>
        {videoList.map((video, index) => (
          <VideoListItem
            setCheckedLabels={setCheckedLabels}
            checkedLabels={checkedLabels}
            item={video}
            key={`${id}-${index}`}
            setDeletedVideoId={setDeletedVideoId}
          />
        ))}
      </StyledVideoListContainer>

      <Pagination sortBy={sortBy} setSortBy={setSortBy} topPosition={false} />
    </VideoListWrapper>
  );
};
