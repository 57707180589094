import { Images } from "assets/images";
import { PlayContainer, PlayIcon } from "./styled";

export const BigPlayButton = ({ setIsPreview }) => {
  const handleTest = () => {
    setIsPreview(true);
  };

  return (
    <PlayContainer>
      <PlayIcon onClick={handleTest}>
        <img src={Images.PlayIcon} alt="" />
      </PlayIcon>
    </PlayContainer>
  );
};
