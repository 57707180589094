import * as styles from "assets/styles/globalStyles.js";
import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";

export const ModalContainer = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
`;

export const Modal = styled.div`
  overflow: auto;
  position: relative;
  ${styles.FlexColumn};
  justify-content: center;
  background: ${colors.white};
  max-width: 518px;
  width: 100%;
  padding: 50px 35px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -4px 4px 6px 3px rgb(0 0 0 / 15%);
  border-radius: 3px;
`;

export const CloseSvg = styled.img`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  width: 15px;
`;
