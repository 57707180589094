import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { WindowInnerSize } from "utils/constants";
import {
  IncompatibleFormatModal,
  ModalPurpose,
  ModalInsideWrapper,
  BackToUploadButton,
  WhyIsModalShown,
} from "./styled";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const IncompatibleFile = ({ close }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Modal open={true}>
      <IncompatibleFormatModal>
        <ModalInsideWrapper>
          {windowSize.innerWidth <= WindowInnerSize ? (
            <WhyIsModalShown>
              Oops, you tried to upload a file that is not a video. Please try
              again.
            </WhyIsModalShown>
          ) : (
            <ModalPurpose>
              <WhyIsModalShown>
                Oops, you tried to upload an incompatible file format. Please
                try again.
              </WhyIsModalShown>
              <WhyIsModalShown>
                Accepted file formats: .mp4, .mov, .ogx, .oga, .ogv, .ogg,
                .webm, .mkv
              </WhyIsModalShown>
            </ModalPurpose>
          )}
          <BackToUploadButton onClick={close}>
            Back to upload
          </BackToUploadButton>
        </ModalInsideWrapper>
      </IncompatibleFormatModal>
    </Modal>
  );
};

export default IncompatibleFile;
