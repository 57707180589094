import { useEffect, useRef } from "react";
import { useOnClickOutside } from "hooks";
import { OutlineButton, SimpleButton } from "../buttons";
import { colors } from "assets/styles/colors";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import {
  ModalContainer,
  ButtonsContainer,
  ConfirmModal,
  NoteContainer,
} from "./styled";

export const ConfirmDeleteMarkerModal = ({ show, close, event }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, close);

  useEffect(() => {
    if (show) {
      disableBodyScroll(ref.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [show]);

  return (
    <ModalContainer show={show} className="confirm-modal-container">
      <ConfirmModal ref={ref}>
        <NoteContainer>
          <div>
            <span>Note:</span> This action deletes the{" "}
          </div>
          <div>clip marker only, not the clip.</div>
        </NoteContainer>
        <ButtonsContainer>
          <OutlineButton text="Cancel" event={close} isBIg={true} />
          <SimpleButton
            isFilter={true}
            text="Continue"
            event={event}
            style={{
              background: colors.primary,
              color: colors.white,
              padding: "11px 26px 12px 26px",
            }}
          />
        </ButtonsContainer>
      </ConfirmModal>
    </ModalContainer>
  );
};
