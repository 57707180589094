import { Images } from "assets/images";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IncompatibleFile from "./incompatibleFile";
import { ExpectedFileTypes, WindowInnerSize } from "utils/constants";
import {
  WrapperContainer,
  ClickableField,
  InsideBlock,
  InformativeText,
  BrowseButton,
  ParentBlock,
  FileUploaderSection,
  ImageUpload,
} from "./styled.js";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const UploadingVideo = () => {
  const [chunks, setChunks] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isError, setIsError] = useState(false);

  const onUploadProgress = (event) => {
    if (!ExpectedFileTypes.includes(event.file.type)) {
      setIsError(true);
    } else {
      const chunk = {
        segmentSize: event.segmentSize,
        bytesLoaded: event.bytesLoaded,
        bytesTotal: event.bytesTotal,
      };
      setChunks([...chunks, chunk]);
    }
  };

  const fileTypeError = () => {
    setIsError((prev) => !prev);
  };

  const FileTypeClose = () => {
    setIsError(false);
  };

  const onUploadStarted = () => {
    setChunks([]);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <WrapperContainer>
      <FileUploaderSection
        name="file"
        uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
        chunkSize={200000}
        uploadMode={"instantly"}
        onUploadStarted={onUploadStarted}
        onProgress={onUploadProgress}
        accept={"*"}
        id="file-uploader"
        dialogTrigger="#dropzone-external"
      />

      <ClickableField id="dropzone-external">
        <InsideBlock>
          <ImageUpload src={Images.VectorIcon} />
          <ParentBlock>
            <InformativeText>
              {windowSize.innerWidth <= WindowInnerSize
                ? "Tap to upload your video"
                : "Drag and drop your video here, or"}
            </InformativeText>
            {isError && (
              <IncompatibleFile close={fileTypeError} isError={isError} />
            )}
            <BrowseButton> Browse </BrowseButton>
          </ParentBlock>
        </InsideBlock>
      </ClickableField>
      <Link to="/club/video/upload/progress">
        <h1>To the next page</h1>
      </Link>
    </WrapperContainer>
  );
};
