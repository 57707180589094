import { Route, Routes } from "react-router-dom";
import { ROUTES } from "configs/routes";
import { NotFound } from "./fragments/notFound";
import { Home } from "./fragments/home";
import { UploadVideo } from "./fragments/uploadVideo";
import { AddInfo, ProgressBlock } from "./fragments/uploadVideo/components";
import { Layout } from "./fragments/layout";

function App() {
  // const Home = Loadable(lazy(() => import("./fragments/home/home")));
  return (
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route path={ROUTES.selectVideo} element={<Home />} />
          <Route path={ROUTES.video} element={<Home />} />
          <Route path={ROUTES.uploadVideo} element={<UploadVideo />} />
          <Route
            path={ROUTES.uploadingProgressDemo}
            element={<ProgressBlock />}
          />
          <Route path={ROUTES.uploadVideoAddInfo} element={<AddInfo />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
