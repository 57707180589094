import { colors } from "assets/styles/colors";
import styled from "styled-components/macro";

export const StyledProgressBarContainer = styled.div`
  width: calc(100% - 2px);
  position: absolute;
  bottom: 1px;
  left: 1px;

  .MuiLinearProgress-colorPrimary {
    background-color: hsla(0, 0%, 100%, 0.2);
    height: 8px;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${colors.primary};
  }
`;
