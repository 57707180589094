import { TextField } from "@mui/material";
import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import { SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const Container = styled.div`
  position: absolute;
  bottom: -70px;
  left: 0;
  width: 100%;
  height: 69px;
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 0 20px;
  }
`;

export const StyledInput = styled(TextField)`
  * {
    border: none !important;
  }

  max-width: 738px;
  width: 100%;
  margin-right: 3px;

  input {
    padding-left: 15px;
    background: ${colors.white};
    border-radius: 5px;
    height: 6px;

    ::placeholder {
      font-size: 14px;
      line-height: 17px;
      ${styles.FontRegular}
      padding-left: 8px;
      opacity: 0.5;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      padding-left: 7px;
    }
  }
`;

export const AcceptButton = styled.div`
  ${styles.FontMedium};
  background: ${colors.primary};
  color: ${colors.white};
  padding: 11px 30px;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  line-height: 19px;
  min-width: max-content;
  margin: 0 10px 0 15px;

  &:hover,
  &:active {
    background: ${colors.darkBlack};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin: 0 20px 0 25px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: 11px 20px;
    margin: ${({ isPreview }) =>
      isPreview ? "0 26px 0 23px" : "0 21px 0 23px"};
    font-size: 14px;
  }
`;

export const StyledDeleteCancelContainer = styled.div`
  display: flex;
`;

export const StyledDeleteButton = styled.div`
  ${styles.FontRegular};
  min-width: max-content;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
  margin-right: 20px;

  ${isMobile} && {
    :hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
  }
`;

export const StyledCancelButton = styled.div`
  ${styles.FontRegular};
  font-size: 14px;
  line-height: 17px;
  color: ${colors.white};
  margin-right: ${({ isPreview }) => (isPreview ? "14px" : "3px")};

  ${isMobile} && {
    :hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 12px;
    margin-right: ${({ isPreview }) => (isPreview ? "6px" : "0")};
  }
`;
