import { TextField } from "@mui/material";
import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";

export const OnVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledMarketDescriptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 69px;
  background: rgba(20, 20, 20, 0.95);
  z-index: 999;
  display: flex;
`;

export const StyledInput = styled(TextField)`
  * {
    border: none !important;
  }

  input {
    padding-left: 15px;
    background: ${colors.white};
    border-radius: 5px;
    height: 3px;

    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      ${styles.FontRegular}
      padding-left: 2px;
      opacity: 0.6;
    }

    @media only screen and (max-width: ${SCREEN_SIZES.md}) {
      padding-left: 9px;
    }
  }
`;

export const SubmitButton = styled.div`
  ${styles.FontMedium};
  background: ${colors.primary};
  color: ${colors.white};
  padding: ${({ isEditMarker }) => (isEditMarker ? "11px 30px" : "11px 20px")};
  height: max-content;
  width: max-content;
  border: none;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  font-size: 14px;
  line-height: 17px;

  &:hover,
  &:active {
    background: ${colors.darkBlack} !important;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin: 0 6px 0 0px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: ${({ isEditMarker }) => (isEditMarker ? "0" : "0 6px 0 10px")};
    padding: 11px 20px;
  }
`;

export const StyledButton = styled.div`
  ${styles.FontMedium};
  font-size: 12px;
  line-height: 15px;
  color: ${colors.white};
  margin-left: ${({ isEditMarker }) => (!isEditMarker ? "0" : "9px")};

  :hover,
  :active {
    text-decoration: underline;
  }
`;

export const DeleteButton = styled.div`
  ${styles.FontMedium};
  font-size: 12px;
  line-height: 15px;
  color: ${colors.white};
  margin-left: 0;

  :hover,
  :active {
    text-decoration: underline;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 661px) auto auto auto;
  grid-gap: ${({ isEditMarker }) => (isEditMarker ? "16px" : "15px")};
  padding: 0 15px;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    grid-gap: ${({ isEditMarker }) => (isEditMarker ? "14px" : "18px")};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    grid-gap: ${({ isEditMarker }) => (isEditMarker ? "18px" : "12px")};
    padding: ${({ isEditMarker }) =>
      isEditMarker ? "0 20px" : "0 10px 0 20px"};
  }
`;
