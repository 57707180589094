import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const FilterWrapper = styled.div`
  border-radius: 0 0 3px 3px;
  padding: ${({ openFilter }) =>
    openFilter ? "7px 30px 34px 30px" : "8px 30px 0 0"};
  background: ${({ openFilter }) =>
    openFilter ? "rgba(0, 0, 0, 0.03)" : colors.white};

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding: ${({ openFilter }) =>
      openFilter ? "8px 30px 34px 30px" : "8px 30px 0 0"};
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding: 9px 31px 34px 30px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 8px 10px 32px 10px;
  }
`;

export const FilterIconContainer = styled.div`
  ${styles.FlexRow};
  justify-content: flex-end;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    height: 17px;
    display: block;
    margin-left: auto;
    width: 70px;
  }
`;

export const FilterIcon = styled.div`
  ${styles.FlexRow};
  align-items: center;
  width: fit-content;
  opacity: 0.75;

  span {
    ${styles.FontMedium};
    color: ${colors.black};
    font-size: 14px;
    line-height: 17px;
    margin: 0 14px 0 5px;

    @media only screen and (max-width: ${SCREEN_SIZES.md}) {
      margin: 0 12px 0 5px;

      img {
        margin-top: 3px;
      }
    }

    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      margin: 0px 8px 0 8px;
      font-size: 12px;
      line-height: 15px;

      img {
        margin-top: 1px;
      }
    }

    ${!isMobile} && {
      ${MEDIA_LANDSCAPE} {
        margin: 0px 9px 0 6px;
        font-size: 12px;
        line-height: 15px;
      }
    }

    @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
      margin: 0 6px 0 30px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    img {
      margin-right: -20px;
      width: 13px;
      margin-top: 0px;
    }
  }

  ${isMobile} && {
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const ArrowImg = styled.img`
  transform: ${({ openFilter }) =>
    openFilter ? "rotate(180deg)" : "rotate(0)"};

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-right: -21px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-right: -21px;
    }
  }
`;

export const LabelContainer = styled.div`
  ${styles.FlexColumn};
  position: relative;
  width: 98%;
  padding: 5px 0 14px;

  p {
    ${styles.FontRegular};
    color: ${colors.black};
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 6px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    padding: 6px 0 14px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 97%;
    padding: 6px 0 13px;

    p {
      margin-bottom: 6px;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      width: 97%;
      padding: 6px 0 13px;

      p {
        margin-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding: 5px 0 13px;

    p {
      margin-bottom: 7px;
    }
  }
`;

export const LabelsRow = styled.div`
  position: relative;

  .slick-slide {
    margin-right: 18px;

    @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
      margin-right: 8px;
    }
  }

  .slick-disabled {
    display: none !important;
  }
`;

export const SampleNextArrow = styled.img`
  position: absolute;
  right: -20px;
  top: 4px;
  width: 15px;
  height: 14px;
  transform: rotate(270deg);
  cursor: pointer;
  opacity: 0.75;

  ${isMobile} && {
    :hover {
      opacity: 1;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    right: -15px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    right: -21px;
  }
`;

export const SamplePrevArrow = styled.img`
  position: absolute;
  left: -20px;
  top: 4px;
  width: 15px;
  height: 14px;
  cursor: pointer;
  transform: rotate(90deg);
  opacity: 0.75;

  ${isMobile} && {
    :hover {
      opacity: 1;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    left: -15px;
  }
`;

export const Label = styled.span`
  display: inline-block;
  padding: 4px 3px;
  background: ${({ checked }) => (checked ? colors.black : colors.grey)};
  color: ${({ checked }) => (checked ? colors.white : colors.black)};
  margin-right: 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  text-transform: capitalize;
  max-height: 20px;
  width: fit-content;
  ${styles.FontMedium}
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  cursor: pointer;

  ${isMobile} && {
    :hover {
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
    }
  }
`;

export const DatePickerWrapper = styled.div`
  ${styles.FlexRow};
  width: 228px;
  margin-bottom: 28px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-bottom: 30px;
  }

  span {
    ${styles.FontRegular}
    font-size: 10px;
    line-height: 12px;
    color: ${colors.lightBlack};
    margin-bottom: 3px;
  }

  .react-datepicker__input-container {
    input {
      background: ${colors.white};
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 106px;
      height: 30px;
      padding: 8px 20px 8px 12px;
      ${styles.FontRegular}
      font-size: 12px;
      line-height: 15px;
      color: ${colors.lightBlack};

      ${isMobile} && {
        :hover {
          box-shadow: 0 2px 6px rgba(18, 18, 18, 0.7);
          outline: none;
        }
      }
    }

    .react-datepicker-ignore-onclickoutside {
      box-shadow: 0 2px 6px rgba(18, 18, 18, 0.7);
      outline: none;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    background: ${colors.white};
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(18, 18, 18, 0.7);
    width: 414px;
    border-radius: 5px;
    padding: 44px 35px 38px;
    margin-top: 18px;

    @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
      margin-top: 18px;
      width: 310px;
      padding: 10px 5px;
      transform: translate3d(-5px, 0px, 0px);
    }

    .react-datepicker__month-container {
      float: none;
    }

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
      ${styles.FlexRow};
      justify-content: center;
      align-items: center;
      top: 34px;
      right: 87px;

      @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
        top: 2px;
        right: 40px;
      }
    }

    .react-datepicker__navigation--previous {
      left: 87px;

      @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
        left: 40px;
      }
    }

    .react-datepicker__day {
      ${styles.FlexRow};
      ${styles.FontBold};
      justify-content: center;
      align-items: center;
      color: ${colors.lightBlack};
      width: 22px;
      font-size: 16px;
      line-height: 19px;
      padding: 10px 20px;

      @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
        padding: 10px 15px;
        font-size: 12px;
        line-height: 10px;
      }
    }

    .react-datepicker__day--disabled {
      color: rgba(18, 18, 18, 0.3);
      cursor: not-allowed;
    }

    .react-datepicker__day-names {
      margin: 0;
      ${styles.FlexRow};
      justify-content: space-between;
    }

    .react-datepicker__week {
      ${styles.FlexRow};
      justify-content: space-between;
    }

    .react-datepicker__day-name {
      ${styles.FlexRow};
      justify-content: center;
      align-items: center;
      ${styles.FontBold};
      color: ${colors.black};
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      opacity: 0.5;
      margin-top: 20px;
      padding: 10px 20px;

      @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
        padding: 10px 15px;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      color: ${colors.white};
      border-radius: 22px;
      background-color: ${colors.primary};
    }

    .react-datepicker__month {
      margin: 0;
    }
  }

  .react-datepicker__header {
    color: ${colors.lightBlack};
    background: ${colors.white};
    border: none;
    padding: 0;

    .react-datepicker__current-month {
      ${styles.FontBold};
      color: ${colors.black};
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }
`;

export const DatePickerInput = styled.div`
  max-width: 106px;
  margin-right: 16px;

  input {
    height: 13px;
  }
`;

export const StyledDivOpacity = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    270deg,
    #f7f7f7 0%,
    #f7f7f7 11.5%,
    rgba(247, 247, 247, 0) 50%
  );
  opacity: 1;
  width: 85px;
  height: 20px;
`;
