import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRandomGif } from "store";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { makeClipPreview, addVideo } from "store";
import { useAnalytics, useVideoActions } from "hooks";
import {
  SUCCESS_MODAL,
  SUCCEEDED,
  REJECTED,
  ANALYTIC_TYPES,
} from "utils/constants";
import { LeftConfetti, RightConfetti } from "./confettiOptions";
import { resetStatus } from "store/video/slice";
import {
  FullBigClass,
  FullWidthBlock,
  StyledModalContainer,
  ProgressBar,
  TextProgressColor,
  StyledDivLine,
  ImageBlock,
  SomethingSpan,
  StyledModalTitle,
  CanvasConfetti,
  StyledOpacityContainer,
  StyledImageContainer,
} from "./styled";

export const MakeClipModal = ({
  setModalName,
  makeClipEndTime,
  makeClipStartTime,
  clipTitle,
  videoId,
}) => {
  const {
    makeClipVideoUrlAndSize,
    makeClipVideoUrlAndSizeStatus,
    gifSrc,
    addVideoStatus,
  } = useSelector((state) => state.video);

  const dispatch = useDispatch();
  const videoActions = useVideoActions();

  const duration = makeClipEndTime - makeClipStartTime;
  const [progress, setProgress] = useState(0);
  const refAnimationInstance = useRef(null);
  const [activeProgress, setActiveProgress] = useState(0);
  const [isTransitionModal, setIsTransitionModal] = useState(false);
  const analyticActions = useAnalytics();

  const progressTimer = () => {
    if (duration < 100) {
      return 250;
    } else if (duration < 200) {
      return 350;
    } else {
      return 450;
    }
  };

  const ConfettiRef = (Confetti) => {
    refAnimationInstance.current = Confetti;
  };

  const nextTickAnimation = () => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(LeftConfetti) &&
        refAnimationInstance.current(RightConfetti);
    }
  };

  useEffect(() => {
    const highlight = {
      end: makeClipEndTime,
      preview: false,
      start: makeClipStartTime,
      video_id: videoId,
    };

    dispatch(makeClipPreview(highlight));
    dispatch(getRandomGif());

    analyticActions.update(ANALYTIC_TYPES.highlight_count);

    setIsTransitionModal(true);
  }, []);

  useEffect(() => {
    setTimeout(nextTickAnimation, 100);

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 94) {
          return 94;
        }
        if (oldProgress >= 100) {
          if (makeClipVideoUrlAndSizeStatus !== REJECTED) {
            setModalName(SUCCESS_MODAL);
          }
        }
        setActiveProgress(Math.floor(oldProgress));
        return Math.min(oldProgress + 0.5, 100);
      });
    }, progressTimer());

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (makeClipVideoUrlAndSizeStatus === SUCCEEDED) {
      const saveVideo = {
        ...makeClipVideoUrlAndSize,
        duration: makeClipEndTime - makeClipStartTime,
        start: makeClipStartTime,
        title: clipTitle,
        url: makeClipVideoUrlAndSize.output,
        download_size: makeClipVideoUrlAndSize.size,
        video_id: videoId,
      };

      setProgress(98);
      dispatch(addVideo(saveVideo));
      dispatch(resetStatus("makeClipVideoUrlAndSizeStatus"));
    }
  }, [makeClipVideoUrlAndSizeStatus]);

  useEffect(() => {
    if (addVideoStatus === SUCCEEDED) {
      dispatch(resetStatus("addVideoStatus"));
      videoActions.getUpdatedVideoList();
    }
  }, [addVideoStatus]);

  return (
    <Modal open={open}>
      <StyledOpacityContainer isTransitionModal={isTransitionModal}>
        <StyledModalContainer className="confetti-modal-container">
          <CanvasConfetti refConfetti={ConfettiRef} />

          <FullBigClass>
            <StyledModalTitle className="confetti-modal-title">
              Your new clip is being created...
            </StyledModalTitle>

            <Box>
              <FullWidthBlock>
                <StyledDivLine
                  style={{ transform: `translateX(${+activeProgress}%)` }}
                >
                  <TextProgressColor>{activeProgress}%</TextProgressColor>

                  {activeProgress !== 100 && <SomethingSpan />}
                </StyledDivLine>
              </FullWidthBlock>

              <ProgressBar variant="determinate" value={activeProgress} />
            </Box>

            <StyledImageContainer className="gif-container">
              {gifSrc ? (
                <ImageBlock
                  src={gifSrc}
                  alt="sorry the image isn't loaded yet"
                />
              ) : null}
            </StyledImageContainer>
          </FullBigClass>
        </StyledModalContainer>
      </StyledOpacityContainer>
    </Modal>
  );
};
