export const colors = {
  black: "#000",
  lightBlack: "#121212",
  grey: "#E6E7E9",
  white: "#fff",
  primary: "#3399FF",
  lightPrimary: "#DBEDFF",
  yellow: "#ffb800",
  lightYellow: "#fff4d9",
  blue: "#e1e1e1",
  silver: "#DEDEDE",
  navyBlue: "#292f36",
  waveBlue: "#0c6dc9",
  lightBlue: "#dbedff",
  oceanBlue: "#1976d2",
  lightWhite: "#12121207",
  darkBlack: "#141414",
  lightSilver: "#121212B2",
  red: "#e54040",
  lightRed: "#EF3D23",
  bordeaux: "#B7230D",
  lightGrey: "#1212120C",
  svgPrimary: `invert(43%) sepia(84%) saturate(929%) hue-rotate(189deg) brightness(102%) contrast(102%)`,
  svgWhite: `invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);`,
};
