import { Images } from "assets/images";
import { useSelector } from "react-redux";
import { OCAMS_CLUB_LINK } from "utils/constants";

import {
  BlackLine,
  HeaderLeftSideIMG,
  HeaderRightSide,
  HeaderRightSideIMG,
  HeaderRightSideName,
  HeaderWrapper,
  StyledImageContainer,
} from "./styled";

export const Header = () => {
  const { user, club } = useSelector((state) => state.video);
  const userUrl = `/video?user_id=${user.id}&code=${user.security_string}`;

  return (
    <HeaderWrapper>
      <StyledImageContainer>
        <a href={club?.website} target="_blank" rel="noreferrer">
          {club?.web_page_logo_file && (
            <HeaderLeftSideIMG src={club.web_page_logo_file} alt="Logo" />
          )}
        </a>
      </StyledImageContainer>

      <HeaderRightSide>
        <a href={userUrl}>
          <HeaderRightSideName>{user.name || ""}</HeaderRightSideName>
        </a>

        <BlackLine />

        <a href={OCAMS_CLUB_LINK}>
          <HeaderRightSideIMG
            src={Images.SeeingOcamsNewMobile}
            title=""
            alt=""
          />
        </a>
      </HeaderRightSide>
    </HeaderWrapper>
  );
};
