import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import { Images } from "assets/images";
import FileUploader from "devextreme-react/file-uploader";
import { SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const WrapperContainer = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 190px);
  background: ${({ isAddInfo }) =>
    isAddInfo ? `${colors.white}` : `${colors.primary}`};

  @media only screen and (max-width: 1020px) {
    height: ${({ isAddInfo }) => (isAddInfo ? `880px` : "calc(100vh - 190px)")};
  }

  @media only screen and (max-width: 375px) {
    height: ${({ isAddInfo }) => (isAddInfo ? `671px` : "calc(100vh - 190px)")};
  }
`;

export const ClickableField = styled.div`
  margin: 120px auto;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 640px;
  height: 360px;
  cursor: pointer;
  border-radius: 20px;
  background-color: ${colors.white};
  background-image: url(${Images.UploadBorder});
  background-size: 100% 100%;

  @media only screen and (max-width: 679px) {
    width: 460px;
    height: 268px;
    background-image: url(${Images.TabletUploadBorder});
  }

  @media screen and (max-width: 500px) {
    width: 320px;
    height: 180px;
    margin: 217px auto;
  }
`;

export const FileUploaderSection = styled(FileUploader)`
  position: absolute;
  left: 0;
  right: 0;
  margin: 120px auto;
  width: 640px;
  height: 200px;
  cursor: pointer;
  opacity: 0;
  z-index: 1;

  @media only screen and (max-width: 679px) {
    width: 460px;
    height: 268px;

    .dx-fileuploader-wrapper,
    .dx-fileuploader-container,
    .dx-fileuploader-content,
    .dx-fileuploader-input-wrapper,
    .dx-button-has-text .dx-fileuploader-button,
    .dx-button-content,
    .dx-button-text {
      height: 268px;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 320px;
    margin: 220px auto;
    height: 180px;

    .dx-fileuploader-wrapper,
    .dx-fileuploader-container,
    .dx-fileuploader-content,
    .dx-fileuploader-input-wrapper,
    .dx-button-has-text .dx-fileuploader-button,
    .dx-button-content,
    .dx-button-text {
      height: 160px;
    }
  }

  @media only screen and (max-width: 350px) {
    width: 315px;
    height: 180px;
  }

  span {
    display: block;
    width: 100%;
    height: 360px;
  }

  .dx-fileuploader-file,
  .dx-fileuploader-input-container {
    display: none;
  }
`;

export const InsideBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 512px;
  height: 279px;
  margin-top: 18px;

  @media only screen and (max-width: 679px) {
    height: 228px;
  }

  @media only screen and (max-width: 500px) {
    width: 320px;
    height: 142px;
  }
`;

export const ParentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  height: 110px;
  margin-bottom: 7px;

  @media only screen and (max-width: 679px) {
    margin-bottom: 11px;
  }

  @media only screen and (max-width: 500px) {
    width: max-content;
    height: auto;
    margin-bottom: 12px;
  }
`;

export const ImageUpload = styled.img`
  @media only screen and (max-width: 679px) {
    width: 124px;
  }

  @media only screen and (max-width: 500px) {
    width: 155px;
  }
`;

export const InformativeText = styled.span`
  ${styles.FontSemiBold}
  font-size: 20px;

  @media only screen and (max-width: 500px) {
    font-size: 16px;
    ${styles.FontBold}
  }
`;

export const BrowseButton = styled.button`
  width: 186px;
  height: 60px;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  border-radius: 100px;
  background: none;
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  ${styles.FontMedium};
  z-index: 1;

  ${isMobile} && {
    &:hover {
      background: ${colors.primary};
      color: ${colors.white};
    }
  }

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const UploadLoading = styled.div`
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  width: 640px;
  height: 360px;
  background-color: ${colors.white};
  background-image: url(${Images.UploadBorder});
  background-size: 100% 100%;
  border-radius: 20px;

  @media only screen and (max-width: 679px) {
    width: 460px;
    height: 268px;
    background-image: url(${Images.TabletUploadBorder});
  }

  @media only screen and (max-width: 679px) {
    width: 380px;
    height: 200px;
  }

  @media only screen and (max-width: 375px) {
    width: 320px;
    height: 180px;
  }
`;

export const ProgressLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 77px;
  height: 104px;
  width: 240px;
`;

export const UploadingText = styled.span`
  cursor: auto;
  font-size: 20px;
  color: ${colors.black};
  ${styles.FontSemiBold};

  span {
    font-size: 20px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    @keyframes blink {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

export const VideoInfoBlock = styled.div`
  width: 991px;
  margin: 120px auto;

  @media only screen and (max-width: 1020px) {
    width: 640px;
  }

  @media only screen and (max-width: 640px) {
    width: 460px;
  }

  @media only screen and (max-width: 460px) {
    width: 375px;
  }

  @media only screen and (max-width: 375px) {
    margin: 15px auto;
    width: 100%;
  }
`;

export const VideoAndMainInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1020px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const VideoBlock = styled.iframe`
  @media only screen and (max-width: 675px) {
    width: 460px;
    height: 259px;
  }

  @media only screen and (max-width: 460px) {
    width: 375px;
    height: 210px;
  }

  @media only screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const AddInfoContainer = styled.div`
  ${styles.FlexColumn};
  justify-content: space-between;
  width: 320px;
  height: 360px;

  @media only screen and (max-width: 1020px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 640px;
    height: auto;
  }

  @media only screen and (max-width: 834px) {
    margin-top: 41px;
  }

  @media only screen and (max-width: 675px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 51px;
  }

  @media only screen and (max-width: 375px) {
    margin-top: 28px;
  }
`;

export const InputWrapper = styled.div`
  ${styles.FlexColumn};
  justify-content: space-between;
  height: 200px;
`;

export const InputBlocks = styled.div`
  ${styles.FlexColumn};
  justify-content: space-between;
  width: 100%;
  height: 80px;
`;

export const FullInput = styled.input`
  padding-left: 25px;
  border-radius: 5px;
  width: 320px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 16px;
  background: ${colors.lightWhite};
  ${styles.FontMedium};
  color: ${colors.lightBlack};

  &:focus {
    box-shadow: 0px 2px 6px ${colors.lightSilver};
  }

  &::placeholder {
    ${styles.FontMedium};
  }

  @media only screen and (max-width: 675px) {
    width: 460px;
  }

  @media only screen and (max-width: 460px) {
    width: 375px;
  }

  @media only screen and (max-width: 375px) {
    width: 320px;
  }
`;

export const TitleSpan = styled.span`
  margin-top: 2px;
  font-size: 14px;
  ${styles.FontBold}
`;

export const ChoosingAccounts = styled.input`
  display: flex;
  align-items: center;
  padding-left: 20px;
  border: none;
  width: 320px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  background: ${colors.lightWhite};
  ${styles.FontMedium}
  color: ${colors.lightBlack};

  &:focus {
    box-shadow: 0px 2px 6px ${colors.lightSilver};

    .open-accounts-block {
      display: ${({ accounts }) => (accounts ? `block` : `none`)};
    }
  }

  @media only screen and (max-width: 675px) {
    width: 100%;
  }
`;

export const AccountsWrapper = styled.div`
  position: absolute;
  top: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 262px;
  background: ${colors.white};
  box-shadow: 0px 2px 6px ${colors.lightSilver};

  @media only screen and (max-width: 834px) {
    top: unset;
    margin-top: 90px;
  }

  @media only screen and (max-width: 675px) {
    width: 460px;
  }

  @media only screen and (max-width: 460px) {
    width: 375px;
  }

  @media only screen and (max-width: 375px) {
    width: 320px;
  }
`;

export const BlockAccounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 288px;
  height: auto;
  background: ${colors.white};
  border-radius: 5px;
`;

export const EachAccountBlock = styled.div`
  width: 288px;
  padding: 10px 0px;
  cursor: pointer;
  border-radius: 5px;

  &.includes {
    background: ${colors.primary};
  }

  ${isMobile} && {
    &:hover {
      background: ${colors.lightPrimary};
    }
  }
`;

export const CheckBoxInput = styled.input`
  margin-left: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 1px solid rgba(18, 18, 18, 0.3);
  border-radius: 2px;
`;

export const AccountName = styled.span`
  margin-left: 14px;
  ${styles.FontMedium};
  font-size: 16px;
  line-height: 19px;

  ${isMobile} && {
    &:hover {
      color: ${colors.primary};
    }
  }
`;

export const CancelSaveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;

  @media only screen and (max-width: 1020px) {
    align-self: flex-end;
    margin-left: 20px;
  }

  @media only screen and (max-width: 675px) {
    align-self: normal;
    justify-content: center;
    margin-left: 250px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 460px) {
    margin-left: 160px;
  }

  @media only screen and (max-width: 375px) {
    margin-top: 73px;
    margin-left: 108px;
  }
`;

export const EndButtons = styled.div`
  ${styles.FlexRow};
  justify-content: space-between;
  align-items: center;
  width: 210px;
  height: 45px;
`;

export const CancelButton = styled.span`
  margin-left: 2px;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 14px;
  ${styles.FontRegular};
  ${({ isNotification }) =>
    isNotification ? `${styles.FontMedium}` : "unset"};
  margin-bottom: ${({ isAddingInfo }) => (isAddingInfo ? "0px" : "3px")};

  ${isMobile} && {
    :hover {
      text-decoration: underline;
    }
  }
`;

export const SaveButton = styled.button`
  line-height: 22px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  width: 140px;
  height: 50px;
  color: ${colors.white};
  ${styles.FontMedium};

  ${isMobile} && {
    &:hover {
      background: ${colors.darkBlack};
      color: ${colors.white};
    }
  }

  &.active {
    background: ${colors.oceanBlue};

    &:hover {
      background: ${colors.darkBlack};
      color: ${colors.white};
    }
  }
`;

export const SuccessNotification = styled.div`
  display: flex;
  align-items: center;
  width: 320px;
  height: 360px;
`;

export const MainBlockOfNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  margin-left: 2px;
  height: 256px;
  width: 300px;
`;

export const NotificationTitle = styled.span`
  font-size: 24px;
  ${styles.FontBold}
  margin-bottom: 12px;
`;

export const NotificationDescription = styled.span`
  margin-bottom: 3px;
  font-size: 14px;
  text-align: center;
  width: 282px;
  height: 72px;
  line-height: 25px;
  ${styles.FontMedium};
`;

export const IncompatibleFormatModal = styled.div`
  width: 494px;
  height: 300px;
  background: ${colors.white};
  position: absolute;
  left: 0;
  right: 0;
  margin: 314px auto;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 355px;
    height: 300px;
  }

  @media only screen and (max-width: 355px) {
    width: 320px;
    height: 260px;
  }
`;

export const ModalInsideWrapper = styled.div`
  width: 494px;
  height: 204px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 300px;
    height: 133px;
  }
`;

export const ModalPurpose = styled.div`
  width: 402px;
  height: 120px;
  word-break: break-all;
  ${styles.FontRegular}
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 375px;
  }
`;

export const BackToUploadButton = styled.button`
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 194px;
  height: 50px;
  background: ${colors.primary};
  color: ${colors.white};
  ${styles.FontMedium}

  ${isMobile} && {
    &:hover {
      background: ${colors.darkBlack};
    }
  }

  &:focus {
    background: ${colors.darkBlack};
  }
`;

export const WhyIsModalShown = styled.span`
  width: 402px;
  text-align: center;
  font-size: 16px;

  @media only screen and (max-width: 500px) {
    width: 320px;
    height: 40px;
  }
`;
