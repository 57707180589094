import React from "react";
import { Images } from "assets/images";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  NewestOldestContainer,
  StyledPaginationInfo,
  StyledSowingVideosText,
} from "./styled";

export const PaginationInfo = ({
  topPosition,
  setOpenDateFilter,
  paginationData,
  changeDateFilter,
  openDateFilter,
  newestOldestRef,
  sortBy,
}) => {
  const { videoList } = useSelector((state) => state.videoList);

  const handleDateFirst = (date) => {
    changeDateFilter(date);
  };

  useEffect(() => {
    setOpenDateFilter(false);
  }, [videoList]);

  return (
    <StyledPaginationInfo topPosition={topPosition}>
      <StyledSowingVideosText topPosition={topPosition}>
        Showing videos
        {` ${paginationData.from}-${paginationData.to} of ${paginationData.total}`}
      </StyledSowingVideosText>

      {topPosition && (
        <NewestOldestContainer
          rotateArrow={openDateFilter}
          ref={newestOldestRef}
        >
          <button onClick={() => setOpenDateFilter((prevState) => !prevState)}>
            <span>{sortBy === 1 ? "Newest first" : "Oldest first"}</span>
            <img src={Images.DownArrow} alt="" />
          </button>

          {openDateFilter && (
            <ul>
              <li
                className={sortBy === 1 ? "checked" : ""}
                onClick={() => handleDateFirst(1)}
              >
                Newest first
              </li>
              <li
                className={sortBy === 2 ? "checked" : ""}
                onClick={() => handleDateFirst(2)}
              >
                Oldest first
              </li>
            </ul>
          )}
        </NewestOldestContainer>
      )}
    </StyledPaginationInfo>
  );
};
