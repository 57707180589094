import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";
import styled from "styled-components/macro";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const StyledPaginationInfo = styled.div`
  ${styles.FlexRow};
  ${styles.FontMedium};
  align-items: baseline;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    ${styles.FlexColumn};
    padding: 0 20px 0 10px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      ${styles.FlexColumn};
      padding: 0 20px 0 10px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    ${styles.FlexRow};
    padding: unset;
    margin: ${({ topPosition }) => (!topPosition ? "auto" : "37px 0 0 0")};
    width: ${({ topPosition }) => (!topPosition ? "" : "100%")};
  }
`;

export const StyledSowingVideosText = styled.p`
  color: ${colors.red};
  font-size: ${({ topPosition }) => (topPosition ? "16px" : "12px")};
  line-height: ${({ topPosition }) => (topPosition ? "19px" : "15px")};
  margin: ${({ topPosition }) =>
    topPosition ? "6px 0 0 2px" : "2px 84px 0 0"};
  color: ${colors.lightBlack};

  @media only screen and (max-width: ${SCREEN_SIZES.lg}) {
    margin: ${({ topPosition }) => (topPosition ? "5px 0 0" : "2px 82px 0 0")};
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: ${({ topPosition }) => (topPosition ? "12px" : "12px")};
    line-height: ${({ topPosition }) => (topPosition ? "15px" : "15px")};
    margin: ${({ topPosition }) => (topPosition ? "4px 0 0" : "2px 82px 0 0")};
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: ${({ topPosition }) => (topPosition ? "12px" : "12px")};
      line-height: ${({ topPosition }) => (topPosition ? "15px" : "15px")};
      margin: ${({ topPosition }) => (topPosition ? "3px 0 11px" : "0 0 11px")};
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-left: 10px;
    margin-right: unset;
    margin-bottom: ${({ topPosition }) => !topPosition && "18px"};
  }
`;

export const NewestOldestContainer = styled.div`
  margin-left: 11px;
  position: relative;

  button {
    ${styles.FlexRow};
    align-items: center;
    width: 90px;
    justify-content: flex-start;
    background: ${colors.white};
    color: ${colors.lightBlack};
    border: none;
    opacity: 0.75;
    transition: 0.2s;
    cursor: pointer;

    span {
      margin-right: 6px;
      letter-spacing: 0.4px;
    }

    img {
      margin-left: -4px;
      width: 8px;
      transform: ${({ rotateArrow }) =>
        rotateArrow ? "rotate(180deg)" : "rotate(0deg)"};
    }

    ${isMobile} && {
      &:hover {
        opacity: 1;
      }
    }
  }

  ul {
    position: absolute;
    width: 100px;
    top: 15px;
    right: 0;
    background: ${colors.white};
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 7px 0;
    box-shadow: 2px 2px 10px grey;
    z-index: 1;
    cursor: pointer;

    li {
      padding: 4px 0;
      cursor: pointer;
      ${styles.FontRegular};
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      list-style: none;
      color: rgba(18, 18, 18);
      opacity: 0.6;

      ${isMobile} && {
        &:hover {
          background: rgba(0, 0, 0, 0.03);
        }
      }
    }

    .checked {
      background: ${colors.lightPrimary};
      color: ${colors.primary};
      opacity: 1;

      ${isMobile} && {
        &:hover {
          background: ${colors.lightPrimary};
        }
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-left: 0px;
    margin-top: 10px;

    button {
      span {
        font-size: 10px;
      }
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-left: 0px;

      button {
        span {
          margin-right: 12px;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-left: 6px;
  }
`;
