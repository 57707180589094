import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const DeleteSection = styled.div`
  ${styles.FontMedium};
  font-size: ${({ fs }) => fs};
  line-height: 15px;
  opacity: 0.75;
  color: ${colors.lightBlack};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;

  img {
    margin-right: 7px;
  }

  ${isMobile} && {
    &:hover {
      opacity: 1;
      color: ${colors.primary};

      img {
        filter: ${colors.svgPrimary};
      }
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 10px;
    line-height: 12px;

    img {
      margin-right: 10px;
      width: 11px;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 10px;
      line-height: 12px;
      margin-left: 0;
      margin-right: 6px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    line-height: unset;
    margin-left: 20px;

    img {
      margin-right: 7px;
    }
  }
`;
