import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMakeClip,
  deleteClip,
  getVideoList,
  setClipDistance,
  resetStatus,
} from "store";
import {
  CONFIRM_MODAL,
  EDIT_MODAL,
  SUCCEEDED,
  SUCCESS_MODAL,
} from "utils/constants";
import { SuccessBarModal } from "./components/successBarModal";
import { MakeClipModal } from "./components/makeClipModal";
import EditSuccessModal from "./components/editSuccessModal";
import { ConfirmDeleteModal } from "components";
import { API } from "configs/api";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  AcceptButton,
  Container,
  StyledInput,
  StyledCancelButton,
  InputWrapper,
  StyledDeleteButton,
  StyledDeleteCancelContainer,
} from "./styled";

export const InputSection = ({
  isPreview,
  setIsPreview,
  makeClipStartTime,
  makeClipEndTime,
  videoId,
  editClip,
}) => {
  const { video, saveClip, deleteClipStatus, user } = useSelector(
    (state) => state.video
  );

  const [clipTitle, setClipTitle] = useState("");
  const [modalName, setModalName] = useState(null);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenWidth = window.innerWidth;

  const cancelAction = () => {
    setIsPreview(false);
    dispatch(closeMakeClip());
    dispatch(setClipDistance([]));
  };

  const saveButtonHandler = () => {
    if (editClip) {
      setModalName(EDIT_MODAL);
      dispatch(setClipDistance([]));
    } else {
      setModalName(CONFIRM_MODAL);
      dispatch(setClipDistance([]));
    }
    setIsPreview(false);
  };

  const deleteClipEvent = () => {
    dispatch(deleteClip({ id: video.id, type: "deleteClipStatus" }));
  };

  const deleteButtonHandler = () => {
    setOpenConfirmDeleteModal(true);
  };

  const closeConfirmDeleteModal = () => {
    setOpenConfirmDeleteModal(false);
  };

  useEffect(() => {
    if (deleteClipStatus === SUCCEEDED) {
      dispatch(resetStatus("deleteClipStatus"));
      const queryParams = {
        userId: user.id,
        code: user.security_string,
        activeTab: localStorage.getItem("activeTab"),
        filters: "",
      };

      dispatch(getVideoList(queryParams));
      setOpenConfirmDeleteModal(false);
      cancelAction();
      const parentVideoUrl = `${API.video}/${video?.parent_id}?user_id=${user.id}&code=${user.security_string}`;
      navigate(parentVideoUrl);
    }
  }, [deleteClipStatus]);

  useEffect(() => {
    if (video.parent_id) {
      setClipTitle(video.title);
    }
  }, []);

  return (
    <Container className="make-clip-input-section">
      {!saveClip && (
        <InputWrapper className="make-clip-input-wrapper">
          <StyledInput
            onChange={(e) => setClipTitle(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            value={clipTitle}
            placeholder="Clip title"
            variant="outlined"
            autoComplete="off"
            className="clip-title-input"
            autoFocus={isMobile ? false : true}
          />
          <AcceptButton
            isPreview={isPreview}
            onClick={saveButtonHandler}
            className="save-clip-button"
          >
            {isMobile ? "Save clip" : screenWidth > 700 ? "Save clip" : "Save"}
          </AcceptButton>
          {isPreview ? (
            <StyledCancelButton
              className="back-clip-button"
              isPreview={isPreview}
              onClick={() => setIsPreview(false)}
            >
              Back
            </StyledCancelButton>
          ) : (
            <StyledDeleteCancelContainer className="delete-cancel-buttons-container">
              {editClip ? (
                <StyledDeleteButton
                  onClick={deleteButtonHandler}
                  className="delete-clip-button"
                >
                  Delete Clip
                </StyledDeleteButton>
              ) : null}
              <StyledCancelButton
                isPreview={isPreview}
                onClick={cancelAction}
                className="cancel-clip-button"
              >
                Cancel
              </StyledCancelButton>
            </StyledDeleteCancelContainer>
          )}
        </InputWrapper>
      )}

      {modalName === CONFIRM_MODAL ? (
        <MakeClipModal
          makeClipEndTime={makeClipEndTime}
          makeClipStartTime={makeClipStartTime}
          clipTitle={clipTitle}
          videoId={videoId}
          setModalName={setModalName}
        />
      ) : modalName === SUCCESS_MODAL ? (
        <SuccessBarModal setModalName={setModalName} />
      ) : modalName === EDIT_MODAL ? (
        <EditSuccessModal
          makeClipEndTime={makeClipEndTime}
          makeClipStartTime={makeClipStartTime}
          videoId={videoId}
          clipTitle={clipTitle}
          modalName={modalName}
          setModalName={setModalName}
        />
      ) : null}

      <ConfirmDeleteModal
        show={openConfirmDeleteModal}
        close={closeConfirmDeleteModal}
        text={"Delete this clip?"}
        event={deleteClipEvent}
      />
    </Container>
  );
};
