import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "configs/api";
import { useMediaQuery } from "@mui/material";
import { VideoTitle, Labels, LoadingSM } from "fragments";
import { MarkerItem } from "../markerItem";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { setIsPageChanged } from "store/video/slice";
import { isMobile } from "react-device-detect";
import { PENDING, SCREEN_SIZES, SUCCEEDED } from "utils/constants";
import {
  Date,
  DateWrapper,
  FullVideo,
  LabelSection,
  MainVideoInfoWrapper,
  WeekDay,
} from "./styled";

export const MainVideoInfo = ({ setCheckedLabels, checkedLabels }) => {
  const { video, labels, user, markers, isPageChanged } = useSelector(
    (state) => state.video
  );

  const { videoList, paginationData } = useSelector((state) => state.videoList);
  const { editMarkerStatus } = useSelector((state) => state.video);

  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobileXs = useMediaQuery(`(min-width:${SCREEN_SIZES.xs})`);

  const landscape = useMediaQuery(
    `(max-width: ${SCREEN_SIZES.landscape}) and (orientation: landscape) and (min-width: ${SCREEN_SIZES.landscapeMinDistance})`
  );
  const isMobileLandscape = isMobile && landscape;

  const sortMarkers = [...markers].sort((a, b) => (+a.time > +b.time ? 1 : -1));

  useEffect(() => {
    if (isPageChanged) {
      dispatch(setIsPageChanged(false));
      const firstVideoUrl = `${API.video}/${videoList[0]?.video.id}?user_id=${user.id}&code=${user.security_string}`;
      navigate(firstVideoUrl);
    }
  }, [paginationData.current_page]);

  useEffect(() => {
    if (isMobile) {
      if (editMarkerStatus === SUCCEEDED) {
        setShowLoading(false);
      }
      if (editMarkerStatus === PENDING) {
        setShowLoading(true);
      }
    }
  }, [editMarkerStatus]);

  return (
    <MainVideoInfoWrapper className="main-video-info-wrapper">
      {showLoading ? (
        <LoadingSM />
      ) : (
        <>
          {isMobileLandscape ||
            (isMobileXs && (
              <DateWrapper>
                <WeekDay>{dayjs(video.filming_date).format("dddd")}</WeekDay>
                <Date>{dayjs(video.filming_date).format("MMM D YYYY")}</Date>
              </DateWrapper>
            ))}

          <LabelSection>
            <VideoTitle video={video} />

            {video.parent_id && (
              <FullVideo>
                <Link
                  to={`${API.video}/${video.parent_id}?user_id=${user.id}&code=${user.security_string}`}
                >
                  See the full video
                </Link>
              </FullVideo>
            )}

            <Labels
              setCheckedLabels={setCheckedLabels}
              checkedLabels={checkedLabels}
              items={labels}
              id={video.id}
              isMainVideo
            />
            {sortMarkers.map((marker) => {
              return <MarkerItem marker={marker} key={uuid()} />;
            })}
          </LabelSection>
        </>
      )}
    </MainVideoInfoWrapper>
  );
};
