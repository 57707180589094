import { css } from "styled-components";

export const fontsCSS = css`
  @font-face {
    font-family: "Inter Thin";
    src: local("Inter Thin"),
      url(${require("../fonts/inter/Inter-Thin.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter SemiBold";
    src: local("Inter SemiBold"),
      url(${require("../fonts/inter/Inter-SemiBold.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter Regular";
    src: local("Inter Regular"),
      url(${require("../fonts/inter/Inter-Regular.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter Medium";
    src: local("Inter Medium"),
      url(${require("../fonts/inter/Inter-Medium.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter Light";
    src: local("Inter Light"),
      url(${require("../fonts/inter/Inter-Light.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter ExtraLight";
    src: local("Inter ExtraLight"),
      url(${require("../fonts/inter/Inter-ExtraLight.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter ExtraBold";
    src: local("Inter ExtraBold"),
      url(${require("../fonts/inter/Inter-ExtraBold.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter Bold";
    src: local("Inter Bold"),
      url(${require("../fonts/inter/Inter-Bold.ttf")}) format("truetype");
  }

  @font-face {
    font-family: "Inter Black";
    src: local("Inter Black"),
      url(${require("../fonts/inter/Inter-Black.ttf")}) format("truetype");
  }
`;
