import { useEffect, useState } from "react";

export const MouseHoverProgress = ({ screenSize }) => {
  const [clientCoords, setClientCoords] = useState(0);

  const handleWindowMouseMove = (event) => {
    setClientCoords(event.clientX);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleWindowMouseMove);

    return () => window.removeEventListener("mousemove", handleWindowMouseMove);
  }, []);

  const mouseHover = clientCoords - screenSize;

  return (
    <span
      style={{
        width: mouseHover + "px",
      }}
      className="mouse-hover-progress"
    />
  );
};
