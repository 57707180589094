import * as styles from "assets/styles/globalStyles.js";
import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import { SCREEN_SIZES } from "utils/constants";

export const ModalContainer = styled.div`
  position: fixed;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.55);
`;

export const Modal = styled.div`
  position: relative;
  ${styles.FlexColumn};
  justify-content: center;
  background: ${colors.white};
  max-width: 360px;
  width: 100%;
  padding: 69px 51px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -4px 4px 6px 3px rgb(0 0 0 / 15%);
  border-radius: 5px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 300px;
    padding: 49px 26px;
  }
`;

export const ConfirmModal = styled.div`
  position: relative;
  ${styles.FlexColumn};
  justify-content: center;
  background: ${colors.white};
  width: 300px;
  padding: 55px 26px 31px 26px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -4px 4px 6px 3px rgb(0 0 0 / 15%);
  border-radius: 5px;
`;

export const NoteContainer = styled.div`
  ${styles.FlexCenter};
  ${styles.FontMedium};
  font-size: 14px;
  line-height: 21px;
  color: ${colors.lightBlack};
  align-items: center;
  margin-bottom: 26px;
  width: 100%;

  span {
    ${styles.FontBold};
  }
`;

export const QuestionContainer = styled.div`
  ${styles.FlexColumn};
  ${styles.FontBold};
  font-size: 16px;
  line-height: 150%;
  color: ${colors.lightBlack};
  align-items: center;
  margin-bottom: 38px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-bottom: 32px;
  }
`;

export const ButtonsContainer = styled.div`
  ${styles.FlexRow};
  width: 100%;
  justify-content: space-between;
`;
