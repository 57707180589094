import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { SCREEN_SIZES } from "utils/constants";

export const NotFoundWrapper = styled.div`
  ${styles.FlexCenter};
  width: 100%;
  height: 100vh;
`;

export const Container = styled.main`
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  height: 100px;
  padding: 0 52px;
  & div {
    max-width: 1438px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & img,
  .lottie {
    height: 54px;
    width: 196px;
    max-width: unset !important;
    object-fit: cover;
  }

  @media (max-width: ${SCREEN_SIZES.md}) {
    height: 66px;
    & img,
    .lottie {
      width: 147px;
      height: 43px;
    }
  }
  @media (max-width: ${SCREEN_SIZES.xs}) {
    padding: 0 22px;
  }
`;

export const NotFoundContent = styled.div`
  display: flex;
  padding-top: 168px;
  justify-content: center;
  @media (max-width: ${SCREEN_SIZES.md}) {
    padding-top: 134px;
  }

  @media (max-width: ${SCREEN_SIZES.xs}) {
    padding-top: 63px;
  }
`;

export const NotFoundContentWrapper = styled.div`
  max-width: 854px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SCREEN_SIZES.md}) {
    max-width: 440px;
  }

  @media (max-width: ${SCREEN_SIZES.xs}) {
    max-width: 331px;
  }
`;

export const NotFoundTitle = styled.p`
  font-size: 48px;
  line-height: 72px;
  margin-bottom: 18px;
  ${styles.FontBold};

  @media (max-width: ${SCREEN_SIZES.md}) {
    line-height: 58px;
    margin-bottom: 46px;
  }

  @media (max-width: ${SCREEN_SIZES.xs}) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 46px;
  }
`;

export const NotFoundText = styled.p`
  font-size: 20px;
  margin-bottom: 25px;
  line-height: 30px;
  ${styles.FontRegular};
  @media (max-width: ${SCREEN_SIZES.xs}) {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 24px;
  }
`;
