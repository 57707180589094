import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { colors } from "assets/styles/colors";

export const ToastMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: visibility 1s;
  visibility: visible;
`;

export const MessageContainer = styled.div`
  max-width: 500px;
  width: fit-content;
  padding: 13px 25px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0 0 3px 3px;
`;

export const Message = styled.div`
  ${styles.FontMedium};
  color: ${colors.white};
  text-align: center;
  font-size: 14px;
  line-height: 21px;
`;
