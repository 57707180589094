import { isMobile, useMobileOrientation } from "react-device-detect";
import { Images } from "assets/images";
import {
  removeActiveMarker,
  removeMobileActiveMarker,
  scrollTopFunction,
  validDateTextFormat,
} from "utils/heplers";
import { Link } from "react-router-dom";
import { API } from "configs/api";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVideoMarker,
  setActiveMarker,
  setEditeMarkerMode,
  updateVideoMarker,
} from "store";
import { useState } from "react";
import { Enter, MARKERS_CLASS } from "utils/constants";
import videojs from "video.js";
import {
  StyledHighlightPlayIcon,
  StyledMarkersContainer,
  StyledMarkerTimeText,
  StyledMarkerTitleText,
  StyledWrapper,
  StyledInput,
  StyledEditMarkerContainer,
} from "./styled";

export const MarkerItem = ({ marker }) => {
  const { user, playerRef, activeMarker, editeMarkerMode } = useSelector(
    (state) => state.video
  );

  const [markerText, setMarkerText] = useState(marker.text);

  const { isLandscape } = useMobileOrientation();
  const dispatch = useDispatch();

  const scrollTopDependantOrientation = () => {
    if (isLandscape) {
      scrollTopFunction(95);
    } else {
      scrollTopFunction(0);
    }
  };

  const addMobileActiveClass = (marker) => {
    const player = videojs("video");

    let markerElement = player
      .el()
      .querySelector(".vjs-marker[data-marker-time='" + marker.time + "']");
    markerElement.className += " " + MARKERS_CLASS.mobileActive;
  };

  const handlerMarker = (e, marker) => {
    if (playerRef) {
      if (marker.id === activeMarker) {
        playerRef.pause();
        dispatch(setActiveMarker(null));
        return;
      }

      playerRef.currentTime(marker.time);
      playerRef.play();

      if (isMobile) {
        removeMobileActiveMarker();
        addMobileActiveClass(marker);
        dispatch(setActiveMarker(marker.id));
        dispatch(setEditeMarkerMode(null));

        if (isLandscape) {
          setTimeout(() => {
            scrollTopFunction(95);
          }, 300);
        }
      }
    }
  };

  const openEditMarkerMode = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (playerRef) {
      playerRef.pause();
      dispatch(setActiveMarker(null));
      dispatch(setEditeMarkerMode(marker.id));
      removeMobileActiveMarker();
      addMobileActiveClass(marker);
    }
  };

  const editMarkerHandler = () => {
    setTimeout(() => {
      let input = document.querySelector(".edit-marker-input input");
      const currentMarkerText = input?.value;

      if (currentMarkerText.trim() !== marker.text) {
        dispatch(
          updateVideoMarker({
            id: marker.id,
            text:
              currentMarkerText.length > 0
                ? currentMarkerText
                : "Untitled marker",
          })
        );
      }
      dispatch(setEditeMarkerMode(null));
      removeActiveMarker(4000);
      scrollTopDependantOrientation();
    });
  };

  const onKeyDownHandler = (e) => {
    if (e.code === Enter) {
      editMarkerHandler();
    } else {
      e.stopPropagation();
    }
  };

  const deleteMarkerHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(deleteVideoMarker({ id: marker.id }));
    dispatch(setEditeMarkerMode(null));
    scrollTopDependantOrientation();
  };

  const renderMarkerIcon = (marker, isEditMarkerMode) => {
    if (isMobile && !isEditMarkerMode) {
      if (activeMarker === marker.id) {
        return marker.is_highlight ? (
          <img src={Images.PauseYellowIcon} alt="play" />
        ) : (
          <img src={Images.PauseBlueIcon} alt="play" />
        );
      }

      return marker.is_highlight ? (
        <img src={Images.PlayYellowIcon} alt="play" />
      ) : (
        <img src={Images.PlayBlueIcon} alt="play" />
      );
    } else {
      return marker.is_highlight ? (
        <img src={Images.MarkerIconHighlight} alt="marker" />
      ) : (
        <img src={Images.MarkerIcon} alt="marker" />
      );
    }
  };

  function handleResize(event) {
    if (window.innerHeight <= window.visualViewport.height) {
      editMarkerHandler();
      window.visualViewport.removeEventListener("resize", handleResize);
    } else {
      let input = document.querySelector(".edit-marker-input");
      const rect = input.getBoundingClientRect();
      const heightFromTop = rect.top + window.pageYOffset;
      // let inputHeight = input.offsetTop;
      let viewportHeight = event.target.height;
      let targetPosition =
        heightFromTop + input.offsetHeight - viewportHeight + 10;

      scrollTopFunction(Math.abs(targetPosition), 0, "auto");
    }
  }

  const onFocusEditInput = () => {
    window.visualViewport.addEventListener("resize", handleResize);
  };

  return (
    <StyledWrapper
      key={`marker-${marker.id}`}
      className={isMobile ? "is-mobile-markers" : ""}
    >
      <StyledMarkersContainer
        onClick={(e) => handlerMarker(e, marker)}
        isHighlight={marker.is_highlight}
        className={
          isMobile && activeMarker !== marker.id && activeMarker !== null
            ? "styled-marker-container opacity_55"
            : "styled-marker-container"
        }
      >
        {renderMarkerIcon(marker, editeMarkerMode)}

        <StyledMarkerTimeText isHighlight={marker.is_highlight}>
          {validDateTextFormat(marker.time)}
        </StyledMarkerTimeText>

        {isMobile && editeMarkerMode === marker.id ? (
          <StyledEditMarkerContainer>
            <StyledInput
              onChange={(e) => setMarkerText(e.target.value)}
              onKeyDown={onKeyDownHandler}
              onFocus={onFocusEditInput}
              value={markerText}
              autoFocus={true}
              placeholder="Add marker description (optional)"
              variant="outlined"
              className="edit-marker-input"
            />
            <img
              src={Images.DeleteWhiteIcon}
              alt="delete"
              onClick={deleteMarkerHandler}
              onTouchEnd={deleteMarkerHandler}
            />
          </StyledEditMarkerContainer>
        ) : (
          <StyledMarkerTitleText
            className="styled-marker-text"
            onClick={(e) => (isMobile ? openEditMarkerMode(e) : null)}
          >
            {marker.text}
          </StyledMarkerTitleText>
        )}
      </StyledMarkersContainer>

      {marker.is_highlight ? (
        <Link
          className="is-highlight-marker"
          onClick={scrollTopDependantOrientation}
          to={`${API.video}/${marker.video_highlight_id}?user_id=${user.id}&code=${user.security_string}`}
        >
          <StyledHighlightPlayIcon>
            <img src={Images.ClipPlayIcon} alt="" />
          </StyledHighlightPlayIcon>
        </Link>
      ) : null}
    </StyledWrapper>
  );
};
