import videojs from "video.js";
import { Images } from "assets/images";
import { API } from "configs/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setVideoCurrentResolution,
  setVideoCurrentTime,
  setScrollPosition,
} from "store";
import {
  CameraThumb,
  MultiCamItem,
  StyledCameraImage,
  StyledImageContainer,
  StyledVideoNumberContainer,
  StyledVideoNumbers,
  StyledVideosNumberSection,
  ThumbImg,
  ThumbName,
} from "./styled";

const VideoCameras = ({ setIsEllipsesMenu }) => {
  const { videoCameras, video, user } = useSelector((state) => state.video);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const keepCurrentTime = (camera) => {
    const player = videojs("video");
    const resolution = player
      .el()
      .querySelectorAll(".quality-span")[0].innerText;

    dispatch(setVideoCurrentTime(player.currentTime()));
    dispatch(setVideoCurrentResolution(resolution));
    dispatch(setScrollPosition(window.scrollY));
    navigate(
      `${API.video}/${camera.id}?user_id=${user.id}&code=${user.security_string}`
    );
  };

  const videos = videoCameras?.map((camera, index) => {
    return (
      <div
        onClick={() => keepCurrentTime(camera)}
        key={`cameras-${index}`}
        className={
          video.id === camera.id
            ? "video-cameras-item active-number"
            : "video-cameras-item"
        }
      >
        <MultiCamItem>
          <StyledVideoNumbers
            active={video.id === camera.id}
            className="video-cameras-number"
          >
            {index + 1}
          </StyledVideoNumbers>
          <CameraThumb className="thumb-item">
            <ThumbName>{camera.camera_name ?? "N/A"}</ThumbName>
            <ThumbImg
              src={
                camera.thumbnail_link ??
                "https://i.vimeocdn.com/video/984008196_295x166.jpg"
              }
            />
          </CameraThumb>
        </MultiCamItem>
      </div>
    );
  });

  return (
    <StyledVideoNumberContainer className="video-cameras">
      <StyledImageContainer
        onClick={() => setIsEllipsesMenu(true)}
        src={Images.VideoBurger}
        alt=""
        className="hot-key-button"
      />

      {videoCameras.length > 1 ? (
        <StyledVideosNumberSection className="cameras-container">
          <StyledCameraImage className="cameras-icon-container">
            <img src={Images.CameraIcon} alt="" />
          </StyledCameraImage>
          <div className="numbers-container">{videos}</div>
        </StyledVideosNumberSection>
      ) : null}
    </StyledVideoNumberContainer>
  );
};
export default VideoCameras;
