import { createGlobalStyle } from "styled-components/macro";
import { fontsCSS } from "./fontsCSS";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }

  ${fontsCSS}
`;

// FONTS

export const FontLight = {
  fontFamily: "Inter Light",
  fontWeight: "300",
  fontStyle: "normal",
};

export const FontRegular = {
  fontFamily: "Inter Regular",
  fontWeight: "400",
  fontStyle: "normal",
};

export const FontMedium = {
  fontFamily: "Inter Medium",
  fontWeight: "500",
  fontStyle: "normal",
};

export const FontSemiBold = {
  fontFamily: "Inter SemiBold",
  fontWeight: "600",
  fontStyle: "normal",
};

export const FontBold = {
  fontFamily: "Inter Bold",
  fontWeight: "700",
  fontStyle: "normal",
};

export const FontExtraBold = {
  fontFamily: "Inter ExtraBold",
  fontWeight: "800",
  fontStyle: "normal",
};

export const FontBlack = {
  fontFamily: "Inter Black",
  fontWeight: "900",
  fontStyle: "normal",
};

// FLEX

export const FlexColumn = {
  display: "flex",
  flexDirection: "column",
};

export const FlexRow = {
  display: "flex",
  flexDirection: "row",
};

export const FlexRowCenter = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

export const FlexCenter = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const FlexSpaceBetween = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "spaceBetween",
  alignItems: "center",
};

// UNSET
export const unsetSideColors = {
  boxShadow: "none",
  border: "unset",
  outline: "unset",
};
