import "videojs-seek-buttons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import VideoPlayer from "./components/VideoPlayer";
import { generateVideoSrc } from "utils/heplers";
import { isMobile } from "react-device-detect";

export const Video = () => {
  const { video, videoCurrentResolution, videoCurrentTime } = useSelector(
    (state) => state.video
  );

  const [videoJsOptions, setVideoJsOptions] = useState(null);
  const playerRef = useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      player.log("player is waiting");
    });

    player.on("dispose", () => {
      player.log("player will dispose");
    });
  };

  useEffect(() => {
    if (video && Object.keys(video).length !== 0) {
      const videoSrc = [];

      if (videoCurrentResolution && videoCurrentTime) {
        switch (videoCurrentResolution) {
          case "Auto":
            if (video.url_hls) {
              generateVideoSrc(
                videoSrc,
                video.url_hls,
                null,
                "application/x-mpegURL"
              );
            }
            break;
          default:
            generateVideoSrc(
              videoSrc,
              video["url_" + videoCurrentResolution],
              videoCurrentResolution
            );
            break;
        }
      } else {
        if (video.url_hls) {
          generateVideoSrc(
            videoSrc,
            video.url_hls,
            null,
            "application/x-mpegURL"
          );
        } else {
          if (video.url_1080p) {
            generateVideoSrc(videoSrc, video.url_1080p, "1080p");
          } else if (video.url_720p) {
            generateVideoSrc(videoSrc, video.url_720p, "720p");
          }

          if (video.url_360p) {
            generateVideoSrc(videoSrc, video.url_360p, "360p");
          }
        }
      }

      // just in case
      if (!videoSrc.length) {
        generateVideoSrc(
          videoSrc,
          video.url_hls,
          null,
          "application/x-mpegURL"
        );
      }

      setVideoJsOptions({
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        playbackRates: [0.25, 0.5, 1, 2, 3],
        width: 720,
        height: 300,
        inactivityTimeout: isMobile ? false : 4000,
        errorDisplay: false,
        html5: {
          vhs: {
            overrideNative: true,
            // nativeControlsForTouch: true,
          },
        },
        plugins: {
          seekButtons: {
            forward: 15,
            back: 15,
          },
        },
        sources: videoSrc,
        poster: video["thumbnail_link"],
        keydown: true,
      });
    }
  }, [video.url_hls, video.updated_at]);

  return (
    videoJsOptions !== null && (
      <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
    )
  );
};
