import { Images } from "assets/images";
import { formatBytes } from "utils/heplers";
import { DownloadSection, StyledSizeTex } from "./styled";
import { useDispatch } from "react-redux";
import { downloadVideo } from "store";
import { ANALYTIC_TYPES, CLUB_NAME } from "utils/constants";
import { useAnalytics } from "hooks";

export const Download = ({
  video,
  underVideoSection = false,
  isMobile = true,
  isSaveModal,
  fs = "12px",
}) => {
  const dispatch = useDispatch();
  const analyticActions = useAnalytics();

  const download = () => {
    if (video.url_dropbox) {
      window.open(video.url_dropbox.replace(/['"]+/g, ""), "_blank");
      analyticActions.update(ANALYTIC_TYPES.download_count);
    } else {
      dispatch(
        downloadVideo({
          url: video?.download_link?.replace(/['"]+/g, ""),
          name: CLUB_NAME,
        })
      );
    }
  };

  return (
    <DownloadSection
      isSaveModal={isSaveModal}
      fs={fs}
      onClick={download}
      underVideoSection={underVideoSection}
      className="download-section"
    >
      <img src={Images.DownloadIcon} alt="" />

      {isMobile && <span>Download</span>}

      {isSaveModal && (
        <StyledSizeTex>{formatBytes(video.download_size)}</StyledSizeTex>
      )}
    </DownloadSection>
  );
};
