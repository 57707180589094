import styled from "styled-components/macro";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import ReactCanvasConfetti from "react-canvas-confetti";
import { isMobile } from "react-device-detect";
import { SCREEN_SIZES } from "utils/constants";

export const StyledModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  height: 500px;
  border-radius: 5px;
  background-color: ${colors.white};

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    width: 500px;
    height: 360px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 460px;
    height: 360px;
  }
`;

export const SomethingSpan = styled.span`
  position: absolute;
  bottom: 0;
  z-index: 50;
  width: 1px;
  height: 10px;
  background-color: ${colors.primary};
  content: "";
  transition: transform 0.4s linear;
  display: inline-block;
`;

export const StyledImageContainer = styled.div`
  background-position: center;
  height: 180px;
  margin-top: 30px;
  border-radius: 5px;
  overflow: hidden;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-top: 17px;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  width: 100%;
`;

export const StyledDivLine = styled.div`
  display: block;
  margin-left: -2px;
  transition: transform 0.4s linear;
`;

export const ProgressBar = styled(LinearProgress)`
  width: 240px;
  height: 10px !important;
  background: ${colors.blue} !important;
  border-radius: 5px;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${colors.primary};
  }
`;

export const FullWidthBlock = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledModalTitle = styled.div`
  font-size: 16px;
  line-height: 150%;
  ${styles.FontBold};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: ${({ isClipReady }) => (isClipReady ? "28px" : "79px")};
  margin-bottom: 86px;
  text-align: center;
  margin-top: 78px;
  margin-bottom: 58px;
`;

export const TextProgressColor = styled.span`
  color: ${colors.primary};
  position: absolute;
  bottom: 10px;
  ${styles.FontMedium};
  margin-left: -8px;
  font-size: 12px;
`;

export const SimpleLineThrough = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const SuccessBarContainer = styled(Box)`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 360px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  background-color: ${colors.white};

  .span-success-modal {
    font-size: 16px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 460px;
  }
`;

export const CanvasConfetti = styled(ReactCanvasConfetti)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const EqualBlock = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  padding-left: 30px;

  ${isMobile} && {
    &:hover {
      background-color: ${colors.lightBlue};
    }
    &:hover img {
      background: none;
    }
  }
`;

export const DownloadCopyBlock = styled.div`
  ${styles.FlexColumn}
  width: 300px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: auto;
`;

export const ReadyCopyDownload = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;

  img {
    cursor: pointer;
  }
`;

export const FullBigClass = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    ${styles.FontBold};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`;

export const StyledEditModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 194px;
  border-radius: 5px;
  background-color: ${colors.white};
`;

export const StyledEditText = styled.div`
  ${styles.FontBold};
  font-size: 16px;
  line-height: 150%;
  padding-bottom: 17px;
`;

export const StyledTextContainer = styled.div`
  width: max-content;
  margin: auto;
  padding-top: 66px;
  text-align: center;
`;

export const StyledOpacityContainer = styled.div`
  transition: 1.5s;
  opacity: ${({ isTransitionModal }) => (isTransitionModal ? 1 : 0)};
`;
