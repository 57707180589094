import { createAsyncThunk } from "@reduxjs/toolkit";
import { videoApi } from "./api";
import { downloadBigFile } from "utils/heplers";

export const getVideo = createAsyncThunk(
  "video/getVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.getVideo(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFilterLabels = createAsyncThunk(
  "video/getFilterLabel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.getFilterLabels(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getRandomGif = createAsyncThunk(
  "video/getRandomGif",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.getRandomGif();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const downloadVideo = createAsyncThunk(
  "video/downloadVideo",
  async (data, { rejectWithValue }) => {
    try {
      await downloadBigFile(data.url.replace(/['"]+/g, ""));
    } catch (error) {
      console.log("Download video error message: ", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoIsFavorite = createAsyncThunk(
  "video/isFavorite",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.updateVideoFavorite(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoLabels = createAsyncThunk(
  "video/updateVideoLabels",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.updateLabels(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addVideoMarker = createAsyncThunk(
  "video/addVideoMarker",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.addMarker(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteVideoMarker = createAsyncThunk(
  "video/deleteVideoMarker",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.deleteMarker(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoMarker = createAsyncThunk(
  "video/updateVideoMarker",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.updateMarker(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const makeClipPreview = createAsyncThunk(
  "video/makeClipPreview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.makeClipPreview(data);
      return response.data;
    } catch {
      return rejectWithValue(error.message);
    }
  }
);

export const addVideo = createAsyncThunk(
  "video/addVideo",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoApi.postVideo(data);
      return response.data;
    } catch {
      return rejectWithValue(error.message);
    }
  }
);

export const editClip = createAsyncThunk(
  "video/editClip",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.updateVideo(data.saveVideo);
      const res = await videoApi.getVideo(data.userParams);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoTitle = createAsyncThunk(
  "video/updateVideoTitle",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.updateVideo(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteClip = createAsyncThunk(
  "video/deleteClip",
  async (data, { rejectWithValue }) => {
    try {
      await videoApi.deleteClip(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAnalyticId = createAsyncThunk(
  "video/getAnalyticId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await videoApi.getAnalyticId(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateAnalytic = createAsyncThunk(
  "video/updateAnalytic",
  async (data, { rejectWithValue }) => {
    try {
      const res = await videoApi.updateAnalytic(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
