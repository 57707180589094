import { Images } from "assets/images";
import { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SimpleButton } from "components";
import { getVideoList } from "store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { colors } from "assets/styles/colors";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  allFiltersFormatString,
  capitalizeFirstLetter,
  formatDateFilter,
  getDaysArray,
  parameterizeArray,
} from "utils/heplers";
import {
  ArrowImg,
  FilterIcon,
  FilterIconContainer,
  FilterWrapper,
  LabelContainer,
  Label,
  DatePickerWrapper,
  LabelsRow,
  SampleNextArrow,
  SamplePrevArrow,
  DatePickerInput,
  StyledDivOpacity,
} from "./styled";

export const Filter = ({
  openFilter,
  setOpenFilter,
  setCheckedLabels,
  checkedLabels,
}) => {
  const { filterLabels, user, fromFilterDate } = useSelector(
    (state) => state.video
  );

  const { getVideoListStatus } = useSelector((state) => state.videoList);
  const [startDate, setStartDate] = useState(new Date(fromFilterDate));
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();

  const id = useId();

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 5,
    variableWidth: true,
    nextArrow: <SampleNextArrow src={Images.DownArrow} />,
    prevArrow: <SamplePrevArrow src={Images.DownArrow} />,
  };

  const checkLabel = (name) => {
    setCheckedLabels([...checkedLabels, name]);

    const newArray = checkedLabels.filter((i) => i !== name);

    if (newArray.length < checkedLabels.length) {
      setCheckedLabels(newArray);
    }
  };

  const applyFilter = () => {
    if (Object.keys(user).length > 0) {
      let filtersEncodeURI = allFiltersFormatString({
        labels: parameterizeArray("labels", checkedLabels),
        startDate: formatDateFilter(startDate),
        endDate: formatDateFilter(endDate),
      });

      localStorage.setItem("filters", filtersEncodeURI);

      const queryParams = {
        userId: user.id,
        code: user.security_string,
        activeTab: localStorage.getItem("activeTab"),
        filters: filtersEncodeURI,
      };

      dispatch(getVideoList(queryParams));
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("filters")) {
      setCheckedLabels([]);
      setStartDate(new Date(fromFilterDate));
      setEndDate(new Date());
    }
  }, [fromFilterDate, getVideoListStatus]);

  useEffect(() => {
    applyFilter();
  }, [checkedLabels.length]);

  return (
    <FilterWrapper openFilter={openFilter}>
      <FilterIconContainer>
        <FilterIcon onClick={() => setOpenFilter((prevState) => !prevState)}>
          <img src={Images.Filter} alt="" />
          <span>Filter</span>
          <ArrowImg src={Images.DownArrow} alt="" openFilter={openFilter} />
        </FilterIcon>
      </FilterIconContainer>

      {openFilter && (
        <>
          <LabelContainer>
            <p>Labels</p>

            <LabelsRow>
              <Slider {...settings}>
                {filterLabels.length &&
                  filterLabels.map(
                    (label, index) =>
                      label.name.trim() && (
                        <Label
                          key={`${id}-${index}`}
                          onClick={() => checkLabel(label.name)}
                          checked={checkedLabels?.find((e) => e === label.name)}
                        >
                          {capitalizeFirstLetter(label.name)}
                        </Label>
                      )
                  )}
              </Slider>
              <StyledDivOpacity />
            </LabelsRow>
          </LabelContainer>

          <DatePickerWrapper>
            <DatePickerInput>
              <span>Start date</span>

              <DatePicker
                includeDates={getDaysArray(
                  new Date(fromFilterDate),
                  new Date()
                )}
                onFocus={(e) => e.target.blur()}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </DatePickerInput>

            <DatePickerInput>
              <span>End date</span>

              <DatePicker
                includeDates={getDaysArray(
                  new Date(fromFilterDate),
                  new Date()
                )}
                onFocus={(e) => e.target.blur()}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </DatePickerInput>
          </DatePickerWrapper>

          <SimpleButton
            isFilter
            text="Apply"
            event={applyFilter}
            style={{ background: colors.primary, color: colors.white }}
          />
        </>
      )}
    </FilterWrapper>
  );
};
