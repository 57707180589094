import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const LabelsWrapper = styled.div`
  ${styles.FlexRow};
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 8px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 21px;
    margin-bottom: 6px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-top: 21px;
      margin-bottom: 7px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-top: 20px;
    margin-bottom: 6px;
  }
`;

export const LabelItem = styled.span`
  padding: 4px 10px;
  background: ${colors.grey};
  text-transform: capitalize;
  color: ${colors.black};
  margin-right: 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  ${styles.FontMedium}
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  background: ${({ selected }) => (selected ? colors.black : colors.grey)};
  color: ${({ selected }) => (selected ? colors.white : colors.black)};
  cursor: pointer;

  ${isMobile} && {
    &:hover {
      background: rgba(0, 0, 0, 0.6);
      color: ${colors.white};
    }
  }
`;

export const EditLabelContainer = styled.div`
  overflow: auto;
  img {
    cursor: pointer;
  }
`;

export const EditLabelIcon = styled.img`
  width: 14px;
  height: 23px;
  opacity: 0.85;
  cursor: pointer;

  ${isMobile} && {
    &:hover {
      opacity: 1;
    }
  }
`;

export const ButtonContainer = styled.div`
  ${styles.FlexRow};
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
`;

export const Error = styled.div`
  ${styles.FontMedium};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${colors.lightRed};
  font-size: 14px;
  width: 100%;
  margin: 14px 0;
`;

export const LabelInputContainer = styled.div`
  ${styles.FlexRow};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;

  input {
    height: 40px;
    width: 100%;
    padding: 0 20px;
    margin-right: 20px;
    border: unset;
    border-radius: 3px;
    color: ${colors.navyBlue};
    background: ${colors.lightGrey};
    font-size: 16px;
    line-height: 16px;
    transition: 0.3s;

    :hover,
    :focus,
    :active {
      box-shadow: 0 2px 6px rgba(18, 18, 18, 0.75);
    }
  }

  img {
    width: 20px;
    height: 18px;
    opacity: 0.75;

    :hover {
      opacity: 1;
    }
  }
`;
