import { EditTitleIcon, EditTitleInput, Title, TitleContainer } from "./styled";
import { Images } from "assets/images";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditedTitleData, updateVideoTitle } from "store";
import { v4 as uuid } from "uuid";

export const VideoTitle = ({ video }) => {
  const { videoTitleData } = useSelector((state) => state.video);

  const inputRef = useRef(null);
  const titleRef = useRef(null);
  const dispatch = useDispatch();

  function saveClipTitle(e) {
    if (e.target.tagName.toUpperCase() === "INPUT") return;

    if (videoTitleData.id !== video.id) return;

    dispatch(
      updateVideoTitle({
        title: videoTitleData.name,
        video_id: video.id,
      })
    );
  }

  function editTitleHandler(e) {
    stopParentPropagation(e);
    titleRef.current.key = uuid();
    dispatch(
      setEditedTitleData({
        id: video.id,
        name: video.title,
        key: titleRef.current.key,
      })
    );
  }

  const onChange = (e) => {
    dispatch(setEditedTitleData({ id: video.id, name: e.target.value }));
  };

  function stopParentPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  useEffect(() => {
    if (videoTitleData.id) {
      if (titleRef.current.key === videoTitleData.key) {
        inputRef.current.focus();
      }
    }
  }, [videoTitleData.id]);

  useEffect(() => {
    if (videoTitleData.id) {
      document.addEventListener("mousedown", saveClipTitle);
      document.addEventListener("touchstart", saveClipTitle);
    }

    return () => {
      document.removeEventListener("mousedown", saveClipTitle);
      document.removeEventListener("touchstart", saveClipTitle);
    };
  }, [videoTitleData]);

  return (
    <TitleContainer ref={titleRef} className="title-container">
      {video.id === videoTitleData.id ? (
        <EditTitleInput
          ref={inputRef}
          onClick={stopParentPropagation}
          onChange={onChange}
          onKeyDown={(e) => e.stopPropagation()}
          value={videoTitleData.name}
        />
      ) : (
        <>
          <Title>{video.title}</Title>
          {video?.parent_id ? (
            <EditTitleIcon
              src={Images.EditLabel}
              alt=""
              onClick={editTitleHandler}
            />
          ) : null}
        </>
      )}
    </TitleContainer>
  );
};
