import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles.js";
import { SCREEN_SIZES } from "utils/constants";

export const SpanText = styled.span`
  position: absolute;
  color: white;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  cursor: auto;
  ${styles.FontMedium};

  span {
    font-size: 20px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }

    @keyframes blink {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 14px;
  }
`;
