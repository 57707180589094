import { Images } from "assets/images";
import { useMediaQuery } from "@mui/material";
import { SCREEN_SIZES } from "utils/constants";
import {
  StyledAddMarkerContainer,
  StyledAddMarkerText,
  StyledImageContainer,
  AddMarkerIcon,
} from "./styled";

const AddMarker = ({ handleAddMarker }) => {
  const isMobileSx = useMediaQuery(`(max-width:${SCREEN_SIZES.sm})`);

  return (
    <StyledAddMarkerContainer
      onClick={() => handleAddMarker(false)}
      className="add-marker"
    >
      <StyledImageContainer className="add-marker-img">
        {isMobileSx ? (
          <AddMarkerIcon src={Images.AddMarkerMobile} />
        ) : (
          <AddMarkerIcon src={Images.AddMarker} />
        )}
      </StyledImageContainer>

      <StyledAddMarkerText className="add-marker-text">
        Add marker
      </StyledAddMarkerText>
    </StyledAddMarkerContainer>
  );
};

export default AddMarker;
