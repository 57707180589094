import styled, { css } from "styled-components";
import { colors } from "assets/styles/colors";

export const Container = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const SpinnerStyle = css`
  animation: rotate 2s linear infinite;

  & .path {
    stroke: ${colors.primary};
    stroke-linecap: round;
    animation: dash 2s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const SpinnerContainer = styled.div`
  ${Container};
  position: fixed;
  background: ${colors.white};
`;

export const SpinnerContainerSM = styled.div`
  ${Container};
  position: ${({ isLandscape }) => (isLandscape ? "inherit" : "absolute")};
  background: transparent;
`;

export const StyledSpinner = styled.svg`
  ${SpinnerStyle};
  width: 150px;
  height: 150px;
`;

export const StyledSpinnerSM = styled.svg`
  ${SpinnerStyle};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
