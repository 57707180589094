import { useMediaQuery } from "@mui/material";
import { Images } from "assets/images";
import { SCREEN_SIZES } from "utils/constants";
import {
  Container,
  StyledCloseIconContainer,
  StyledEllipsesMenuContainer,
  StyledImage,
  StyledOptions,
  StyledOptionsContainer,
} from "./styled";

const EllipsesMenu = ({ isEllipsesMenu, setIsEllipsesMenu }) => {
  const isMobileSx = useMediaQuery(`(max-width:${SCREEN_SIZES.sm})`);

  return (
    <>
      {isEllipsesMenu && (
        <Container>
          <StyledEllipsesMenuContainer>
            <StyledCloseIconContainer
              src={Images.CloseIcon}
              onClick={() => setIsEllipsesMenu(false)}
            />

            <StyledOptionsContainer spacing="43px">
              <StyledOptions>
                <StyledImage>
                  {isMobileSx ? (
                    <img src={Images.LeftAndRightArrowsMobile} alt="keyDown" />
                  ) : (
                    <img src={Images.LeftAndRightArrows} alt="keyDown" />
                  )}
                </StyledImage>
                <p>Left & right arrows to skip 15s</p>
              </StyledOptions>

              <StyledOptions>
                <StyledImage>
                  {isMobileSx ? (
                    <img src={Images.JAngKIconsMobile} alt="keyDown" />
                  ) : (
                    <img src={Images.JAngKIcons} alt="keyDown" />
                  )}
                </StyledImage>
                <p>J and K to step frame-by-frame</p>
              </StyledOptions>

              <StyledOptions>
                <StyledImage>
                  {isMobileSx ? (
                    <img src={Images.MIconMobile} alt="keyDown" />
                  ) : (
                    <img src={Images.MIcon} alt="keyDown" />
                  )}
                </StyledImage>
                <p>M to add a marker</p>
              </StyledOptions>

              <StyledOptions>
                <StyledImage>
                  {isMobileSx ? (
                    <img src={Images.ZoomIconMobile} alt="keyDown" />
                  ) : (
                    <img src={Images.ZoomIcon} alt="keyDown" />
                  )}
                </StyledImage>
                <p>Pinch to zoom</p>
              </StyledOptions>

              <StyledOptions>
                <StyledImage>
                  {isMobileSx ? (
                    <img src={Images.SpaceIconMobile} alt="keyDown" />
                  ) : (
                    <img src={Images.SpaceIcon} alt="keyDown" />
                  )}
                </StyledImage>
                <p>Spacebar to play & pause</p>
              </StyledOptions>
            </StyledOptionsContainer>
          </StyledEllipsesMenuContainer>
        </Container>
      )}
    </>
  );
};
export default EllipsesMenu;
