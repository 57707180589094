import { Images } from "assets/images";
import { ConfirmDeleteModal } from "components";
import { useState } from "react";
import { DeleteSection } from "./styled";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteClip } from "store";
import { SUCCEEDED } from "utils/constants";

export const Delete = ({ fs = "12px", item, setDeletedVideoId }) => {
  const { deleteClipFromVideoListStatus } = useSelector((state) => state.video);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const dispatch = useDispatch();

  const deleteClipEvent = () => {
    setDeletedVideoId(item.id);
    dispatch(
      deleteClip({ id: item.id, type: "deleteClipFromVideoListStatus" })
    );
  };

  const deleteButtonHandler = () => {
    setOpenConfirmDeleteModal(true);
  };

  const closeConfirmDeleteModal = () => {
    setOpenConfirmDeleteModal(false);
  };

  useEffect(() => {
    if (deleteClipFromVideoListStatus === SUCCEEDED) {
      setOpenConfirmDeleteModal(false);
    }
  }, [deleteClipFromVideoListStatus]);

  return (
    <>
      <DeleteSection fs={fs} onClick={deleteButtonHandler}>
        <img src={Images.DeleteIcon} alt="" />
        Delete
      </DeleteSection>

      <ConfirmDeleteModal
        show={openConfirmDeleteModal}
        close={closeConfirmDeleteModal}
        text={"Delete this clip?"}
        event={deleteClipEvent}
      />
    </>
  );
};
