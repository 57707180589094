import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { colors } from "assets/styles/colors";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

const ActionsStyle = styled.div`
  ${styles.FlexRow};
  ${styles.FontSemiBold};
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.lightBlack};
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 4px;
    margin-left: 0px;

    img {
      margin-right: 1px;
      width: 13px;
    }
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin-left: 21px;
      margin-top: 2px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    img {
      margin-right: 4px;
      margin-top: -2px;
    }
  }
`;

export const UnderVideoSectionWrapper = styled.div`
  ${styles.FlexRow};
  justify-content: space-between;
  align-items: flex-start;
  margin: 18px 0 25px 1px;
  width: 100%;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin: 18px 0 25px 0;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 12px 0 23px 0;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      margin: 13px 0 20px 0;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin: 12px 0 21px 0;
  }
`;

export const UnderVideoActionWrapper = styled.div`
  ${styles.FlexRow};
  margin-top: 0px;
`;

export const UnderVideoAction = styled(ActionsStyle)`
  span {
    ${styles.FontSemiBold};
  }

  ${isMobile} && {
    &:hover {
      opacity: 1;
      color: ${colors.primary};

      img {
        filter: ${colors.svgPrimary};
      }
    }
  }
`;

export const IsFavoriteAction = styled(ActionsStyle)`
  color: ${colors.lightBlack};

  img {
    opacity: ${({ isFavorite }) => (isFavorite ? "1" : "0.75")};
    margin-right: 7px;
  }

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    img {
      margin-right: 0;
    }
  }

  span {
    opacity: 0.75;
    ${styles.FontSemiBold};
  }

  ${isMobile} && {
    &:hover {
      color: ${colors.primary};

      img {
        filter: ${colors.svgPrimary};
        opacity: 1;
      }

      span {
        opacity: 1;
      }
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: -3px;
`;

export const StyledLinkCopied = styled.div`
  ${styles.FontSemiBold};
  padding: 2px 8px;
  background: ${colors.primary};
  width: max-content;
  border-radius: 2px;
  color: ${colors.white};
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  top: 17px;
  right: 8px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 9px 0 0 16px;
  }
`;
