import { Images } from "assets/images";
import { useEffect, useState } from "react";
import {
  CopyLinkContainer,
  ImageCopyDownload,
  CopyLinkText,
  LinkCopiedContainer,
} from "./styled";
import { useAnalytics } from "hooks";
import { ANALYTIC_TYPES } from "utils/constants";

export const Copy = ({
  video,
  underVideoSection,
  isMobile = true,
  setIsCopied,
  isSaveModal,
  url,
  fs = "12px",
}) => {
  const [copied, setCopied] = useState(false);
  const analyticActions = useAnalytics();

  const copyLink = (url) => {
    let copyUrl = url;

    if (!copyUrl) {
      copyUrl = video.url_1080p ?? video.url_720p;
    }

    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyUrl);
    } else {
      const el = document.createElement("input");

      el.value = copyUrl;
      document.body.appendChild(el);
      el.select();

      document.execCommand("copy");

      navigator.clipboard
        .readText()
        .then(
          (clipText) =>
            (document.querySelector("#copy-text").innerText += clipText)
        );

      document.body.removeChild(el);
    }

    setCopied(true);

    analyticActions.update(ANALYTIC_TYPES.share_count);

    setTimeout(() => {
      setCopied(false);
    }, 30000);
  };

  useEffect(() => {
    if (underVideoSection) {
      setIsCopied(copied);
    }
  }, [copied]);

  useEffect(() => {
    setCopied(false);
  }, [video?.id]);

  return (
    <>
      {copied ? (
        <LinkCopiedContainer
          className="link-copied"
          isSaveModal={isSaveModal}
          underVideoSection={underVideoSection}
          fs={fs}
        >
          <ImageCopyDownload src={Images.LinkBlack} alt="" />

          {isMobile && (
            <CopyLinkText isSaveModal={isSaveModal}>Link copied</CopyLinkText>
          )}
        </LinkCopiedContainer>
      ) : (
        <CopyLinkContainer
          onClick={() => copyLink(url)}
          isSaveModal={isSaveModal}
          fs={fs}
          underVideoSection={underVideoSection}
        >
          <ImageCopyDownload src={Images.LinkBlack} alt="" />

          {isMobile && (
            <CopyLinkText isSaveModal={isSaveModal}>
              {isSaveModal ? "Copy link to share" : "Copy link"}
            </CopyLinkText>
          )}
        </CopyLinkContainer>
      )}
    </>
  );
};
