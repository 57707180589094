import { useEffect, useState } from "react";
import { Images } from "assets/images";
import { StyledProgressCircular } from "components/buttons/loadingButton";
import {
  CancelButton,
  MainBlockOfNotification,
  NotificationDescription,
  NotificationTitle,
  SuccessNotification,
} from "../styled";
import { Link } from "react-router-dom";

const SavedInfoBlock = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });

  return (
    <SuccessNotification>
      {loading ? (
        <StyledProgressCircular />
      ) : (
        <MainBlockOfNotification>
          <img src={Images.SuccessIcon} />
          <NotificationTitle>Saved</NotificationTitle>
          <NotificationDescription>
            You will receive an email notification when your video has been
            posted on the video library.
          </NotificationDescription>
          <Link to="/club/video/create">
            <CancelButton isNotification={true}>Back to upload</CancelButton>
          </Link>
        </MainBlockOfNotification>
      )}
    </SuccessNotification>
  );
};

export default SavedInfoBlock;
