import { useState, useRef } from "react";
import OpenAccountsBlock from "./addInfoComponents/openAccountsBlock";
import { useOnClickOutside } from "hooks";
import SavedInfoBlock from "./addInfoComponents/SavedInfoBlock";
import {
  WrapperContainer,
  VideoInfoBlock,
  AddInfoContainer,
  TitleSpan,
  InputBlocks,
  FullInput,
  InputWrapper,
  CancelSaveWrapper,
  EndButtons,
  CancelButton,
  SaveButton,
  ChoosingAccounts,
  VideoAndMainInfo,
  VideoBlock,
} from "./styled";

export const AddInfo = () => {
  const [saved, setSaved] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [checkNumber, setCheckNumber] = useState(0);
  const openAccountRef = useRef(null);
  const WrapperRef = useRef(null);

  useOnClickOutside(WrapperRef, () => setAccounts(false));

  const checkCount = (event) => {
    if (event.target.checked) {
      setCheckNumber(checkNumber + 1);
    } else {
      setCheckNumber(checkNumber - 1);
    }
  };

  const openAccounts = () => {
    setAccounts((prev) => !prev);
  };

  const infoSave = () => {
    setSaved(true);
  };

  return (
    <WrapperContainer isAddInfo>
      <VideoInfoBlock>
        <VideoAndMainInfo>
          <VideoBlock
            width="640"
            height="360"
            src="https://www.youtube.com/embed/Ff7FFseuFuY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          {saved === true ? (
            <SavedInfoBlock />
          ) : (
            <AddInfoContainer>
              <InputWrapper>
                <InputBlocks>
                  <TitleSpan> Video title </TitleSpan>
                  <FullInput
                    placeholder="Add a title"
                    onChange={(event) => setVideoTitle(event.target.value)}
                    required={true}
                    autoFocus
                  />
                </InputBlocks>
                <InputBlocks ref={WrapperRef}>
                  <TitleSpan> Post to </TitleSpan>
                  <ChoosingAccounts
                    type="button"
                    onClick={openAccounts}
                    ref={openAccountRef}
                    value={
                      checkNumber === 0
                        ? `Choose one or more accounts`
                        : checkNumber + ` accounts`
                    }
                  />
                  {accounts && <OpenAccountsBlock checkCount={checkCount} />}
                </InputBlocks>
              </InputWrapper>

              <CancelSaveWrapper>
                <EndButtons>
                  <CancelButton isAddingInfo={false}>Cancel</CancelButton>
                  <SaveButton
                    onClick={infoSave}
                    className={`${
                      videoTitle !== "" && checkNumber !== 0
                        ? "active"
                        : "disable"
                    }`}
                  >
                    Save
                  </SaveButton>
                </EndButtons>
              </CancelSaveWrapper>
            </AddInfoContainer>
          )}
        </VideoAndMainInfo>
      </VideoInfoBlock>
    </WrapperContainer>
  );
};
