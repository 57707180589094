import { Modal, ModalContainer, StyledModalText } from "./styled";
import { useEffect, useRef, useState } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { LoadingSM } from "fragments";

export const DownloadProgressModal = ({
  show,
  downloadProgressStatus,
  setDownloadProgressStatus,
  setOpenModal,
}) => {
  const [progress, setProgress] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (downloadProgressStatus === "finished") {
      setProgress(100);
      setDownloadProgressStatus(null);
      setTimeout(() => {
        setOpenModal(false);
        setProgress(0);
      }, 500);
    }
  }, [downloadProgressStatus]);

  useEffect(() => {
    disableBodyScroll(ref.current);
    let timer;

    const startDownload = () => {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 94) {
            clearInterval(timer);
            return oldProgress;
          }

          if (oldProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return Math.min(oldProgress + getRandomIncrement(), 100);
        });
      }, getRandomDelay());
    };

    const getRandomDelay = () => {
      return Math.floor(Math.random() * 1000) + 500; // Random delay between 0.5s and 1.5s
    };

    const getRandomIncrement = () => {
      return Math.floor(Math.random() * 3) + 1; // Random increment between 1% and 5%
    };

    if (show) {
      startDownload();
    } else {
      clearInterval(timer);
      clearAllBodyScrollLocks();
    }

    return () => {
      clearInterval(timer);
    };
  }, [show]);

  return (
    <ModalContainer show={show}>
      <Modal ref={ref}>
        <div>
          <StyledModalText>Downloading {progress}%</StyledModalText>
          <LoadingSM width="25px" height="25px" />
        </div>
      </Modal>
    </ModalContainer>
  );
};
