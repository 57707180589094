import styled from "styled-components/macro";
import { colors } from "assets/styles/colors";
import * as styles from "assets/styles/globalStyles";
import { MEDIA_LANDSCAPE, SCREEN_SIZES } from "utils/constants";
import { isMobile } from "react-device-detect";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 616px;
  width: 100%;
  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      padding-right: 10px;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    padding-right: 10px;
  }
`;

export const EditTitleInput = styled.input`
  -webkit-appearance: none;
  ${styles.FontBold}
  height: 40px;
  width: 100%;
  padding: 0 20px;
  background: ${colors.white};
  box-shadow: 0 2px 6px rgba(18, 18, 18, 0.75);
  border-radius: 5px;
  border: unset;
  color: ${colors.navyBlue};
  font-size: 24px;
  line-height: 29px;
  transition: 0.3s;
`;

export const Title = styled.div`
  color: ${colors.black};
  ${styles.FontBold}
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 5px;

  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 18px;
  }

  ${!isMobile} && {
    ${MEDIA_LANDSCAPE} {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: unset;
    }
  }

  @media only screen and (max-width: ${SCREEN_SIZES.xs}) {
    margin-bottom: unset;
  }
`;

export const EditTitleIcon = styled.img`
  width: 14px;
  height: 23px;
  opacity: 0.75;
  cursor: pointer;
  margin-left: 8px;

  ${isMobile} && {
    &:hover {
      opacity: 1;
    }
  }
`;
