import axios from "axios";
import { API } from "configs/api";

export const videoApi = {
  getVideo: (body) =>
    axios.get(
      `${API.video}/${body.id}?user_id=${encodeURIComponent(
        body.userId
      )}&code=${encodeURIComponent(body.code)}`
    ),
  getRandomGif: () => axios.get(`${API.gif}`),
  postVideo: (body) => axios.post(`${API.video}`, body),
  updateVideo: (body) => axios.put(`${API.video}/${body.video_id}`, body),
  deleteClip: (body) => axios.delete(`${API.video}/${body.id}`),
  getFilterLabels: (body) =>
    axios.get(
      `${API.videoLabel}?user_id=${encodeURIComponent(
        body.userId
      )}&code=${encodeURIComponent(body.code)}`
    ),
  updateLabels: (body) => axios.put(`${API.videoLabel}/${body.id}`, body),
  downloadVideo: (body) => {
    const instance = axios.create();
    return instance.request({
      url: body.url,
      method: "GET",
      responseType: "blob",
      headers: { "Content-Type": "application/mp4" },
    });
  },
  updateVideoFavorite: (body) => axios.patch(`${API.video}/${body.id}`, body),
  addMarker: (body) => axios.post(`${API.marker}`, body),
  deleteMarker: (body) => axios.delete(`${API.marker}/${body.id}`),
  updateMarker: (body) => axios.patch(`${API.marker}/${body.id}`, body),
  makeClipPreview: (body) => axios.post(`${API.videoHighlight}`, body),
  getAnalyticId: (body) => axios.post(`${API.analytic}`, body),
  updateAnalytic: (body) => axios.put(`${API.analytic}/${body.id}`, body),
};
