import { createSlice } from "@reduxjs/toolkit";
import { sortAlphabeticallyByName } from "utils/heplers";
import {
  downloadVideo,
  getFilterLabels,
  getRandomGif,
  getVideo,
  updateVideoIsFavorite,
  updateVideoLabels,
  addVideoMarker,
  deleteVideoMarker,
  updateVideoMarker,
  makeClipPreview,
  addVideo,
  editClip,
  deleteClip,
  getAnalyticId,
  updateAnalytic,
  updateVideoTitle,
} from "./axios";
import {
  FAILED,
  gifsPreview,
  PENDING,
  REJECTED,
  SUCCEEDED,
} from "utils/constants";

const initialState = {
  video: {},
  user: {},
  videoCameras: [],
  announcements: {},
  club: {},
  labels: [],
  gifSrc: gifsPreview[Math.floor(Math.random() * 2)] || gifsPreview[3],
  filterLabels: [],
  makeClipAction: false,
  saveClip: false,
  fromFilterDate: "2022-01-03",
  markers: [],
  makeClipVideoUrlAndSize: null,
  makeVideoData: null,
  playerRef: null,
  makeClipDistance: [],
  isFavorite: {},
  isPageChanged: false,
  analyticId: null,
  videoCurrentTime: null,
  scrollPosition: false,
  videoCurrentResolution: null,
  pageTitle: "",
  isVideoPlayerReady: false,
  videoTitleData: {},
  playerPlayed: 0,
  activeMarker: null,
  editeMarkerMode: null,

  getRandomGifStatus: PENDING | SUCCEEDED | FAILED,
  getVideoStatus: PENDING | SUCCEEDED | FAILED,
  getFilterLabelsStatus: PENDING | SUCCEEDED | FAILED,
  downloadVideoStatus: PENDING | SUCCEEDED | FAILED,
  updateIsFavoriteStatus: PENDING | SUCCEEDED | FAILED,
  updateLabelsStatus: PENDING | SUCCEEDED | FAILED,
  makeClipVideoUrlAndSizeStatus: PENDING | SUCCEEDED | FAILED,
  addVideoStatus: PENDING | SUCCEEDED | FAILED,
  editClipStatus: PENDING | SUCCEEDED | FAILED,
  deleteClipStatus: PENDING | SUCCEEDED | FAILED,
  deleteClipFromVideoListStatus: PENDING | SUCCEEDED | FAILED,
  updateVideoTitleStatus: PENDING | SUCCEEDED | FAILED,
  addMarkerStatus: PENDING | SUCCEEDED | FAILED,
  deleteMarkerStatus: PENDING | SUCCEEDED | FAILED,
  editMarkerStatus: PENDING | SUCCEEDED | FAILED,
  getAnalyticIdStatus: PENDING | SUCCEEDED | FAILED,
  updateAnalyticStatus: PENDING | SUCCEEDED | FAILED,
};

const setError = (state, action) => {
  state.status = REJECTED;
  state.error = action.payload;
};

export const video = createSlice({
  name: "video",
  initialState,
  reducers: {
    openMakeClip: (state) => {
      state.makeClipAction = true;
    },
    closeMakeClip: (state) => {
      state.makeClipAction = false;
    },
    saveClipToggle: (state) => {
      state.saveClip = !state.saveClip;
    },
    setClipDistance: (state, action) => {
      state.makeClipDistance = action.payload;
    },
    setPlayerRef: (state, action) => {
      state.playerRef = action.payload;
    },
    toggleFavorite: (state, action) => {
      state.isFavorite = action.payload;
    },
    setIsPageChanged: (state, action) => {
      state.isPageChanged = action.payload;
    },
    resetStatus: (state, action) => {
      switch (action.payload) {
        case "makeClipVideoUrlAndSizeStatus":
          state.makeClipVideoUrlAndSizeStatus = 0;
          break;
        case "editClipStatus":
          state.editClipStatus = 0;
          break;
        case "editMarkerStatus":
          state.editMarkerStatus = 0;
          break;
        case "addMarkerStatus":
          state.addMarkerStatus = 0;
          break;
        case "deleteMarkerStatus":
          state.deleteMarkerStatus = 0;
          break;
        case "deleteClipStatus":
          state.deleteClipStatus = 0;
          break;
        case "deleteClipFromVideoListStatus":
          state.deleteClipFromVideoListStatus = 0;
          break;
        case "updateIsFavoriteStatus":
          state.updateIsFavoriteStatus = 0;
          break;
        case "updateVideoTitleStatus":
          state.updateVideoTitleStatus = 0;
          break;
        case "addVideoStatus":
          state.addVideoStatus = 0;
          break;
        default:
          break;
      }
    },
    syncVideoLabels: (state, action) => {
      const { changedLabels, videoId } = action.payload;

      if (state.video.id === videoId) {
        state.labels = sortAlphabeticallyByName([...changedLabels]);
      }
    },
    setVideoCurrentTime: (state, action) => {
      state.videoCurrentTime = action.payload;
    },
    setVideoCurrentResolution: (state, action) => {
      state.videoCurrentResolution = action.payload;
    },
    setVideoPlayerReady: (state) => {
      state.isVideoPlayerReady = true;
    },
    setEditedTitleData: (state, action) => {
      state.videoTitleData = action.payload;
    },
    setPlayerPlayed: (state, action) => {
      state.playerPlayed = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
    setActiveMarker: (state, action) => {
      state.activeMarker = action.payload;
    },
    setEditeMarkerMode: (state, action) => {
      state.editeMarkerMode = action.payload;
    },
  },
  extraReducers: {
    /**
     * Get Video
     * @param state
     */
    [getVideo.pending]: (state) => {
      state.getVideoStatus = PENDING;
    },
    [getVideo.fulfilled]: (state, action) => {
      state.getVideoStatus = SUCCEEDED;
      state.video = action.payload.video;
      state.user = action.payload.user;
      state.labels = action.payload.labels;
      state.club = action.payload.club;
      state.videoCameras = action.payload.video_cameras;
      state.announcements = action.payload.announcements;
      state.markers = action.payload?.markers;
      state.isFavorite = {
        favorite: action.payload.video.is_favorite,
        favoriteId: action.payload.video.id,
      };
      state.analyticId = null;
      state.pageTitle = action.payload.title;
    },
    [getVideo.rejected]: (state) => {
      state.getVideoStatus = REJECTED;
    },

    /**
     * Filter Labels
     * @param state
     */
    [getFilterLabels.pending]: (state) => {
      state.getFilterLabelsStatus = PENDING;
    },
    [getFilterLabels.fulfilled]: (state, action) => {
      state.filterLabels = action.payload.filters;
      state.fromFilterDate = action.payload.from_filter_date;
      state.getFilterLabelsStatus = SUCCEEDED;
    },
    [getFilterLabels.rejected]: (state) => {
      state.getFilterLabelsStatus = REJECTED;
    },

    /**
     * Random Gifs
     * @param state
     */
    [getRandomGif.pending]: (state) => {
      //
    },
    [getRandomGif.fulfilled]: (state, action) => {
      //
      state.gifSrc = action.payload.media.file;
    },
    [getRandomGif.rejected]: (state) => {
      //
    },

    /**
     * Download Video
     * @param state
     */
    [downloadVideo.pending]: (state) => {
      state.downloadVideoStatus = PENDING;
    },
    [downloadVideo.fulfilled]: (state) => {
      state.downloadVideoStatus = SUCCEEDED;
    },
    [downloadVideo.rejected]: (state) => {
      state.downloadVideoStatus = REJECTED;
    },

    /**
     * Video Is Favorite
     * @param state
     */
    [updateVideoIsFavorite.pending]: (state) => {
      state.video.is_favorite = !state.video.is_favorite;
      state.updateIsFavoriteStatus = PENDING;
    },
    [updateVideoIsFavorite.fulfilled]: (state) => {
      state.updateIsFavoriteStatus = SUCCEEDED;
    },
    [updateVideoIsFavorite.rejected]: (state) => {
      state.video.is_favorite = !state.video.is_favorite;
      state.updateIsFavoriteStatus = REJECTED;
    },

    /**
     * Update Video Labels
     * @param state
     */
    [updateVideoLabels.pending]: (state) => {
      state.updateLabelsStatus = PENDING;
    },
    [updateVideoLabels.fulfilled]: (state, action) => {
      state.labels = sortAlphabeticallyByName(action.payload.labels);
      state.updateLabelsStatus = SUCCEEDED;
    },
    [updateVideoLabels.rejected]: (state) => {
      state.updateLabelsStatus = REJECTED;
    },

    /**
     * Make Clip Preview
     * @param state
     */
    [makeClipPreview.pending]: (state) => {
      state.makeClipVideoUrlAndSizeStatus = PENDING;
    },
    [makeClipPreview.fulfilled]: (state, action) => {
      state.makeClipVideoUrlAndSize = action.payload;
      state.makeClipVideoUrlAndSizeStatus = SUCCEEDED;
    },
    [makeClipPreview.rejected]: (state) => {
      state.makeClipVideoUrlAndSizeStatus = REJECTED;
    },

    /**
     * Add video
     * @param state
     */
    [addVideo.pending]: (state) => {
      state.addVideoStatus = PENDING;
    },
    [addVideo.fulfilled]: (state, action) => {
      state.makeVideoData = action.payload;
      state.addVideoStatus = SUCCEEDED;
    },
    [addVideo.rejected]: (state) => {
      state.addVideoStatus = REJECTED;
    },

    /**
     * Edit Clip
     * @param state
     */
    [editClip.pending]: (state) => {
      state.editClipStatus = PENDING;
    },
    [editClip.fulfilled]: (state, action) => {
      state.editClipStatus = SUCCEEDED;
      state.video = action.payload;
    },
    [editClip.rejected]: (state) => {
      state.editClipStatus = REJECTED;
    },

    /**
     * Update Video Title
     * @param state
     */
    [updateVideoTitle.pending]: (state) => {
      state.updateVideoTitleStatus = PENDING;
    },
    [updateVideoTitle.fulfilled]: (state) => {
      state.updateVideoTitleStatus = SUCCEEDED;
      state.videoTitleData = {};
    },
    [updateVideoTitle.rejected]: (state) => {
      state.updateVideoTitleStatus = REJECTED;
    },

    /**
     * Delete Clip
     * @param state
     */
    [deleteClip.fulfilled]: (state, action) => {
      state[action.payload.type] = SUCCEEDED;
    },

    /**
     * Add Marker
     * @param state
     */
    [addVideoMarker.fulfilled]: (state, action) => {
      state.markers = [...state.markers, action.payload];
      state.addMarkerStatus = SUCCEEDED;
    },

    /**
     * Delete Video Marker
     * @param state
     */
    [deleteVideoMarker.fulfilled]: (state, action) => {
      state.markers = state.markers.filter(
        (item) => item.id !== action.payload.id
      );
      state.deleteMarkerStatus = SUCCEEDED;
    },

    /**
     * Update Video Marker
     * @param state
     */
    [updateVideoMarker.pending]: (state) => {
      state.editMarkerStatus = PENDING;
    },
    [updateVideoMarker.fulfilled]: (state, action) => {
      state.markers = state.markers.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.editMarkerStatus = SUCCEEDED;
    },

    /**
     *
     * @param state
     * @param action
     */
    [getAnalyticId.fulfilled]: (state, action) => {
      state.analyticId = action.payload?.id;
      state.getAnalyticIdStatus = SUCCEEDED;
    },

    /**
     *
     * @param state
     * @param action
     */
    [updateAnalytic.fulfilled]: (state, action) => {
      state.updateAnalyticStatus = SUCCEEDED;
    },
  },
});

export const {
  openMakeClip,
  closeMakeClip,
  setClipDistance,
  setPlayerRef,
  resetStatus,
  setIsPageChanged,
  syncVideoLabels,
  saveClipToggle,
  setVideoCurrentTime,
  setVideoCurrentResolution,
  setVideoPlayerReady,
  setEditedTitleData,
  setPlayerPlayed,
  setScrollPosition,
  setActiveMarker,
  setEditeMarkerMode,
} = video.actions;

export default video.reducer;
