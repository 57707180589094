import videojs from "video.js";
import { useEffect, useState } from "react";
import { BigPlayButton } from "../BigPlayButton";
import { InputSection } from "../MakeEditClip/inputSection";
import MakeClipSlider from "../MakeClipSlider";
import PreviewProgressBar from "../PreviewProgressBar";
import { SpanText } from "./styled";
import { useSelector } from "react-redux";

export const MakeClip = () => {
  const { video } = useSelector((state) => state.video);
  const [isPlayButton, setIsPlayButton] = useState(true);
  const [makeClipStartTime, setMakeClipStartTime] = useState(0);
  const [makeClipEndTime, setMakeClipEndTime] = useState(0);
  const [isPreview, setIsPreview] = useState(false);
  const [videoTime, setVideoTime] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const player = videojs("video");

  useEffect(() => {
    setVideoTime(player.cache_.currentTime);
    setIsReady(true);
  }, []);

  return (
    <>
      {isPlayButton ? <BigPlayButton setIsPreview={setIsPreview} /> : null}
      <InputSection
        setIsPreview={setIsPreview}
        isPreview={isPreview}
        player={player}
        makeClipStartTime={makeClipStartTime}
        makeClipEndTime={makeClipEndTime}
        videoId={video.id}
        editClip={video.parent_id}
      />

      {isPreview ? (
        <>
          <SpanText className="preview-clip-text">
            Previewing
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </SpanText>

          <PreviewProgressBar
            makeClipEndTime={makeClipEndTime}
            makeClipStartTime={makeClipStartTime}
            setIsPreview={setIsPreview}
            player={player}
            setIsPlayButton={setIsPlayButton}
          />
        </>
      ) : (
        <MakeClipSlider
          setIsPlayButton={setIsPlayButton}
          setMakeClipEndTime={setMakeClipEndTime}
          setMakeClipStartTime={setMakeClipStartTime}
          editClip={video.parent_id}
          videoTime={isReady ? videoTime : player.cache_.currentTime}
        />
      )}
    </>
  );
};
