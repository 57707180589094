import { createAsyncThunk } from "@reduxjs/toolkit";
import { videoListApi } from "./api";

export const getVideoList = createAsyncThunk(
  "videoList/getVideoList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await videoListApi.getVideoList(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoListLabels = createAsyncThunk(
  "video/updateVideoListLabels",
  async (data, { rejectWithValue }) => {
    try {
      await videoListApi.updateLabels(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateVideoListIsFavorite = createAsyncThunk(
  "videoList/isFavorite",
  async (data, { rejectWithValue }) => {
    try {
      await videoListApi.updateVideoListFavorite(data);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
