import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { StyledProgressBarContainer } from "./styled";
import { setPlayerPlayed } from "store";

export const VideoProgressBar = ({ player }) => {
  const { playerPlayedCount } = useSelector((state) => state.video);

  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      if (playerPlayedCount || player?.hasStarted()) {
        const currentTime = +player.currentTime();
        const videoTime = +player?.duration();
        const percent = (currentTime * 100) / videoTime;
        setProgress(percent);
      }
    }, 400);

    if (player?.ended()) {
      clearInterval(timer);
      dispatch(setPlayerPlayed(0));
    }

    return () => {
      clearInterval(timer);
    };
  }, [player?.ended(), player?.hasStarted(), playerPlayedCount]);

  return (
    <StyledProgressBarContainer className="inactive-timeline">
      <LinearProgress variant="determinate" value={progress} />
    </StyledProgressBarContainer>
  );
};
