import styled from "styled-components/macro";
import * as styles from "assets/styles/globalStyles";
import { SCREEN_SIZES } from "utils/constants";

export const PlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${styles.FlexCenter};
  cursor: pointer;
`;

export const PlayIcon = styled.div`
  ${styles.FlexCenter};
  width: 140px;
  height: 140px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  z-index: 999;
  transition: 0.2s;

  img {
    margin-left: 5px;
    width: 48px !important;
    height: 52px !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    width: 70px;
    height: 70px;

    img {
      width: 24px !important;
      height: 26px !important;
    }
  }
`;
