import { memo, useEffect, useState } from "react";
import { validDate } from "utils/heplers";
import {
  StyledProgress,
  StyledProgressBarContainer,
  StyledTimeContainer,
} from "./styled";

const PreviewProgressBar = ({
  setIsPreview,
  makeClipEndTime,
  makeClipStartTime,
  setIsPlayButton,
  player,
}) => {
  const [progress, setProgress] = useState(0);
  const [progressTimeValidFormat, setProgressTimeValidFormat] = useState(0);
  const [videoTimeValidFormat, setVideoTimeValidFormat] = useState(0);
  const [playerPlayed, setPlayerPlayed] = useState(false);

  useEffect(() => {
    if (player) {
      if (
        player.currentTime() > makeClipStartTime + 1 ||
        player.currentTime() - makeClipStartTime < 0
      ) {
        player.currentTime(makeClipStartTime);
      }
      let playPromise = player.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {
          setPlayerPlayed(true);
        });
      }
      setIsPlayButton(false);
    }
  }, [player]);

  useEffect(() => {
    if (playerPlayed) {
      const timer = setInterval(() => {
        const videoTime = player.currentTime() - makeClipStartTime;
        const percent = (videoTime * 100) / editVideoTime;
        setProgress(percent);
        setProgressTimeValidFormat(validDate(videoTime));
        if (percent >= 100) {
          setIsPreview(false);
        }
      }, 400);

      const editVideoTime = makeClipEndTime - makeClipStartTime;
      setVideoTimeValidFormat(validDate(editVideoTime));

      return () => {
        clearInterval(timer);
      };
    }
  }, [playerPlayed]);

  return (
    <StyledProgressBarContainer className="style-progress-bar-container">
      <StyledTimeContainer>
        {`${progressTimeValidFormat} / ${videoTimeValidFormat}`}
      </StyledTimeContainer>
      <StyledProgress variant="determinate" value={progress} />
    </StyledProgressBarContainer>
  );
};

export default memo(PreviewProgressBar);
